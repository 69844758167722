/**
 * 本地缓存
 * 注意是缓存在内存中，一刷新就没了
 * @author jasonelchen
 */
let _cache = {}

// 获取缓存
function get<T> (key: string): T {
  return _cache[key]
}

// 设置缓存
function set<T> (key: string, value: T): T {
  return (_cache[key] = value)
}

// 清除缓存
function remove (key: string) {
  delete _cache[key]
}

// 清空缓存
function clear () {
  return (_cache = {})
}

export const cache = {
  get,
  set,
  remove,
  clear,
}
