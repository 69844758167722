import { requestApiV2 } from '@library'
import {
  BatchInfoListPayload,
  BatchInfoItem,
  CommonList,
} from '@types'

/*
获取批次信息列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001870757
 */
export function getBatchInfoList (filter: BatchInfoListPayload) {
  return requestApiV2<CommonList<BatchInfoItem>>('/trpc.tcc.tcc_main.Batch/GetBatchList', filter)
}
