import { requestApiV2 } from '@library'
import { GetAccessRolesParam, SalesApprovalApplyListPayload, CommonList, SalesApprovalApplyItem } from '@types'

// 申请权限 http://opc-mock.woa.com/project/530/interface/api/3419
export function getAccessRoles (params: GetAccessRolesParam) {
  return requestApiV2<{id?: number}>('/trpc.tcc.tcc_main.Sales/GetAccessRoles', params)
}

// 获取权限记录列表 http://opc-mock.woa.com/project/530/interface/api/3425
export function getSalesApprovalApplyList (filter: SalesApprovalApplyListPayload) {
  return requestApiV2<CommonList<SalesApprovalApplyItem>>('/trpc.tcc.tcc_main.Sales/GetSalesApprovalApplyList', filter)
}
