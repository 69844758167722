import React, { useMemo } from 'react'
import { MenuOutlined } from '@ant-design/icons'
import { Table } from 'antd'
import { SortableContainer, SortableElement, SortableHandle, SortEndHandler } from 'react-sortable-hoc'
import './style.less'
import { isFunction } from '@library'
import { TableProps } from 'antd/lib/table'

interface SortableTableProps<T extends object> extends Omit<TableProps<T>, 'rowKey'| 'columns' | 'components'> {
  onSortEnd: SortEndHandler
  rowKey: string
  columns: TableProps<T>['columns'] | ((handle: typeof SortableHandle) => TableProps<T>['columns'])
}
const SortableItem = SortableElement(props => <tr {...props} />)
const SsortableContainer = SortableContainer(props => <tbody {...props} />)

const DragHandle = SortableHandle(() => (
  <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />
))

/**
 * 可拖拽表单
 * @author tylerzzheng
 */
const SortableTable = <T extends object>(props: SortableTableProps<T>) => {
  const { onSortEnd, dataSource, rowKey, columns: tempColumns, ...restProps } = props
  const columns = useMemo(() => isFunction(tempColumns) ? tempColumns(SortableHandle) : [{
    title: '排序',
    dataIndex: 'sort',
    width: 80,
    className: 'drag-visible',
    render: () => <DragHandle />,
  }, ...tempColumns], [tempColumns])

  const DraggableContainer = props => (
    <SsortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow = props => {
    // function findIndex base on Table rowKey props and should always be a right array index
    const index = dataSource.findIndex(x => x[rowKey] === props['data-row-key'])
    return <SortableItem index={index} {...props} />
  }

  return (
    <Table
      dataSource={dataSource}
      rowKey={rowKey}
      columns={columns}
      {...restProps}
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow,
        },
      }}
    />
  )
}
export default SortableTable
