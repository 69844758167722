/**
 * 公共头部，基于 ant design 的 PageHeader 封装
 * @author jasonelchen
 */
import React, { FunctionComponent } from 'react'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import { PageHeader as AntPageHeader } from 'antd'
import { PageHeaderProps as AntPageHeaderProps } from 'antd/es/page-header'
import './style'
import { useTitle } from '@hooks'
import { isString } from '@library'

interface PageHeaderProps extends AntPageHeaderProps {
  hasBack?: boolean
  documentTitle?: string // 当 title 不为 string 是，替代使用的此参
}
// todo 做吸顶效果 https://ant.design/components/tabs-cn/#components-tabs-demo-custom-tab-bar https://codesandbox.io/s/zi-ding-yi-ye-qian-tou-antd-4-17-0-alpha-9-forked-vmcdr?file=/index.js
export const PageHeader: FunctionComponent<PageHeaderProps> = props => {
  const { documentTitle, hasBack, onBack, className, ...restProps } = props
  const { goBack } = useHistory()

  useTitle(isString(restProps.title) ? restProps.title : documentTitle)

  return (
    <AntPageHeader
      className={classNames('component-page-header', className)}
      onBack={hasBack ? (onBack || goBack) : onBack}
      {...restProps}
    />
  )
}

PageHeader.defaultProps = {
  ghost: false,
  hasBack: false,
}
