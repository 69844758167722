import { requestApiV2 } from '@library'
import {
  CommonList,
  RuleStatisticsParamType,
  RuleStatisticsData,
  OrdersFilter,
  OrdersCompletion,
  OrderData,
  OrdersDetailFilter,
  OrderDetail,
  OrdersAccountFilter,
  OrdersAccount,
  GetMonthOrdersRank,
  GetMonthCallRank, TaskConvert, CallMonitorData, CapacityData, KanbanDetail, KanbanDetailFilter,
} from '@types'

// 坐席日常任务明细接口 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001883231
export function getSalesStatistics (params: RuleStatisticsParamType) {
  return requestApiV2<CommonList<RuleStatisticsData>>('/trpc.tcc.tcc_main.Statistics/GetSalesStatistics', params)
}

// 获取管理的群组座席明细
export function getKanbanDetail (filter: KanbanDetailFilter) {
  return requestApiV2<{company: KanbanDetail[], personal: KanbanDetail[]}>('/trpc.tcc.tcc_main.Report/GetSalesEnterpriseDetails', filter)
}

// 获取员工(企业)昨日目标完成情况 http://opc-mock.oa.com/project/530/interface/api/2988
export function getOrdersCompletion (filter: OrdersFilter) {
  return requestApiV2<{company: OrdersCompletion, personal: OrdersCompletion}>('/trpc.tcc.tcc_main.Report/GetSalesDailyOrdersCompletion', filter)
}

// 获取员工(企业)累计订单分类 http://opc-mock.oa.com/project/530/interface/api/2990
export function getAccumulativeOrders (filter: OrdersFilter) {
  return requestApiV2<{company: OrderData, personal: OrderData}>('/trpc.tcc.tcc_main.Report/GetSalesAccumulativeOrders', filter)
}

// 获取员工日订单金额明细 http://opc-mock.oa.com/project/530/interface/api/2992
export function getDayOrdersDetail (filter: OrdersDetailFilter) {
  return requestApiV2<CommonList<OrderDetail>>('/trpc.tcc.tcc_main.Report/GetSalesDayOrdersDetail', filter)
}

// 获取员工月订单金额明细 http://opc-mock.oa.com/project/530/interface/api/3000
export function getMonthOrdersDetail (filter: OrdersDetailFilter) {
  return requestApiV2<CommonList<OrderDetail>>('/trpc.tcc.tcc_main.Report/GetSalesMonthOrdersDetail', filter)
}

// 获取员工月成单用户明细 http://opc-mock.oa.com/project/530/interface/api/3002
export function getMonthOrdersAccount (filter: OrdersAccountFilter) {
  return requestApiV2<CommonList<OrdersAccount>>('/trpc.tcc.tcc_main.Report/GetSalesMonthOrdersAccountsDetail', filter)
}

// 获取员工日成单用户明细 http://opc-mock.oa.com/project/530/interface/api/2994
export function getDayOrdersAccount (filter: OrdersAccountFilter) {
  return requestApiV2<CommonList<OrdersAccount>>('/trpc.tcc.tcc_main.Report/GetSalesDayOrdersAccountsDetail', filter)
}

// 获取当月订单金额排行 http://opc-mock.woa.com/project/530/interface/api/3306
export function getMonthOrdersRank (filter: OrdersFilter) {
  return requestApiV2<GetMonthOrdersRank>('/trpc.tcc.tcc_main.Report/GetMonthOrdersRank', filter)
  // return requestApi<GetMonthOrdersRank>('GetMonthOrdersRank', { ...filter, salesOpenId: 'HuangJia' })
}

// 获取当月累计通话时长排行 http://opc-mock.woa.com/project/530/interface/api/3307
export function getMonthCallRank (filter: OrdersFilter) {
  return requestApiV2<GetMonthCallRank>('/trpc.tcc.tcc_main.Report/GetMonthCallRank', filter)
  // return requestApi<GetMonthCallRank>('GetMonthCallRank', { ...filter, salesOpenId: 'HuangJia' })
}

// 当日通话记录监控 http://opc-mock.woa.com/project/530/interface/api/3308
export function getCallMonitor (filter: OrdersFilter) {
  return requestApiV2<{ target: CallMonitorData, reality: CallMonitorData }>('/trpc.tcc.tcc_main.Report/GetCallMonitor', filter)
  // return requestApi<{ target: CallMonitor, reality:£ CallMonitor }>('GetCallMonitor', { ...filter, salesOpenId: 'HuangJia' })
}

// 当日库容管理 http://opc-mock.woa.com/project/530/interface/api/3309
export function getCapacity (filter: OrdersFilter) {
  return requestApiV2<{ related: CapacityData, inspect: CapacityData }>('/trpc.tcc.tcc_main.Report/GetCapacity', filter)
  // return requestApi<{ related: Capacity, inspect: Capacity }>('GetCapacity', { ...filter, salesOpenId: 'HuangJia' })
}

// 重点任务追踪 http://opc-mock.woa.com/project/530/interface/api/3310
export function GetTaskConvert (filter: OrdersFilter) {
  return requestApiV2<{ list: TaskConvert[] }>('/trpc.tcc.tcc_main.Report/GetTaskConvert', filter)
  // return requestApi<TaskConvert[]>('GetTaskConvert', { ...filter, salesOpenId: 'HuangJia' })
}
