/**
 * 侧边栏菜单配置
 * @author jasonelchen
 */
import React from 'react'
import { MenuItem } from '@components'
import { moduleId } from '@configs/index'
import {
  DesktopOutlined,
  CarryOutOutlined,
  SolutionOutlined,
  BookOutlined, HomeOutlined, LineChartOutlined, SettingOutlined,
} from '@ant-design/icons'

const routes: MenuItem[] = [
  {
    key: 'index',
    title: '首页',
    url: '/',
    Icon: <HomeOutlined />,
  },
  {
    key: 'performance',
    title: '业绩概览',
    url: '/performance',
    Icon: <LineChartOutlined />,
  },
  {
    key: 'kanban',
    title: '数据看板',
    url: '/kanban',
    Icon: <LineChartOutlined />,
    authPoints: ['menuReportKanban'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'kanban-admin',
    title: '管理看板',
    url: '/kanban-admin',
    Icon: <LineChartOutlined />,
    authPoints: ['menuReportKanbanAdmin'],
    belongModule: [moduleId.MID_LONG_TAIL_ID],
  },
  {
    key: 'task',
    title: '任务管理',
    Icon: <DesktopOutlined />,
    subMenus: [
      // {
      //   key: 'task.list',
      //   title: '座席工作台',
      //   url: '/task/list',
      //   Icon: <DesktopOutlined />,
      //   authPoints: ['menuTaskList'],
      // },
      {
        key: 'task.workspace',
        title: '座席工作台',
        url: '/task/workspace',
        authPoints: ['menuTaskList'],
      },
      {
        key: 'task.goal',
        title: '目标管理',
        url: '/task/goal',
        authPoints: ['menuTaskGoal'],
      },
      {
        key: 'task.all.task',
        title: '所有任务',
        url: '/task/all-task',
        authPoints: ['menuTaskAllTask'],
      },
      {
        key: 'task.follow.record',
        title: '跟进记录',
        url: '/task/follow-record',
        authPoints: ['menuTaskFollowRecord'],
      },
      {
        key: 'task.call.record',
        title: '通话记录',
        url: '/task/call-record',
        authPoints: ['menuTaskCallRecord'],
      },
      {
        key: 'task.call.robot',
        title: '机器人通话记录',
        url: '/task/robot-call-record',
        authPoints: ['menuRobotCallRecord'],
      },
      {
        key: 'task.sms.record',
        title: '短信记录',
        url: '/task/sms-record',
        authPoints: ['menuTaskSmsRecord'],
      },
      {
        key: 'task.retain.record',
        title: '留资记录',
        url: '/task/retain-record',
        authPoints: ['menuTaskRetainRecord'],
      },
    ],
  },
  {
    key: 'lead',
    title: '线索管理',
    Icon: <CarryOutOutlined />,
    subMenus: [
      {
        key: 'lead.list.claimed',
        title: '已派发线索',
        url: '/lead/list/claimed',
        authPoints: ['menuLeadListClaimed'],
      },
      {
        key: 'lead.list.unclaimed',
        title: '线索公海池',
        url: '/lead/list/unclaimed',
        authPoints: ['menuLeadListUnclaimed'],
      },
      {
        key: 'lead.list.discard',
        title: '废弃线索池',
        url: '/lead/list/discard',
        authPoints: ['menuLeadListDiscard'],
      },
      {
        key: 'lead.add',
        title: '上传线索',
        url: '/lead/add',
        authPoints: ['menuLeadAdd'],
      },
      {
        key: 'lead.circulate.record',
        title: '线索流转记录',
        url: '/lead/circulate-record',
        authPoints: ['menuLeadCirculateRecord'],
      },
    ],
  },
  {
    key: 'customer',
    title: '客户管理',
    Icon: <SolutionOutlined />,
    subMenus: [
      {
        key: 'customer.opportunity',
        title: '商机列表',
        url: '/customers/opportunity',
        authPoints: ['menuCustomerOpportunity'],
      },
      {
        key: 'customer.resource',
        title: '客户资源池',
        url: '/customers/resource',
        authPoints: ['menuCustomerResource'],
      },
      {
        key: 'customer.pure',
        title: '公海池',
        url: '/customers/pure-pool',
        authPoints: ['menuCustomerPurePool'],
      },
      {
        key: 'customer.company.pool',
        title: '公司私海池',
        url: '/customers/company-pool',
        authPoints: ['menuCustomerCompanyPool'],
      },
      {
        key: 'customer.investigate',
        title: '考察客户池',
        url: '/customers/investigate',
        authPoints: ['menuCustomerInvestigate'],
        belongModule: [moduleId.MID_LONG_TAIL_ID],
      },
      {
        key: 'customer.linked',
        title: '关联客户池',
        url: '/customers/linked',
        authPoints: ['menuCustomerLinked'],
        belongModule: [moduleId.MID_LONG_TAIL_ID],
      },
      {
        key: 'customer.saas.config',
        title: 'SaaS产品配置单',
        url: '/customers/saas-config',
        authPoints: ['menuSaasConfig'],
      },
      {
        key: 'customer.transfer',
        title: '转出客户池',
        url: '/customers/transfer',
        authPoints: ['menuCustomerTransfer'],
      },
      {
        key: 'customer.trans.temp',
        title: '回流临时池',
        url: '/customers/trans-temp',
        authPoints: ['menuCustomerTransTemp'],
        belongModule: [moduleId.MID_LONG_TAIL_ID],
      },
      {
        key: 'customer.link.log',
        title: '专属链接关联记录',
        url: '/customers/link-log',
        authPoints: ['menuCustomerLinkLog'],
      },
      {
        key: 'customer.order.detail',
        title: '订单明细',
        url: '/customers/order-detail',
        authPoints: ['menuCustomerOrderDetail'],
      },
      {
        key: 'customer.assign.record',
        title: '客户变更记录',
        url: '/customers/assign-record',
        authPoints: ['menuCustomerAssignRecord'],
      },
      {
        key: 'customer.conflict.record',
        title: '撞单分配记录',
        url: '/customers/conflict-record',
        authPoints: ['menuCustomerConflictRecord'],
      },
      {
        key: 'customer.bind-failure',
        title: '指派失败记录',
        url: '/customers/bind-failure',
        authPoints: ['menuBindFailure'],
        belongModule: [moduleId.REBATE_ID],
      },
      {
        key: 'customer.unbind',
        title: '解绑客户记录',
        url: '/customers/unbind',
        authPoints: ['menuUnbind'],
        belongModule: [moduleId.REBATE_ID],
      },
    ],
  },
  {
    key: 'config',
    Icon: <SettingOutlined />,
    title: '配置管理',
    subMenus: [
      {
        key: 'config.my.apply',
        title: '我的申请',
        url: '/config/my-apply',
      },
      {
        key: 'config.organization',
        title: '组织架构管理',
        url: '/config/organization',
        authPoints: ['menuConfigOrganization'],
      },
      {
        key: 'config.tags',
        title: '标签管理',
        url: '/config/tags',
        authPoints: ['menuConfigTags'],
      },
      {
        key: 'config.rule.type',
        title: '规则分类管理',
        url: '/config/rule-type',
        authPoints: ['menuConfigRuleType'],
      },
      {
        key: 'config.sms',
        title: '短信模版管理',
        url: '/config/sms',
        authPoints: ['menuConfigSms'],
      },
      {
        key: 'config.lead.rule',
        title: '自动处理规则',
        url: '/config/lead-rule',
        authPoints: ['menuConfigLeadRule'],
      },
      {
        key: 'config.follow',
        title: '跟进表单管理',
        url: '/config/follow',
        authPoints: ['menuConfigFollow'],
      },
      {
        key: 'config.task.log',
        title: '系统任务列表',
        url: '/config/task-log',
        authPoints: ['menuConfigTaskLog'],
      },
      {
        key: 'config.task.robot',
        title: '机器人名单',
        url: '/config/robot-task-log',
        authPoints: ['menuRobotTask'],
      },
      {
        key: 'config.retain.resource.list',
        title: '留资表单管理',
        url: '/config/retain-resource-list',
        authPoints: ['menuConfigRetainResourceList'],
      },
      {
        key: 'config.batch.info',
        title: '批次信息',
        url: '/config/batch-info',
        authPoints: ['menuConfigBatchInfo'],
      },
      {
        key: 'config.operation.Log',
        title: '操作日志',
        url: '/config/operation-Log',
        authPoints: ['menuConfigOperationLog'],
      },
    ],
  },
  {
    key: 'report',
    title: '统计报表',
    Icon: <BookOutlined />,
    subMenus: [
      {
        key: 'report.agent.task',
        title: '坐席日常任务明细',
        url: '/report/agent-task',
        authPoints: ['menuReportAgentTask'],
      },
    ],
  },
]
export default routes
