import { ISortOrder, Pagination, TimeTypeEnum } from '@types'

export interface RuleStatisticsParamType extends ISortOrder{
  timeType?: TimeTypeEnum // 是 int 时间类型 hour|day|month
  beginTime?: string // 是 string 查询开始时间
  endTime?: string // 是 string 查询结束时间
  salesName?: string[] // 否 string 销售名字 // 文档这里写成string了， 写错了
  sort?: 'recordTime' | 'callTotal' | 'validCallTotal' | 'durationTotal' | 'validCallRate' | 'newCustomerTotal' | 'touchTotal'// 排序字段，目前支持recordTime,callTotal,validCallTotal,durationTotal,validCallRate,newCustomerTotal,touchTotal，默认为recordTime
}

export interface RuleStatisticsData {
  baseStatisticsId: string // 编号
  salesName: string // 销售名字
  callTotal: number // 外呼次数
  validCallTotal: number // 接通次数
  durationTotal: number // 外呼总时长
  validCallRate: string // 接通率
  averageDuration: number // 平均通话时长
  followTotal: number // 跟进记录数
  validFollowTotal: number // 有效跟进记录数
  newCustomerTotal: number // 新增客户数
  touchTotal: number // 触达客户数
  communicateTotal: number // 沟通客户数
  salesNameCh: string // sales中文名字
}

// Kanban类型
export enum KanbanTypeEnum {
  SALESNAME = 'salesName',
  GROUP = 'group'
}

export interface OrdersFilter {
  date: string // 日期 2022-01-05
  kanbanType?: KanbanTypeEnum // 默认salesName
  salesName?: string // kanbanType为salesName时，不传则默认为本人
  groupName?: string // kanbanType为group的时候必传， groupId同
  groupId?: number
}

export interface KanbanDetailFilter {
  date: string // 日期 2022-01-05
  kanbanType?: KanbanTypeEnum // 默认salesName
  groupName?: string // kanbanType为group的时候必传， groupId同
}

export interface OrdersCompletion {
  totalOrderAmt: number // 非必须 当月累计成单金额 （已完成））
  kpiOrderAmt: number // 非必须 当月订单目标金额 （目标，分母）
  kpiCompleteRate: number // 非必须 任务完成率
  lowerOrderUin: number // 非必须 当日成单用户数
  followerOrderAmt: number // 非必须 当日成单金额
}

export type KanbanDetail = OrdersCompletion & OrderData & {
  salesName: string
}

// 成单数据
export interface FollowOrderData {
  followerOrderAmt: number // 非必须 成单金额
  lowerOrderUin: number // 非必须 成单用户数
}

export interface OrderData {
  newBuy: FollowOrderData // 新购
  continueBuy: FollowOrderData // 续费
}

export interface OrdersAccountFilter extends OrdersFilter, Pagination {
  customerType?: OrderDetailCustomerTypeEnum // 非必须 客户类型，0个人，1企业
  dealType?: DealTypeEnum // 非必须 订单类型，0 未知 1新购 2续费 3 变配 4退费
}

export type OrdersDetailFilter = OrdersAccountFilter & ISortOrder & {
  sort?: 'payTime' // 非必须 排序字段，仅支持payTime
}

export enum DealTypeEnum {
  UNKNOWN = 0,
  NEW_BUY = 1, // 非续费
  CONTINUE_BUY = 2,
  UPGRADE = 3,
  REFOUND = 4,
}

export enum OrderDetailCustomerTypeEnum {
  PERSON = 0,
  COMPANY = 1,
}

export interface OrderDetail {
  salesName: string // 非必须 跟进人英文名
  salesNameCh: string // 非必须 跟进人中文名
  customerName: string // 非必须 客户名
  customerId: number // 非必须 客户id
  customerType: OrderDetailCustomerTypeEnum // 非必须 客户类型，0个人，1企业
  dealType: DealTypeEnum // 非必须 订单类型，续费，新购
  payTime: string // 非必须 支付时间
  followerOrderAmt: number // 非必须 订单金额

  roleType: string // 必须 角色类型，值为员工
  divideGroupSec: string // 必须 二级职能组
  divideGroupFirst: string // 必须 一级职能组
  divideCompany: string // 必须 公司
  uin: string // 必须 uin
  tccRelatedStatus: TccRelatedStatusEnum // 必须 关联状态
  smallDealId: string // 必须 订单号
  productCodeName: string // 必须 产品名称
}

export interface OrdersAccount {
  roleType: string // 必须 角色类型，值固定为员工
  salesName: string // 必须 英文名
  salesNameCh: string // 必须 中文名
  divideGroupSec: string // 必须 二级群组
  divideGroupFirst: string // 必须 一级群组
  divideCompany: string // 必须 公司
  dealType: DealTypeEnum // 非必须 订单类型，续费，新购
  uin: string // 必须 客户uin
  customerId: number // 非必须 客户id
  customerName: string // 必须 客户名
  customerType: string // 必须 客户类型
  tccRelatedStatus: TccRelatedStatusEnum // 必须 关联状态
  followerOrderAmt: string // 必须 成单金额
}

export enum TccRelatedStatusEnum {
  Y = 'Y',
  N = 'N',
}

export interface MonthOrdersRank {
  ftime: string//  非必须
  roleType: string//  非必须
  customerType: string//  非必须
  divideCompany: string//  非必须
  topRank: string//  非必须 排名
  salesName: string//  非必须 坐席英文名
  salesNameCh: string//  非必须 坐席中文名
  divideGroupSec: string//  非必须 所属二级职能组
  divideGroupFirst: string//  非必须 所属一级职能组
  lowerOrderUin: string//  非必须
  followerOrderAmt: string//  非必须 当月累计订单金额
  etlTime: string//  非必须
  customerId: string//  非必须
}

export interface GetMonthOrdersRank {
  personRank: MonthOrdersRank[],
  personMe: MonthOrdersRank,
  companyRank: MonthOrdersRank[],
  companyMe: MonthOrdersRank,
}

export interface MonthCallRank {
  ftime: string//  非必须
  roleType: string//  非必须
  // customerType: string//  非必须
  divideCompany: string//  非必须
  topRank: string//  非必须 排名
  salesName: string//  非必须 坐席英文名
  salesNameCh: string//  非必须 坐席中文名
  divideGroupSec: string//  非必须 所属二级职能组
  divideGroupFirst: string//  非必须 所属一级职能组
  callDuration: string // 非必须
  etlTime: string // 非必须
  callMinutes: string // 非必须 通话时长
  customerId: string//  非必须
}

export interface GetMonthCallRank {
  me: MonthCallRank
  rank: MonthCallRank[]
}

export interface CallMonitor {
  ftime: string //  非必须
  roleType: string //  非必须
  salesName: string //  非必须 坐席英文名
  salesNameCh: string //  非必须 坐席中文名
  divideGroupSec: string //  非必须 所属二级职能组
  divideGroupFirst: string //  非必须 所属一级职能组
  divideCompany: string //  非必须
  indicatorCategory: string //  非必须
  tccConcatCnt: string //  非必须 通话次数
  connetUin: string //  非必须 接通次数
  tccEffectiveConcatCnt: string //  非必须 有效通话次数
  effectiveConcatCust: string //  非必须 有效通话客户数
  connetRate: string //  非必须 接通率
  callMinutes: string //  非必须 通话总时长（分钟）
  callAtt: string //  非必须 通话ATT（分钟）
  effectiveCallMinutes: string //  非必须 有效通话时长（分钟）
  effectiveCallAtt: string //  非必须 有效通话ATT
  customerId: string //  非必须
}

export interface CallMonitorData {
  callMonitorList: CallMonitor[]
  callMonitorTotal: CallMonitor
}

export interface Capacity {
  ftime: string //  非必须
  roleType: string //  非必须
  tccRelatedStatus: string //  非必须
  salesNameCh: string //  非必须 坐席中文名
  salesName: string //  非必须 坐席英文名
  divideGroupFirst: string //  非必须 所属一级职能组
  divideGroupSec: string //  非必须 所属二级职能组
  divideCompany: string //  非必须
  custsNum: string //  非必须 库内客户数
  paymentCustsNum: string //  非必须 已付费客户数
  unpayCustsNum: string //  非必须 未付费客户数
  touch30dCustsNum: string //  非必须 近30天触达客户数
  effectTouch30dCustsNum: string //  非必须 近30天有效触达客户数
  followerOrderCusts30d: string //  非必须 近30天预付费下单客户数
  followerOrderAmts30d: string //  非必须 近30天预付费成单金额
  customerId: string //  非必须
}

export interface CapacityData {
  capacitiesList: Capacity[]
  capacitiesTotal: Capacity
}

export interface TaskConvert {
  ftime: string //  非必须
  roleType: string //  非必须
  salesName: string //  必须   坐席英文名
  salesNameCh: string //  必须   坐席中文名
  divideGroupSec: string //  必须   所属二级职能组
  divideGroupFirst: string //  必须   所属一级职能组
  divideCompany: string //  公司   必须
  batch: string //  必须   批次ID
  batchName: string //  必须   批次名
  inputLeadNum: string //  必须   接受线索数
  distLeadNum: string //  必须   下发线索数
  touchLeadNum: string //  必须   触达线索数
  effectiveTouchLeadNum: string //  必须   有效触达线索数
  connectLeadNum: string //  必须   接通线索数
  inputUin: string //  必须   接收用户数
  lowerUin: string //  必须   下发用户数
  trackUin: string //  必须   触达用户数
  effectiveTouchUinNum: string //  必须   有效触达用户数
  connetUin: string //  必须   接通用户数
  lowerOrderUin: string //  必须   当月成单用户数（预付费）
  conversRateGoal: string //  必须   当月转化率目标（预付费）
  isQualifiedFlag: string //  必须   是否达成
  untouchLeadNum: string //  必须   未触达线索数
  untrackUin: string //  必须   未触达用户数
  conversRate: string //  必须   当月累计转化率（预付费）
  customerId: string //  必须   的
}
