/**
 判断类型比较可靠的一个方法
 Object.prototype.toString.call(1) // "[object Number]"

 Object.prototype.toString.call('hi') // "[object String]"

 Object.prototype.toString.call({ a: 'hi' }) // "[object Object]"

 Object.prototype.toString.call([1, 'a']) // "[object Array]"

 Object.prototype.toString.call(true) // "[object Boolean]"

 Object.prototype.toString.call(() => {}) // "[object Function]"

 Object.prototype.toString.call(null) // "[object Null]"

 Object.prototype.toString.call(undefined) // "[object Undefined]"

 Object.prototype.toString.call(Symbol(1)) // "[object Symbol]"
 * @param unknown
 */
export const isNumber = (unknown: any): unknown is number => Object.prototype.toString.call(unknown) === '[object Number]'

export const isString = (unknown: any): unknown is string => Object.prototype.toString.call(unknown) === '[object String]'

export const isObject = (unknown: any): unknown is object => Object.prototype.toString.call(unknown) === '[object Object]'

export const isArray = (unknown: any): unknown is any[] => Object.prototype.toString.call(unknown) === '[object Array]'

export const isBoolean = (unknown: any): unknown is boolean => Object.prototype.toString.call(unknown) === '[object Boolean]'

export const isFunction = (unknown: any): unknown is Function => Object.prototype.toString.call(unknown) === '[object Function]'

export const isNull = (unknown: any): unknown is null => Object.prototype.toString.call(unknown) === '[object Null]'

export const isUndefined = (unknown: any): unknown is undefined => Object.prototype.toString.call(unknown) === '[object Undefined]'

export const isSymbol = (unknown: any): unknown is symbol => Object.prototype.toString.call(unknown) === '[object Symbol]'

/**
 * 确保值是正常数字（非NaN），否则返回undefined
 * batchId: +batchId || undefined, // 如果batchId的值是0， 那么会变成undefined， 出现bug
 * +values?.batchId // 如果batchId是undefined， 那么「+」之后会变成NaN，axios会处理成null上送给后端，同样有bug
 */
export const handleNumber = (val: string | null | undefined | number, fallback: number = undefined): number => {
  if (isNull(val) || isUndefined(val)) return fallback
  const number = +val
  return isNaN(number) ? fallback : number
}

/**
 * 是否是空数组， 即[]
 */
export const isEmptyArray = (val: any) => isArray(val) && val.length === 0

/**
 * 不是空数组， 即必须是数组，且数组长度大于等于1
 */
export const notEmptyArray = (val: any) => isArray(val) && val.length >= 1

export const isNullOrUndefined = (val: any): val is null | undefined => isNull(val) || isUndefined(val)

/**
 * lodash的isempty有问题.使用这里的
 * @param val
 */
export const isEmpty = (val: any) => {
  return isNullOrUndefined(val) || val === ''
}

export const isAllEmpty = (...list: any[]) => {
  return list?.every?.(val => isEmpty(val)) ?? true
}
