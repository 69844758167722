/**
 * 前端 Store
 * @author jasonelchen
 */
import { createContainer } from 'unstated-next'

type containerFn<Value, State = void> = (initialState?: State) => Value

export function composeContainer<T, C extends containerFn<T>, U extends { [key: string]: C }, K extends keyof U> (mapping: U) {
  function Global () {
    return Object.keys(mapping).reduce((obj, key) => {
      obj[key as K] = mapping[key]()
      return obj
    }, {} as { [K in keyof U]: T })
  }

  const allContainer = createContainer(Global)
  return {
    Provider: allContainer.Provider,
    containers: Object.keys(mapping).reduce((obj, key) => {
      obj[key as K] = (() => allContainer.useContainer()[key]) as U[K]
      return obj
    }, {} as { [K in keyof U]: U[K] }),
  }
}
