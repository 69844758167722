/**
 * 适合TCC的评论组件
 * @author tylerzzheng
 */
import { Avatar, Button, Comment, Form, Input, List, message } from 'antd'
import React, { useState } from 'react'
import { ListProps } from 'antd/lib/list'
import store from '@store'
import { getThumbAvatar } from '../UserAvatar'

const { TextArea } = Input

export interface CommentItem {
  commentId: number | string //  必须
  author: string;
  avatar: string;
  content: React.ReactNode;
  datetime: string;
}

interface EditorProps {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: () => Promise<void>;
  submitting: boolean;
  placeholder?: string
  value: string;
}

export interface TccCommentProps {
  commentListProps?: ListProps<CommentItem>
  comments: CommentItem[]
  placeholder?: string
  onSubmit: (comment: Partial<CommentItem>) => Promise<boolean>
}

const CommentList = ({ comments, commentListProps }: { comments: CommentItem[], commentListProps: ListProps<CommentItem> }) => (
  <List
    itemLayout="horizontal"
    {...commentListProps}
    dataSource={comments}
    renderItem={props => <Comment {...props} />}
  />
)

const Editor = ({ onChange, onSubmit, submitting, value, placeholder }: EditorProps) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onChange} value={value} placeholder={placeholder} />
    </Form.Item>
    <Form.Item>
      <Button htmlType="submit" loading={submitting} onClick={onSubmit} type="primary">
        新增评论
      </Button>
    </Form.Item>
  </>
)

const TccComment: React.FC<TccCommentProps> = (props) => {
  const { comments, commentListProps, placeholder, onSubmit } = props

  const { userInfo } = store.useSession()

  const [submitting, setSubmitting] = useState(false)
  const [editorContent, setEditorContent] = useState('')

  const handleSubmit = async () => {
    if (!editorContent) {
      message.error('评论内容为空')
      return
    }

    setSubmitting(true)

    const isErr = await onSubmit({ content: editorContent })

    setSubmitting(false)
    if (isErr) return
    setEditorContent('')
  }

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setEditorContent(e.target.value)
  }

  return (
    <>
      {comments.length > 0 ? <CommentList commentListProps={commentListProps} comments={comments} /> : <span>暂无评论</span>}
      <Comment
        avatar={<Avatar src={getThumbAvatar(userInfo.avatar)} alt={userInfo.salesName} />}
        content={
          <Editor
            onChange={handleChange}
            onSubmit={handleSubmit}
            submitting={submitting}
            value={editorContent}
            placeholder={placeholder}
          />
        }
      />
    </>
  )
}

TccComment.defaultProps = {
  comments: [],
  placeholder: '',
  onSubmit: async () => false,
}

export default TccComment
