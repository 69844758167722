/**
 * 获取指定的table表头配置和表头参数
 * @author tylerzzheng
 */
import { useEffect, useMemo, useState } from 'react'
import { ColumnProps as AntdColumnProps } from 'antd/lib/table'
import store from '@store'
import { ColumnHeader, TableIdEnum } from '@configs/table'
import { message } from 'antd'
import { isString, sStorage } from '@library'
import { getTableConfig } from '@services/session'

/**
 * 根据antd的API，title有可能是函数等，不是string会触发bug
 * 所以这里要么title是string类型，如果不是string就要设置一个_title来表示
 */
export type TableColumnProps<T> = AntdColumnProps<T> & {
  title: Exclude<AntdColumnProps<T>['title'], string>,
  _title: string,
  dataIndex: string,
} | AntdColumnProps<T> & {
  title: string,
  _title?: string,
  dataIndex: string,
}

/**
 * 获取用户特定table的行配置，行配置是本地配置一部分和后端配置一部分
 * 所以需要请求接口获取后端数据，把前后端数据拼接在一起。
 * 本方法使用了sessionStorage缓存
 *
 * @param tableId 传入指定的tableId
 * @param columns 原先的table的表头配置选项
 * @param disabledList 不可编辑的表头，字段为ColumnProps的dataIndex
 */
export function useTableColumn<T> (tableId: TableIdEnum, columns: TableColumnProps<T>[], disabledList?: string[]) {
  const { userInfo } = store.useSession()

  const [columnHeaders, setColumnHeaders] = useState<ColumnHeader[]>([])
  const key = useMemo(() => `${userInfo.belongModule}.${userInfo.companyId}.${userInfo.salesName}.${tableId}`, [tableId, userInfo])
  const [flag, setFlag] = useState(0)
  useEffect(() => {
    ;(async () => {
      const cachedHeaders = sStorage.get<ColumnHeader[]>(key)
      if (!_.isEmpty(cachedHeaders) && _.isArray(cachedHeaders)) {
        // 拿到缓存之后直接返回，阻止后面继续请求后台数据
        setColumnHeaders(cachedHeaders)
        return
      }

      const [res, err] = await getTableConfig(tableId)
      if (err) {
        message.error('获取列表表头配置失败' + err.message)
      }
      const fields = res?.fields || []
      const headers: ColumnHeader[] = columns.map<ColumnHeader>(c => ({
        // 根据antd的API，这里有可能是函数，会触发bug
        title: isString(c.title) ? c.title as string : c._title,
        dataIndex: c.dataIndex,
        hide: fields.some(f => f.key === c.dataIndex), // 后端返回的fields数组的每一项，如果key值和dataIndex相同，那么就不显示
        disabled: disabledList?.includes(c.dataIndex),
      }))
      setColumnHeaders(headers)
      sStorage.set(key, headers)
    })()
  }, [flag])
  // 强制获取的时候将取最新的数据，而不是缓存
  const forceRequest = () => {
    sStorage.remove(key)
    // console.log('缓存清除了', key)
    setFlag(f => f + 1)
  }

  /**
   * 根据行配置，生成列表的表头参数项
   */
  const columnProps = useMemo(() => columns.filter(c => !columnHeaders?.find(ch => ch.dataIndex === c.dataIndex)?.hide), [columnHeaders, columns])

  return [columnHeaders, columnProps, forceRequest] as const
}
