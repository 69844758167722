
import { TaskLogItem } from '@types'

export default class TaskLog {
  missionId: number
  missionType: number
  relatedId: string
  status: number
  creator: string
  createTime: string
  updateTime: string
  belongModule: number
  extend: string
  message: string

  constructor (TaskLog: TaskLogItem) {
    this.missionId = TaskLog?.missionId || undefined
    this.missionType = TaskLog?.missionType || undefined
    this.relatedId = TaskLog?.relatedId || undefined
    this.status = TaskLog?.status || undefined
    this.creator = TaskLog?.creator || undefined
    this.createTime = TaskLog?.createTime || undefined
    this.updateTime = TaskLog?.updateTime || undefined
    this.belongModule = TaskLog?.belongModule || undefined
    this.extend = TaskLog?.extend || '[]'
    this.message = TaskLog?.message || undefined
  }
}
