/**
 * @author tylerzzheng
 * 封装的Cos， 文档参考： https://cloud.tencent.com/document/product/436/11459
 */
import COS from 'cos-js-sdk-v5'
import { getCosSignature } from '@services/session'
import { message } from 'antd'
import { CosConfig } from '@configs'
import { randomString } from './random-string'

export interface SuccessReturnData {
  ETag: string, // 返回文件的 MD5 算法校验值。ETag 的值可以用于检查对象在上传过程中是否有损坏例如"09cba091df696af91549de27b8e7d0f6"，注意：这里的 ETag 值字符串前后带有双引号
  Location: string, // 后端需要的就是cos返回的这个字段
  headers: object, // 请求返回的头部信息
  statusCode: number // 请求返回的 HTTP 状态码，例如200、403、404等

}

const { Bucket, Region, StorageClass, ...otherParams } = CosConfig

const cos = new COS({
  // 必选参数
  getAuthorization: async function (options, callback) {
    // 服务端获取签名，请参考对应语言的 COS SDK：https://cloud.tencent.com/document/product/436/6474
    // 注意：这种有安全风险，后端需要通过 method、pathname 严格控制好权限，例如不允许 put / 等
    const [res, err] = await getCosSignature({
      method: options.Method,
      path: '/' + options.Key,
    })
    if (err || _.isEmpty(res?.authorization)) {
      message.error(`获取临时签名失败${err.message}`)
      return err
    }
    callback({
      // @ts-ignore
      Authorization: res.authorization,
      // XCosSecurityToken: data.sessionToken, // 如果使用临时密钥，需要把 sessionToken 传给 XCosSecurityToken
    } as any)
  },
  // 可选参数
  FileParallelLimit: 3, // 控制文件上传并发数
  ChunkParallelLimit: 3, // 控制单个文件下分片上传并发数
  ProgressInterval: 1000, // 控制上传的 onProgress 回调的间隔
})

type ProgressCB = (percent: number) => void
type CosPutObject = (file: File, progressCB?: ProgressCB, key?: string) => Promise<[ null, Error] | [SuccessReturnData, null]>

/**
 * cos上传文件
 * https://cloud.tencent.com/document/product/436/35649#.E7.AE.80.E5.8D.95.E4.B8.8A.E4.BC.A0.E5.AF.B9.E8.B1.A1
 * @param file 要上传的文件
 * @param progressCB 进度回掉方法
 * @param key 上传的时候的文件命名，默认会随机字符串混合文件名为key
 */
export const cosPutObject: CosPutObject = (file, progressCB?, key?) => {
  return new Promise((resolve, reject) => {
    const Key = key || randomString(18, { number: false, symbol: false }) + '-' + file?.name
    const newFile = new File([file], Key)
    cos.putObject({
      Bucket, /* 必须 */
      Region, /* 存储桶所在地域，必须字段 */
      StorageClass,
      ...otherParams,
      Key, /* 必须 */
      Body: newFile, // 上传文件对象
      onProgress: function (progressData) {
        if (_.isFunction(progressCB)) progressCB(progressData.percent * 100) // progressData.percent * 100 注意这里返回的data的进度是0到1，而antd的是0-100
      },
    }, function (err, data) {
      if (err) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject([null, err])
      } else {
        // @ts-ignore
        resolve([data, null])
      }
    })
  })
}

/**
 * cos文件下载
 * 注意： 该接口用于读取对象内容，如果需要发起浏览器下载文件 建议直接使用cosGetObjectUrl更简单一下
 * https://cloud.tencent.com/document/product/436/35649#.E4.B8.8B.E8.BD.BD.E5.AF.B9.E8.B1.A1
 * @param key 要下载的文件的key
 * @param progressCB ,进度回调
 * @param cb 下载完成的回调，默认为a标签下载
 *
 * 参考示例：
  const [blob, err] = await cosGetObject(file.name)
  if (err) {
    message.error(`下载失败: ${err.message}`)
  } else {
    message.success('下载完成', blob)
  }
 */
type CosGetObject = (key: string, progressCB?: ProgressCB) => Promise<[null, Error] | [Blob, null]>
export const cosGetObject: CosGetObject = (key, progressCB?) => {
  return new Promise((resolve, reject) => {
    cos.getObject({
      Bucket, /* 必须 */
      Region, /* 存储桶所在地域，必须字段 */
      Key: key, /* 必须 */
      onProgress: function (progressData) {
        if (_.isFunction(progressCB)) progressCB(progressData.percent * 100) // progressData.percent * 100 注意这里返回的data的进度是0到1，而antd的是0-100
      },
    }, function (err, data) {
      if (err) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject([null, err])
      } else {
        // @ts-ignore
        const blob = new Blob(data?.Body ?? data?.body)
        resolve([blob, null])
      }
    })
  })
}

type CosGetObjectUrl = (key: string, sign?: boolean) => Promise<[ null, Error] | [string, null]>
/**
 * 获取文件下载的url https://cloud.tencent.com/document/product/436/35651
 * @param key 要下载的文件key
 * @param sign 是否签名 默认为真
 */
export const cosGetObjectUrl: CosGetObjectUrl = (key, sign = true) => {
  return new Promise((resolve, reject) => {
    cos.getObjectUrl({
      Bucket,
      Region,
      Key: key,
      Sign: sign,
      Method: 'GET',
    }, function (err, data) {
      if (err) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject([null, err])
      } else {
        resolve([data?.Url, null])
      }
    })
  })
}

/**
 * 删除对象 https://cloud.tencent.com/document/product/436/35649#.E5.88.A0.E9.99.A4.E5.8D.95.E4.B8.AA.E5.AF.B9.E8.B1.A1
 * @param key
 */
export const cosDeleteObjectUrl = (key: string): Promise<Error|null> => {
  return new Promise((resolve, reject) => {
    cos.deleteObject({
      Bucket,
      Region,
      Key: key,
    }, function (err) {
      if (err) {
        reject(err)
      } else {
        resolve()
      }
    })
  })
}

export default cos
