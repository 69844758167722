/**
 * 导入 XLSX 文件组件
 */
import * as React from 'react'
import { Upload, Button, Modal, message } from 'antd'
import { RcFile } from 'antd/lib/upload/interface'
import { read, utils } from 'xlsx'

interface ImportXlsxButtonProps<T = any> {
  // 按钮文案
  buttonText?: string

  // 确认弹窗的文案
  confirmContent?: string

  // 类名
  className?: string

  // 数据回调
  onReadData?: (data: T) => void
}

export class ImportXlsxButton extends React.Component<ImportXlsxButtonProps, {}> {
  handleFile = (file: RcFile) => {
    const reader = new FileReader()

    reader.onload = e => {
      const rawWorkBook = read((e.target as any).result, { type: 'binary', cellDates: true })
      const workBook = []
      for (let i = 0; i < rawWorkBook.SheetNames.length; i++) {
        const workBookItem = utils.sheet_to_json(rawWorkBook.Sheets[rawWorkBook.SheetNames[i]])
        workBook.push(...workBookItem)
      }
      this.props.onReadData(workBook)
    }

    reader.readAsBinaryString(file)
  }

  // 确认导入
  confirmUpload = (file: RcFile) => {
    // 文件大小限制【对应nginx配置client_body_buffer_size 1024k】
    if (file.size > 1024 * 1024) {
      message.error('当前文件过大,请保证文件大小不超过1M')
      return false
    }

    Modal.confirm({
      title: '确认导入文件？',
      content: this.props.confirmContent,
      onOk: () => this.handleFile(file),
    })

    // 总是返回 false，不触发实际的上传请求
    // 导入的过程自定义
    return false
  }

  render () {
    return (
      <Upload action="#" accept=".xlsx" showUploadList={false} beforeUpload={this.confirmUpload}>
        <Button type="primary" className={this.props.className}>
          {this.props.buttonText || '导入'}
        </Button>
      </Upload>
    )
  }
}
