/**
 * 前端 Store
 * @author jasonelchen
 */
import { composeContainer } from '@library'
import { useSession } from './session'
import { useGlobal } from './global'

export const { Provider, containers } = composeContainer({
  useSession,
  useGlobal,
})

export default containers
