/**
 * 我的申请
 * @author wenyunwan
 */
import React, { FunctionComponent, useMemo, useState, useCallback } from 'react'
import { Card, Table, Button, Input, message, Tag, Select } from 'antd'
import { PageHeader, ModalForm, ModalFormProp, CommonTreeSelector, UserAvatar } from '@components'
import { GetAccessRolesParam, LabelInValue, SalesApprovalApplyListPayload, SalesApprovalApplyItem } from '@types'
import { ColumnProps } from 'antd/lib/table'
import { getTableXwidth } from '@configs/table'
import { useTableService } from '@hooks/useTableService'
import { roleTree } from '../../../components/CommonTreeSelector/configs'
import { getSalesRoleList } from '@services/organazition'
import { getAccessRoles, getSalesApprovalApplyList } from '@services/my-apply'
import store from '@store'
import { salesApprovalApplyMap } from '@configs/map'
import { handleNumber } from '@library'

const initFilter: SalesApprovalApplyListPayload = {
  page: 1,
  pageSize: 10,
}

const MyApplyPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    setFilter,
    rowKey,
    filter,
    reload,
  } = useTableService(getSalesApprovalApplyList, initFilter, '获取权限申请记录列表失败')

  const tagColor = {
    2: 'processing',
    3: 'success',
    4: 'error',
  }

  const columns: ColumnProps<SalesApprovalApplyItem>[] = useMemo(() => [
    { title: '审批标题', dataIndex: 'title', width: 150, ellipsis: true },
    { title: '审批编号', dataIndex: 'approvalId', width: 150 },
    { title: '申请角色', dataIndex: ['roleRecordInfo', 'roleName'], width: 150, render: value => value?.split(';').join('、') },
    { title: '内部员工企微ID', dataIndex: ['roleRecordInfo', 'staffName'], width: 120 },
    { title: '申请原因', dataIndex: ['roleRecordInfo', 'reason'], width: 150, ellipsis: true },
    {
      title: '申请状态',
      dataIndex: 'status',
      width: 80,
      render: status => <Tag color={tagColor?.[status]}>{salesApprovalApplyMap[status]}</Tag> || '-',
    },
    {
      title: '申请人',
      dataIndex: 'salesName',
      width: 150,
      render: value => <UserAvatar salesName={value} />,
    },
    { title: '最后审批时间', dataIndex: 'approveTime', width: 150 },
    { title: '当前审批人', dataIndex: 'handlers', width: 200, render: value => value?.split(';').join('、') },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  const { userInfo } = store.useSession()

  const [ownRoles, setOwnRoles] = useState<LabelInValue[]>([])

  // 申请权限弹窗表单
  const [modalLoading, setModalLoading] = useState<boolean>(false)
  const [showModalForm, setShowModalForm] = useState(false)

  const ApplyForm = useMemo(() => ModalForm<GetAccessRolesParam, GetAccessRolesParam>(), [])
  const applyFormItems: ModalFormProp<GetAccessRolesParam, GetAccessRolesParam>['fieldItems'] = useMemo(() => [
    {
      label: '选择角色',
      field: 'roles',
      required: true,
      children: (
        <CommonTreeSelector
          {...roleTree}
          treeCheckable
          treeCheckStrictly
          treeExpandAction="click"
          translate={role => {
            const alreadyHaveRole = !!ownRoles.find(item => item.roleId === role.roleId)
            return {
              id: role.roleId,
              value: role.roleId,
              title: alreadyHaveRole ? role.name + '（已有）' : role.name,
              isLeaf: !role.hasSub,
              other: role.tag, // 加载子节点的时候需要
              checkable: !alreadyHaveRole && !(role?.subRoles?.length > 0),
              disableCheckbox: alreadyHaveRole,
              disabled: alreadyHaveRole,
            }
          }}
          loading={modalLoading}
          multiple
          labelInValue // 这里因为部分value不会在树结构中返回回来。前端无法翻译。所以必须使用labelInValue模式
        />
      ),
      fromFieldValue: (val: any[]) => ({
        roles: val?.map(item => ({
          roleId: item.value,
          roleName: item.label,
        })),
      }),
    },
    {
      tooltip: '如果是腾讯内部员工，必须填写企微ID，否则审批不通过',
      label: '内部员工名称',
      field: 'staffName',
      children: <Input placeholder="请输入腾讯员工企微ID" />,
      fromFieldValue: fieldVal => ({ staffName: fieldVal as string || undefined }),
    },
    {
      label: '申请原因',
      field: 'reason',
      required: true,
      children: <Input.TextArea placeholder="请输入申请原因" />,
    },
  ], [ownRoles, modalLoading])

  // 打开申请权限弹窗表单
  const handleApply = async () => {
    setShowModalForm(true)
    setModalLoading(true)
    const [res, err] = await getSalesRoleList(userInfo.salesName)
    if (err) {
      message.error('获取员工角色列表出错' + err.message)
      return
    }
    setOwnRoles(res?.list?.map(role => ({
      ...role,
      label: role.name,
      value: role.roleId,
    })))
    setModalLoading(false)
  }

  // 提交申请权限表单
  const submit = useCallback(async (formData: GetAccessRolesParam) => {
    const [, err] = await getAccessRoles(formData)
    if (err) {
      message.error(`角色权限申请失败:${err.message}`)
    } else {
      setShowModalForm(false)
      message.success('角色权限申请成功')
      reload()
    }
  }, [])

  return (
    <>
      <PageHeader
        title="我的申请"
        extra={(
          <>
            <span>申请状态: </span>
            <Select
              allowClear
              placeholder="未筛选则默认全部"
              style={{ width: 180 }}
              onChange={(val) => setFilter({ ...filter, status: val })}
            >
              {Object.entries(salesApprovalApplyMap).map(([value, text]) => {
                const val = handleNumber(value)
                return (<Select.Option value={val} key={val}> <Tag color={tagColor?.[val]}>{text}</Tag> </Select.Option>)
              })}
            </Select>
          </>
        )}
      />
      <Card className="mt20">
        <Button type="primary" onClick={() => handleApply()}>申请权限</Button>
        <Table
          className="mt20"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
        />
      </Card>
      {showModalForm && (
        <ApplyForm
          title="角色权限申请"
          fieldItems={applyFormItems}
          open
          onSubmit={submit}
          formProps={{ labelCol: { span: 5 }, wrapperCol: { span: 19 } }}
          onCancel={() => setShowModalForm(false)}
        />
      )}
    </>
  )
}
export default MyApplyPage
