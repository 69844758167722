/**
 * 线索相关
 * @author tylerzzheng
 */
import { requestApi, getInterfaceName, requestApiV2 } from '@library'
import {
  CommonList,
  LeadPoolFilter,
  ILeadPoolItem,
  NewLead,
  SourceTag,
  TouchStatus,
  UpdateLeadsFilter,
  AssignLeadsFilter,
  TouchStatusFilter,
  AddLeadBatchFilter,
  ReuseLeadFilter,
  FailLeadData,
  GetAddLeadCustomerFollower,
  CirculateRecordFilter, CirculateRecordItem,
  LeadTagsItem, LeadIntentionLevel,
} from '@types'

/* 获取客户来源标签 */
export function getSourceTags () {
  return requestApiV2<SourceTag[]>('/trpc.tcc.tcc_main.Tag/GetSourceTag')
}

/* 获取触达状态 */
export function getTouchStatus (filter?: TouchStatusFilter) {
  return requestApiV2<TouchStatus[]>('/trpc.tcc.tcc_main.Lead/GetTouchStatus', filter)
}

/* 获取线索意向等级 https://opc-mock.woa.com/project/530/interface/api/3434 */
export function getLeadIntentionLevel () {
  return requestApiV2<LeadIntentionLevel[]>('/trpc.tcc.tcc_main.Lead/GetLeadIntentionLevel')
}

/* 添加线索 */
export function addLead (lead: NewLead) {
  return requestApi<{}>('AddLead', lead)
}

/* 获取添加线索客户跟进人 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001797197 */
export function getLeadCustomerFollower (params: { name: string, uin: string, phone: string }) {
  return requestApiV2<GetAddLeadCustomerFollower>('/trpc.tcc.tcc_main.Lead/CheckLeadFollower', params)
}

/* 获取线索池列表 */
export function getLeadPoolList (leadFilter: LeadPoolFilter) {
  return requestApiV2<CommonList<ILeadPoolItem>>(getInterfaceName('/trpc.tcc.tcc_main.Lead/GetLeadList', leadFilter), leadFilter)
}

/**
 *  根据cid获取线索池列表，是全量非分页的
 *  http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002013359
 *  */
export function getLeadListByCid (filter: {cid: string}) {
  return requestApi<ILeadPoolItem[]>('GetLeadListByCid', filter)
}

/* 下载线索池列表 */
export function exportLeadList (leadFilter: LeadPoolFilter) {
  return requestApiV2<CommonList<ILeadPoolItem>>('/trpc.tcc.tcc_main.Lead/ExportLeadList', leadFilter)
}

/**
 * 更新线索
 * @param payload
 */
export function updateLeads (payload: UpdateLeadsFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Lead/UpdateLeads', payload)
}

/**
 * 分配线索 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000511503
 * @param payload
 */
export function assignLeads (payload: AssignLeadsFilter) {
  return requestApi<{}>('AssignLeads', payload)
}

/**
 * @deprecated 已废弃
 * 回收废弃线索
 * @param payload
 */
export function reuseLead (payload: ReuseLeadFilter) {
  return requestApi<{}>('ReuseLead', payload)
}

/**
 * 批量上传线索
 * @param payload
 * @deprecated 已过时 废弃
 */
export function addLeadBatch (payload: AddLeadBatchFilter) {
  return requestApi<{failLeadData: FailLeadData[]}>('AddLeadBatch', payload)
}

// 获取线索流转记录
export function getLeadCirculateRecordList (params: CirculateRecordFilter) {
  return requestApi<CommonList<CirculateRecordItem>>(getInterfaceName('GetLeadChangeRecordList', params), params)
}

/**
 * 获取线索标签 http://opc-mock.woa.com/project/530/interface/api/3383
 */
export function getLeadTags () {
  return requestApiV2<LeadTagsItem[]>('/trpc.tcc.tcc_main.Lead/GetLeadTags', { valid: true }) // 只允许选择现行有效的线索标签
}
