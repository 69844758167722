import React, { useCallback, useMemo, useState, useRef } from 'react'
import { Modal, message, Alert, Button } from 'antd'
import { ModalForm, AuthChecker } from '@components'
import { RobotOutBoundFormItems, BatchCallRobotFormItems } from '../ModalForm/configs'
import { pick, debounce } from 'lodash'
import {
  CallRobotSourceEnum,
  CreateRobotCallRollParams,
} from '@types'
import { createRobotCallRoll } from '@services/robot'

interface CallRobotProps {
  filterCondition?: any
  loading?: any
  // statusParam?: any
  selectedLeadList?: any
  filteredLeadList?: any
  sourceType?: number
  selectedCids?: string[]
  filterType?: number
  reload?: Function
}

export const CallRobot: React.FC<CallRobotProps> = (props) => {
  const {
    filterCondition, loading, selectedLeadList, reload,
    filteredLeadList, sourceType, selectedCids, filterType,
  } = props

  const RobotOutBoundForm = useMemo(() => ModalForm<CreateRobotCallRollParams, CreateRobotCallRollParams>(), [])
  const [showRobotOutBoundForm, setShowRobotOutBoundForm] = useState<boolean>(false)
  const formRef = useRef()
  const initValues = { excludeNamedCustomer: true }

  const handleCreateRobotCallRoll = debounce(async (value) => {
    const param = {
      rollTitle: value.rollTitle,
      taskId: value.taskId,
      excludeNamedCustomer: value.excludeNamedCustomer,
      rollDescription: value.rollDescription,
      filterType,
      filterCondition: sourceType === CallRobotSourceEnum.CALL_RECORD ? value?.uploadFileKey?.[0] : filterCondition,
      filterRestriction: pick(value, ['callOutDay', 'answeredDay', 'publicTags']),
      sourceType,
    }
    console.log('创建机器人名单', param)
    const [, err] = await createRobotCallRoll(param)
    if (err) {
      message.info(`机器人名单创建失败${err.message}`)
      return
    }
    message.info('提交成功，稍后请留意名单状态')
    reload && reload()
    setShowRobotOutBoundForm(false)
  }, 500)

  const onClickSubmit = useCallback(() => {
    // @ts-ignore
    formRef.current && formRef.current.submitForm({})
  }, [formRef])

  let tipsDom = <></>

  if (sourceType === CallRobotSourceEnum.LEAD_LIST || sourceType === CallRobotSourceEnum.LEAD_LIST_SENT) {
    tipsDom = selectedLeadList?.length > 0
      ? (
        <Alert
          message={
            <>当前勾选
              <span style={{ color: 'red' }}>{selectedLeadList?.length}</span>
              条线索，去重后按<span style={{ color: 'red' }}>{filteredLeadList?.length}</span>个客户提交外呼名单
            </>
          }
          type="info" style={{ margin: '10px' }}
        />
      )
      : (<Alert message="当前按筛选条件的全量客户提交外呼名单，请留意客户数量" type="info" style={{ margin: '10px' }} />)
  } else if (sourceType === CallRobotSourceEnum.CUSTOMER_LIST) {
    tipsDom = selectedCids?.length > 0
      ? (<Alert message={<>当前按勾选的<span style={{ color: 'red' }}>{selectedCids?.length}</span>个客户提交外呼名单</>} type="info" style={{ margin: '10px' }} />)
      : (<Alert message="当前按筛选条件的全量客户提交外呼名单，请留意客户数量" type="info" style={{ margin: '10px' }} />)
  } else if (sourceType === CallRobotSourceEnum.CALL_RECORD) {
    tipsDom = (
      <Alert
        message={
          <>注意：1、请勿修改模板表头 <br /> <div style={{ marginLeft: 42 }}>2、excel表格不超过2万个客户</div></>
        } type="info" style={{ margin: '10px' }}
      />
    )
  }

  return (
    <>
      <AuthChecker points="robotOutBound">
        <Button className="ml10" onClick={() => setShowRobotOutBoundForm(true)}>
          {sourceType === CallRobotSourceEnum.CALL_RECORD ? '批量创建外呼名单' : '机器人外呼'}
        </Button>
      </AuthChecker>
      <Modal
        open={showRobotOutBoundForm}
        width={800}
        title={sourceType === CallRobotSourceEnum.CALL_RECORD ? '批量创建外呼名单' : '创建外呼名单'}
        onCancel={() => setShowRobotOutBoundForm(false)}
        onOk={onClickSubmit}
        okButtonProps={{ loading }}
        destroyOnClose
      >
        {tipsDom}
        <RobotOutBoundForm
          isModal={false}
          cRef={formRef}
          formProps={{ labelCol: { span: 4 }, wrapperCol: { span: 19 } }}
          fieldItems={sourceType === CallRobotSourceEnum.CALL_RECORD ? BatchCallRobotFormItems : RobotOutBoundFormItems}
          onSubmit={handleCreateRobotCallRoll}
          onCancel={() => setShowRobotOutBoundForm(false)}
          initFormValues={initValues}
        />
      </Modal>
    </>
  )
}
