import React, { useEffect, useState, useMemo } from 'react'
import SdkRender from './SdkRender'
import { Card, Form, Select, Row, Col, Table, Button, Typography, message, Modal, Input } from 'antd'
import { PageHeader, CommonFilters } from '@components'
import {
  querySpuCodeList,
  querySelectionList,
  cancelSelection,
  querySelectionListByUin,
} from '@services/customer'
import { saasConfigFilterItems } from '../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { saasStatusMap } from '@configs/map'
import store from '@store'
import './style.less'

const { Paragraph } = Typography

interface Props {
  uinMap: any
  type?: string
  uinListWithChannel?: any
}

const CopBuyPage: React.FC<Props> = (props) => {
  const { uinMap = [], type, uinListWithChannel } = props
  const [form] = Form.useForm()
  const [formForRemark] = Form.useForm()
  const customerUin = Form.useWatch('customerUin', form)
  const productList = Form.useWatch('productList', form)
  const [spuCodeList, setSpuCodeList] = useState([])
  const [remarkModal, setRemarkModal] = useState(false)
  const [remarkLoading, setRemarkLoading] = useState(false)
  const [validSelectionList, setValidSelectionList] = useState<any>()
  const [currentSelection, setCurrentSelection] = useState('')
  const { userInfo } = store.useSession()

  const myUinMap = [...uinMap]

  const initFilter: any = {
    ownerUin: type === 'modal' ? myUinMap?.map(o => String(o?.value)) : null, // TODO
  }

  const {
    loading,
    list,
    pagination,
    setFilter,
    resetFilter,
    rowKey,
    reload,
  } = useTableService(querySelectionList, initFilter, '获取历史产品配置单失败')

  const fetchSpuCodeList = async () => {
    const [res, err] = await querySpuCodeList({})
    if (err) {
      message.error(err?.message || '请求产品列表失败')
    } else {
      setSpuCodeList(res?.list)
      form.setFieldsValue({ productList: { label: res?.list?.[0]?.spuName, value: res?.list?.[0]?.spuCode } })
    }
  }

  useEffect(() => {
    reload()
  }, [customerUin, productList, validSelectionList])

  // 查找所有UIN的有效配置单为了回写编辑时需要的数据
  const fetchValidSelectionList = () => {
    // 有效单据状态待下单与确认中
    querySelectionListByUin({ status: [1, 2], ownerUin: myUinMap?.map(o => String(o?.value)) }).then(([res, err]) => {
      if (!err) {
        setValidSelectionList(res?.list)
      }
    })
  }

  useEffect(() => {
    fetchSpuCodeList()
    if (type === 'modal') fetchValidSelectionList()
  }, [])

  // 计算该给sdk传入的通道标签信息
  const judgeSalesTags = useMemo(() => {
    console.log('====================judgeSalesTags', uinListWithChannel, customerUin)
    // const salesTagsResults = {}
    const channelModel: { [key: string]: any } = {
      telemarket: ['SC106', 'SC109'],
      tele_cross_direct: ['SC101', 'SC115', 'SC116', 'SC117'],
      tele_cross_channel: ['SC104', 'SC105'],
    }
    const salesTags = uinListWithChannel?.filter(o => o?.uin === customerUin)?.map(uin => {
      return uin.salesChannelId1.map(channel => {
        for (const key in channelModel) {
          if (channelModel[key].includes(channel)) {
            return key
            // salesTagsResults[key] = key
          }
        }
      })
    })
    const result = [...new Set(salesTags?.[0])]
    console.log('当前uin的通路计算结果为---', result)
    console.log('当前uin的通路计算结果为---', result?.includes('tele_cross_direct') ? 'tele_cross_direct' : result?.[0])
    return { sale_channel: result?.includes('tele_cross_direct') ? 'tele_cross_direct' : result?.[0] }
  }, [uinListWithChannel, customerUin])

  const columns = [
    {
      title: '创建时间',
      dataIndex: 'createTime',
      key: 'createTime',
      width: 120,
    },
    {
      title: 'UIN',
      dataIndex: 'ownerUin',
      key: 'ownerUin',
      width: 150,
    },
    {
      title: '配置单',
      dataIndex: 'selectionId',
      key: 'selectionId',
    },
    {
      title: '产品',
      // dataIndex: 'spuName',
      width: 200,
      dataIndex: 'spuCode',
      key: 'spuCode',
      render: (value, record) => (
        <>
          <div>{record?.spuCode}</div>
          <div>{record?.spuName}</div>
        </>
      ),
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      render: (value) => saasStatusMap && saasStatusMap?.[value],
    },
    {
      title: '订单ID',
      dataIndex: 'bigDealId',
      key: 'bigDealId',
    },
    {
      title: '创建人',
      dataIndex: 'creator',
      key: 'creator',
      width: 170,
    },
    {
      title: '归属人',
      dataIndex: 'operator',
      key: 'operator',
      width: 160,
    },
    {
      title: '配置单URL',
      dataIndex: 'confirmUrls',
      key: 'confirmUrls',
      width: 250,
      fixed: 'right',
      render: (value) => {
        return value && value?.map(o => (
          <>
            <Paragraph copyable={{ text: o?.urlValue }} ellipsis={{ suffix: '.' }}>{o?.urlText} {o?.urlValue}</Paragraph>
          </>))
      },
    },
    {
      title: '操作',
      fixed: 'right',
      dataIndex: 'oparete',
      width: 70,
      render: (_, record) => (
        // 待确认和待下单状态才显示作废按钮
        record?.operator === userInfo?.openid && [1, 2].includes(record?.status) && (
          <Button
            type="link"
            onClick={() => {
              setCurrentSelection(record?.selectionId)
              formForRemark.resetFields()
              setRemarkModal(true)
            }}
          >
            作废
          </Button>
        )
      ),
    },
  ]

  const renderConfigForm = () => (
    <Form form={form} style={{ width: '100%' }} labelAlign="left" labelCol={{ span: 6 }}>
      <Row gutter={36}>
        <Col span={9}>
          <Form.Item name="customerUin" label="客户UIN" initialValue={myUinMap?.[0]?.authState !== -1 ? myUinMap?.[0]?.value : null}>
            <Select options={myUinMap?.map(o => ({ ...o, disabled: o?.authState === -1 }))} style={{ width: 200 }} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item name="productList" label="产品列表" initialValue={{ label: spuCodeList?.[0]?.spuName, value: spuCodeList?.[0]?.spuCode }}>
            <Select labelInValue options={spuCodeList?.map(o => ({ label: o?.spuName, value: o?.spuCode }))} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  )

  const renderSearchForm = () => {
    return (
      <CommonFilters
        className="custom-Label"
        itemList={saasConfigFilterItems}
        colProps={{ flex: '1 1 300px' }}
        standstill={9}
        onSubmit={filter => setFilter({ ...filter, page: 1 })}
        onReset={() => resetFilter(initFilter)}
      />
    )
  }

  return (
    <div className="tcc-cop-buy-main">
      {type !== 'modal' && <PageHeader title="SaaS产品配置单" />}
      <Card className={type !== 'modal' && 'mt20'}>
        {type === 'modal' ? renderConfigForm() : renderSearchForm()}
        {type === 'modal' && (
          <Row style={{ backgroundColor: '#80808012' }}>
            <div id="component" style={{ margin: '0 auto', width: '100%', height: 500 }}>
              {!loading && customerUin && productList?.value && (
                <SdkRender
                  saleTags={judgeSalesTags as any}
                  customerUin={customerUin}
                  productList={productList}
                  copId={
                    // 待确认和待下单状态才回写编辑
                    validSelectionList?.filter((o: any) => [1, 2].includes(o?.status) && String(o?.ownerUin) === String(customerUin))?.[0]?.selectionId
                  }
                  reload={reload}
                  fetchValidSelectionList={fetchValidSelectionList}
                />
              )}
            </div>
          </Row>
        )}
        {type === 'modal' && <h3 style={{ marginTop: '15px' }}>历史配置单</h3>}
        <Table
          style={{ marginTop: 12 }}
          pagination={pagination}
          loading={loading}
          rowKey={rowKey}
          dataSource={list || []}
          columns={columns as any}
          scroll={{ x: 1450 }}
          size="small"
        />
      </Card>
      <Modal
        open={remarkModal}
        title="作废配置单"
        confirmLoading={remarkLoading}
        onOk={async () => {
          const values = await form.validateFields()
          setRemarkLoading(true)
          const [, err] = await cancelSelection({ selectionId: currentSelection, result: values?.remark })
          if (!err) {
            message.success('作废成功')
            setRemarkModal(false)
            fetchValidSelectionList()
            reload()
          } else {
            message.error(err?.message || '作废失败')
          }
          setRemarkLoading(false)
        }}
        onCancel={() => setRemarkModal(false)}
        destroyOnClose
      >
        <Form form={formForRemark}>
          <Form.Item name="remark" label="原因" rules={[{ required: true, message: '请输入作废原因!' }]}>
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default CopBuyPage
