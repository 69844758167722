/**
 * 用a标签下载文件
 */
function downloadByATag(name: string, url: string, target?: string): void
function downloadByATag(name: string, blob: Blob, target?: string): void
function downloadByATag (name: string, data: Blob | string, target?: string): void {
  const url = data instanceof Blob ? window.URL.createObjectURL(data) : data
  const a = document.createElement('a')
  a.href = url
  a.target = target || '_blank'
  a.download = name
  a.click()
  window.URL.revokeObjectURL(url)
}
export default downloadByATag
