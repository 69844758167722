/**
 * 转出客户池
 * @author tylerzzheng
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Card, Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import { CommonFilters, PageHeader, TableDownload, UserAvatar } from '@components'
import { TableColumnProps, useTableColumn } from '@hooks'
import { sortTypeEnum, TransferFilter, TransferItem } from '@types'
import { getCustomerTransferList, exportCustomerTransferList } from '@services/customer'
import { antdSortMap, customerTypeMap } from '@configs/map'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { alterFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { trimStr } from '../../../library/utils'

const initFilter: TransferFilter = {
  page: 1,
  pageSize: 10,
  sort: 'transferTime',
  sortType: sortTypeEnum.DESC,
}

const CustomerAlterPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getCustomerTransferList, initFilter, '获取客户转出池列表失败')

  const onTableChange: TableProps<TransferItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<TransferFilter>(sorter)
    setFilter(sort)
  }

  const custColumns: TableColumnProps<TransferItem>[] = useMemo(() => [
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 160,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: 'CID', dataIndex: 'cid', width: 250 },
    { title: '客户类型', dataIndex: 'customerType', width: 120, render: customerType => customerTypeMap[customerType] || '' },
    { title: '转出前跟进人', dataIndex: 'lastFollower', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '转出前所属组', dataIndex: 'lastFollowerGroupName', width: 200 },
    { title: '客户创建时间', dataIndex: 'createTime', width: 180 },
    {
      title: '客户转出时间',
      dataIndex: 'transferTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'transferTime' ? antdSortMap[filter.sortType] : undefined,
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.CUSTOMER_ALTER, custColumns, ['name'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="转出客户池" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={alterFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.CUSTOMER_ALTER} onSuccess={() => forceRequest()} />
        <TableDownload className="ml20" exportMethod={exportCustomerTransferList} filter={filter} />
        <Table
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
export default CustomerAlterPage
