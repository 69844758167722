/**
 * 组织架构相关接口
 * @author tylerzzheng
 */
import {
  Agent,
  AgentFilter,
  ADDOrgGroupParam,
  CallNumberFilter,
  CommonList,
  EditOrgGroupParam,
  EditStaffFilter,
  IOrgGroup,
  OrgGroupFilter,
  IStaff,
  StaffFilter,
  ISalesRole,
  IRoleLayer,
  UpdateCompanyManagerParams, IRoleLayerFilter,
} from '@types'
import { requestApi, requestApiV2 } from '@library'

/* 获取职能组列表 登录态调用 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000510745
 * 当值为-1时，在部分接口拉取全量数据
 */
export function getOrgGroupList (orgGroupFilter: OrgGroupFilter) {
  return requestApiV2<CommonList<IOrgGroup>>('/trpc.tcc.tcc_main.SalesGroup/GetSalesGroupList', orgGroupFilter)
}

/* 添加职能组 */
export function addOrgGroup (orgGroupParam: ADDOrgGroupParam) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.SalesGroup/AddSalesGroup', orgGroupParam)
}

/* 编辑职能组 */
export function editOrgGroup (orgGroupParam: EditOrgGroupParam) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.SalesGroup/UpdateSalesGroup', orgGroupParam)
}

/* 删除职能组 */
export function deleteOrgGroup (groupId: {groupId: number}) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.SalesGroup/DeleteSalesGroupById', groupId)
}

/* 根据公司ID获取可用主号 */
export function GetCallNumberByCompanyId (callNumber: CallNumberFilter) {
  return requestApiV2<{availableNum: string[]}>('/trpc.tcc.tcc_main.Company/GetCallNumberByCompanyId', callNumber)
  // return Promise.resolve([['23424', '2424', '3456345', '34535']])
}

/* 获取员工列表
 * 当值为-1时，在部分接口拉取全量数据
 */
export function getStaffList (staffFilter: StaffFilter) {
  return requestApiV2<CommonList<IStaff>>('/trpc.tcc.tcc_main.Sales/GetSalesInfoList', staffFilter)
}

/**
 * 导出员工数据
 * @param staffFilter
 */
export function exportSalesInfoList (staffFilter: StaffFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sales/ExportSalesInfoList', staffFilter)
}

/* 编辑员工 */
export function updateStaff (editStaffFilter: EditStaffFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sales/UpdateSalesInfo', editStaffFilter)
}

/* 批量编辑员工 */
export function assignSalesToGroup (editStaffFilter: {groupId: number, salesNames: string[]}) {
  return requestApi<{}>('AssignSalesToGroup', editStaffFilter)
}

/** 添加员工 */
export function addSalesInfo (StaffFilter: StaffFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sales/AddVirtualSales', StaffFilter)
}

/* 删除职能组 */
export function deleteStaff (staffFilter: {id: number}) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sales/DeleteSalesInfoById', staffFilter)
}

/* 获取员工角色列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001730705 */
export function getSalesRoleList (salesName: string) {
  return requestApiV2<{ list: Array<ISalesRole> }>('/trpc.tcc.tcc_main.Sales/GetSalesRoleList', { salesName })
}

/* 获取角色树 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001730711 文档未更新，参数应为tag */
export function getRoleLayer (roleLayerFilter: IRoleLayerFilter) {
  return requestApiV2<Array<IRoleLayer>>('/trpc.tcc.tcc_main.Sales/GetRoleLayer', roleLayerFilter)
}

/**
 * http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002085057
 */
export function UpdateCompanyManager (params: UpdateCompanyManagerParams) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Company/UpdateCompanyManager', params)
}

/* 获取代理商 http://opc-mock.woa.com/project/530/interface/api/3327 */
export function getAgentList (filter: AgentFilter) {
  return requestApiV2<CommonList<Agent>>('/trpc.tcc.tcc_main.Agent/GetAgentList', filter)
}
