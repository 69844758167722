import { getCustomerList } from '@services/customer'
import { getIndicatorCustomerList } from '@services/task'
import { CommonList, Pagination } from '@types'
import { handleNumber, isEmpty, isNullOrUndefined, isObject, lStorage, notEmptyArray } from '@library'
import { message } from 'antd'
import { getResList } from '../../../../library/utils'

// service不能存到sessionStorage里，只能在这里映射, 以后有新的接口只要配置在这里即可
const serviceNameMap = {
  getCustomerList,
  // getSalesNotHandleCustomerList,
  getIndicatorCustomerList,
} as const

const _id = '_CUSTOMER_DETAIL_QUEUE_'

// const _maxTime = 60 * 1000 // 队列的最大保存时间
const _maxTime = 7 * 30 * 24 * 60 * 60 * 1000 // 队列的最大保存时间

export interface CustomerQueue {
  // 待跟进客户队列 // 没有分页则只传此项
  customerIds: number[]
  // service名称 // 如果还有分页的，必传filter和serviceName. serviceName如果不支持 需配置serviceNameMap
  serviceName?: keyof typeof serviceNameMap
  // 当前筛选条件  // 如果还有分页的，必传filter和serviceName
  filter?: Parameters<typeof serviceNameMap[keyof typeof serviceNameMap]>[0]
}

/**
 * 保存客户队列信息到localStorage中，取的时候通过getNextCustomerId和getPrevCustomerId来获取
 * 保存的时间上限
 *
 * @param customerQueue 保存的客户队列信息
 * @return queueId 返回一个id字段，作为取的时候的Id索引
 */
export function storeCustomerQueue (customerQueue: CustomerQueue) {
  const time = Date.now()
  const temp = lStorage.get<Record<string, CustomerQueue>>(_id) || {}

  // 清除过期的
  for (const key of Object.keys(temp)) {
    if (time > handleNumber(key) + _maxTime) {
      delete temp[key]
    }
  }

  temp[time] = customerQueue
  lStorage.set(_id, temp)
  return time // 返回时间戳，作为id索引
}

async function getCustomerId (queueId: number, currCustomerId: number, type: 'next' | 'prev') {
  if (isEmpty(queueId) || isEmpty(currCustomerId)) return undefined

  const store = lStorage.get<Record<string, CustomerQueue>>(_id)
  if (!isObject(store) || isEmpty(store[queueId])) return undefined

  const { customerIds = [], serviceName, filter } = store[queueId]
  const currIndex = customerIds?.findIndex?.(id => id === currCustomerId) ?? -1
  if (currIndex === -1) return undefined

  const targetIndex = currIndex + (type === 'next' ? 1 : -1)
  let targetCustomerId = customerIds[targetIndex]
  if (isNullOrUndefined(targetCustomerId) && type === 'prev') return undefined

  // 如果想要的不存在，获取下一个的情况下，还可以通过分页接口再查一次
  if (isNullOrUndefined(targetCustomerId) && type === 'next') {
    if (isEmpty(serviceName)) return undefined

    const service = serviceNameMap[serviceName]
    const newFilter = { ...filter, page: filter?.page + 1 }
    const [res, err] = await service(newFilter as Pagination) // 这里类型没有对应上会产生ts错误，因此把类型as成都有的pagiantion
    if (err) {
      message.error(`获取客户信息失败(${err.message})`)
      return undefined
    }
    const [idList] = getResList((res as CommonList<{ customerId: number }>), [])
    if (notEmptyArray(idList)) {
      store[queueId] = { customerIds: customerIds.concat(idList.map(item => item.customerId)), serviceName, filter: newFilter }
      lStorage.set(_id, store)
      targetCustomerId = idList[0].customerId
    }
  }
  return targetCustomerId
}

/**
 * 根据当前id， 获取客户队列中的下一项id。
 * 注意：调用此方法之前先要调用 storeCustomerQueue 生成队列id
 *
 * @param queueId 队列id
 * @param currCustomerId 客户id
 */
export function getNextCustomerId (queueId: number, currCustomerId: number) {
  return getCustomerId(queueId, currCustomerId, 'next')
}

export function getPrevCustomerId (queueId: number, currCustomerId: number) {
  return getCustomerId(queueId, currCustomerId, 'prev')
}
