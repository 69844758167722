import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Cascader, message, Spin } from 'antd'
import { useService, useShowError } from '@hooks'
import { getResList, isEmpty, isFunction, notEmptyArray, ServiceType } from '@library'
import { CascaderProps, DefaultOptionType } from 'antd/lib/cascader'

interface Option extends DefaultOptionType{
  /* 是否加载中，loadData的时候需要设置节点状态 */
  loading?: boolean
  // 标记是否为叶子节点，设置了 `loadData` 时有效
  // 设为 `false` 时会强制标记为父节点，即使当前节点没有 children，也会显示展开图标
  isLeaf?: boolean;
  /* 允许自行挂载 */
  other?: any
}

export interface CommonCascadorProps<F extends object, T extends object> extends Omit<CascaderProps<Option>, 'loadData' | 'options' | 'multiple' | 'onSearch'> {
  // 组件名字，请求数据报错时会用到。
  name?: string
  // 查询参数,
  filter?: Partial<F>
  // 数据请求接口
  service: ServiceType<F, T>
  // 异步加载树节点的时候,通过此prop组成新的上送参数。为空时不进行异步加载。
  loadData?: ((item: Option) => F)
  // 将数据翻译成TreeDataSimple
  translate: (item: T, index?: number, list?: T[]) => Option
  // 后端搜索。默认不是。有值前端搜索失效
  // 搜索参数 - 可以是一个字符串或函数。存在时会自动设置showSearch为true
  // 字符串时，会把用户输入的string和此key结合成object，merge到filter中。函数时， 会把函数返回的对象 merge到filter中。
  // 业务场景可能会有用户输入的对应多个key。如员工组件中英文都可以搜素，需要自行判断searchKey是saleName还是saleNameCh。
  searchKey?: keyof F | ((searchValue: string) => F)
  // 搜索的时候的placeholder
  placeholderOnSearching?: string
}

/**
 * 公用级联选择器
 * 使用场景： 仅单选，多选请使用CommonTreeSelector。且需选择完整的父子节点，而不是仅父或子节点
 * 自带前端搜索，不需要搜索需要设置showSearch: false。配置后端搜索时（searchKey）前端搜索自动失效
 * 注意：loadData 与 showSearch 无法一起使用。 这是官方的问题，官方文档有说明，很坑的。所以本地搜索的时候就最好是全量的数据，否则最好后端搜索。
 */
export function CommonCascador<F extends object, T extends object> (props: CommonCascadorProps<F, T>) {
  const { name, filter, service, loadData: fetchMore, translate, onDropdownVisibleChange, placeholderOnSearching, placeholder, showSearch, searchKey, dropdownRender, ...otherProps } = props
  const [loading, res, err] = useService(service, filter)
  useShowError(`获取${name}数据失败`, err)

  const [options, setOptions] = useState<Option[]>(null)
  useEffect(() => {
    const list = getResList(res, [])[0].map(translate)
    setOptions(list)
  }, [res])

  const loadData = async (selectedOptions: Option[]) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    if (notEmptyArray(targetOption.children)) return
    targetOption.loading = true

    const [res, err] = await service(fetchMore?.(targetOption))
    targetOption.loading = false
    if (err) {
      message.error(`获取${name}子节点失败${err?.message}`)
      return
    }
    targetOption.children = getResList(res, [])[0].map(translate)
    setOptions([...options])
  }

  /* 搜索相关 当有后端搜索的时候， 前端搜索自动禁用 */
  const [open, setOpen] = useState(false)
  const placeholderText = useMemo(() => (showSearch && open) ? placeholderOnSearching : placeholder, [placeholderOnSearching, placeholder, showSearch, open])
  const [searchedOptions, setSearchOptions] = useState<Option[]>(null) // 搜索结果列表
  const [searchValue, SetSearchValue] = useState<string>(otherProps.searchValue)
  const [isSearchLoading, setIsSearchLoading] = useState(false) // 请求后端中
  const onSearch = useCallback(async (searchValue: string) => {
    if (!searchKey || isEmpty(searchValue)) return

    if (isEmpty(searchValue)) {
      setIsSearchLoading(false)
      return
    }

    setIsSearchLoading(true)
    const filter = isFunction(searchKey) ? searchKey(searchValue) : { [searchKey]: searchValue }
    const [res, err] = await service(filter as F)
    setIsSearchLoading(false)
    if (err) {
      message.error(`搜索${name}失败${err?.message}`)
      return
    }
    const list = getResList(res, [])[0].map(translate)
    setSearchOptions(list)
    setOptions(oldOptions => _.unionBy(list, oldOptions, 'value'))
  }, [searchKey, service, translate, setOptions])
  const onSearchDebounce = useMemo(() => (showSearch && searchKey) ? _.debounce(onSearch, 1000, { trailing: true }) : undefined, [searchKey, showSearch])

  const realOptions = useMemo(() => isEmpty(searchValue) ? options : searchedOptions, [searchedOptions, options, searchValue])

  return (
    <Spin spinning={loading}>
      <Cascader
        {...otherProps}
        multiple={false}
        // changeOnSelect={false}
        onChange={otherProps.onChange as any} // 这里是本身antd的类型有误
        options={realOptions}
        loadData={isFunction(fetchMore) ? loadData : undefined}
        onSearch={isEmpty(searchKey) ? undefined : (searchValue) => {
          SetSearchValue(searchValue)
          onSearchDebounce && onSearchDebounce(searchValue)
        }}
        dropdownRender={isEmpty(searchKey) ? dropdownRender : originRender => {
          const render = isFunction(dropdownRender) ? dropdownRender(originRender) : originRender
          return <Spin spinning={isSearchLoading}>{render}</Spin>
        }}
        onDropdownVisibleChange={open => {
          setOpen(open)
          if (isFunction(onDropdownVisibleChange)) onDropdownVisibleChange(open)
        }}
        showSearch={isEmpty(searchKey) ? showSearch : true}
        searchValue={isEmpty(searchValue) ? undefined : searchValue}
        placeholder={placeholderText}
      />
    </Spin>
  )
}

// 搜索过滤，仅在label是string的时候可以使用
CommonCascador.filter = (inputValue: string, path: Option[]) => {
  return path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1)
}

// 前端搜索
CommonCascador.showSearch = { filter: CommonCascador.filter, limit: 20, matchInputWidth: false }

CommonCascador.defaultProps = {
  name: '',
  style: { minWidth: '100px' },
  filter: {},
  translate: item => item,
  placeholderOnSearching: '搜索...',
  /* origin */
  allowClear: true,
  showSearch: CommonCascador.showSearch,
}
