import React, { useMemo } from 'react'
import { Card, Col, Row, Statistic, Tooltip } from 'antd'
import classNames from 'classnames'
import { useService, useShowError } from '@hooks'
import { mergeArrayWith, ServiceType, getResList } from '@library'
import { Indicator } from '@pages/task/Workspace/index'
import { CssStyle, IndicatorFilter } from '@types'
import { IndicatorTipMap, IndicatorNameMap } from './config'

export interface IndicatorCardProp extends CssStyle{
  service: ServiceType<IndicatorFilter, Indicator>
  filter: IndicatorFilter
  name?: keyof typeof IndicatorNameMap
  onClickTag: (indicator: Indicator) => void
  activeTag: string
  staticIndicator: Partial<Indicator>[]
  StatisticClassName?: string
  ColSpan?: (item: Indicator, index: number, list: Indicator[]) => number | string
}

const IndicatorCard: React.FC<IndicatorCardProp> = (props) => {
  const { service, filter, name, onClickTag, activeTag, staticIndicator, style, className, StatisticClassName, ColSpan } = props

  const [loading, indicatorRes, err] = useService(service, filter)
  useShowError(`获取${IndicatorNameMap?.[name]}指标失败`, err)
  const indicatorList = useMemo<Indicator[]>(() => mergeArrayWith(getResList(indicatorRes, [])[0], staticIndicator, 'tag'), [indicatorRes, staticIndicator])

  const ToolTipTitle = IndicatorTipMap?.[name] ? (
    <Tooltip placement="right" title={<>{IndicatorTipMap?.[name]?.map((str, index) => <div key={index}>{index + 1}. {str}</div>)}</>}>
      {IndicatorNameMap?.[name]}
    </Tooltip>
  ) : (IndicatorNameMap?.[name])

  return (
    <Card title={ToolTipTitle} loading={loading} hoverable className={className} style={{ ...style, cursor: 'auto' }}>
      <Row gutter={[16, 16]}>
        {indicatorList.map((item, index, arr) => (
          <Col span={ColSpan(item, index, arr)} key={item.tag}>
            <span onClick={() => onClickTag(item)}>
              <Statistic
                className={classNames('statistic', StatisticClassName, { active: activeTag === item.tag }, { 'zero-statistic': item.count === 0 })}
                title={item.tagName}
                value={item.count}
                valueStyle={{ color: item.count > 0 ? '#FF7250' : '#5cD492' }}
              />
            </span>
          </Col>
        ))}
      </Row>
    </Card>
  )
}

IndicatorCard.defaultProps = {
  name: 'default',
  ColSpan: (item, index, arr) => index === arr.length - 1 ? 4 : 5,
}

export default IndicatorCard
