/**
 * 标签管理
 * @author tylerzzheng
 */
import React, { FunctionComponent } from 'react'
import { Card } from 'antd'
import { PageHeader } from '@components'
import LeadAdd from '@pages/lead/add/LeadAdd'
import store from '@store'

const ConfigTagsPage: FunctionComponent = () => {
  const { authPointMap } = store.useGlobal()

  return (
    <>
      <PageHeader title="上传线索" />
      <Card className="mt20">
        {authPointMap.uploadLead?.result && (<LeadAdd />)}
      </Card>
    </>
  )
}
export default ConfigTagsPage
