/**
 * TCC 登录页面
 * @author jasonelchen
 */
import React, { FunctionComponent, useState, useMemo, useCallback, useEffect } from 'react'
import { parse } from 'qs'

import {
  AppstoreTwoTone,
  ArrowLeftOutlined,
  BankTwoTone,
  BookTwoTone,
  ExclamationCircleTwoTone,
  LoadingOutlined,
} from '@ant-design/icons'

import { Card } from 'antd'
import classNames from 'classnames'
import { get, set, remove } from 'js-cookie'
import { useHistory } from 'react-router-dom'
import store from '@store'
import { useTitle } from '@hooks'
import { CompanyInfo, ModuleInfo } from '@types'
import './style'
import { isNil } from 'lodash'

const LoginPage: FunctionComponent<{}> = () => {
  const { moduleList, companyList: allCompanyList, jumpToWorkWechat, loginWithCode } = store.useSession()
  const { push } = useHistory()
  const [moduleId, setModuleId] = useState(-1)
  const [loginError, setLoginError] = useState('')
  const { code, company: companyId } = useMemo(() => parse(window.location.search, { ignoreQueryPrefix: true }), [])

  useTitle('用户登录')

  // 当前模块的公司列表
  const currCompanyList = useMemo(() => {
    return allCompanyList.filter(c => +c.belongModule === moduleId)
  }, [allCompanyList, moduleId])

  // 获取最后一次选择的模块
  const lastChooseModule = useMemo<ModuleInfo>(() => {
    const lastModuleId = parseInt(window.localStorage.getItem('TCC_LAST_MODULE'))

    if (isNaN(lastModuleId)) {
      return null
    }

    return _.find(_.flatten(moduleList), { belongModule: lastModuleId })
  }, [moduleList])

  // 获取最后一次选择的公司
  const lastChooseCompany = useMemo<CompanyInfo>(() => {
    const lastCompanyId = window.localStorage.getItem('TCC_LAST_COMPANY')

    if (!lastCompanyId || lastCompanyId.indexOf('-') < 0) {
      return null
    }

    const [companyId, agentId] = lastCompanyId.split('-')
    return _.find(currCompanyList, { companyId: Number(companyId), workWeixinAgentId: agentId })
  }, [currCompanyList])

  // 点击选择模块
  const handleSelectModule = useCallback((id: number) => {
    setModuleId(id)
    set('tcc-module', id.toString())
    window.localStorage.setItem('TCC_LAST_MODULE', id.toString())
  }, [])

  // 点击返回选择模块
  const handleBackToModule = useCallback(() => {
    setModuleId(-1)
    remove('tcc-module')
    remove('tcc-companyid')
  }, [])

  // 点击跳转企业微信扫码
  const handleJumpToWorkWechat = useCallback((appId: string, agentId: string, companyId: number) => {
    set('tcc-appid', appId)
    set('tcc-agentid', agentId)
    set('tcc-companyid', companyId.toString())

    window.localStorage.setItem('TCC_LAST_COMPANY', `${companyId.toString()}-${agentId}`)

    const query = parse(window.location.search, { ignoreQueryPrefix: true })
    const callbackUrl = (query?.callbackUrl ?? '/') as string

    jumpToWorkWechat(appId, agentId, callbackUrl)
  }, [jumpToWorkWechat])

  // 点击重新登录
  const handleReLogin = useCallback(() => {
    const query = parse(window.location.search, { ignoreQueryPrefix: true })
    // @ts-ignore
    const callbackUrl = query?.state?.callbackUrl ?? '/'
    window.location.href = `/login?callbackUrl=${encodeURIComponent(callbackUrl)}`
  }, [])

  // ,如果url参数存在company。就直接跳到sso扫码登录 // 扫码之后会带着code回到login页面，因为url里有code，就直接loginWithCode了
  useEffect(() => {
    if (!companyId) return
    const company = allCompanyList?.find(company => company?.companyId?.toString() === companyId)
    if (!company) return
    set('tcc-companyid', companyId)
    set('tcc-module', company.belongModule?.toString())
    set('tcc-appid', company.workWeixinAppId)
    set('tcc-agentid', company.workWeixinAgentId)
    jumpToWorkWechat(company.workWeixinAppId, company.workWeixinAgentId)
  }, [allCompanyList, companyId])

  // 有 code 直接登录
  useEffect(() => {
    if (isNil(code) || isNil(get('tcc-module')) || isNil(get('tcc-agentid')) || isNil(get('tcc-appid')) || isNil(get('tcc-companyid'))) {
      return
    }

    ;(async () => {
      const moduleId = parseInt(get('tcc-module'))
      const appId = get('tcc-appid')
      const agentId = parseInt(get('tcc-agentid'))
      const companyId = parseInt(get('tcc-companyid'))

      // 有 code 就登录
      try {
        await loginWithCode(code as string, moduleId, appId, agentId, companyId)

        const query = parse(window.location.search, { ignoreQueryPrefix: true })
        const callbackUrl = _.get(query, 'state.callbackUrl', '/')
        push(callbackUrl)
      } catch (e) {
        setLoginError(e.message)
      }
    })()
  }, [])

  return (
    <div className="login-page-inner">
      {code && (
        <>
          {
            loginError
              ? (
                <>
                  <h2 className="text-danger" style={{ textAlign: 'center' }}>
                    <ExclamationCircleTwoTone twoToneColor="#f5222d" />
                    <span className="ml10">登录失败</span>
                  </h2>
                  <p style={{ textAlign: 'center' }} className="mt15">
                    <span>{loginError}</span>
                    <a href={null} className="ml10" onClick={handleReLogin}>重新登录</a>
                  </p>
                </>
              )
              : (
                <h2 style={{ textAlign: 'center' }}>
                  <LoadingOutlined />
                  <span className="ml10">登录中，请稍候...</span>
                </h2>
              )
          }
        </>
      )}

      {!code && (
        <Card className="chooser-card">
          <h1 className="mb20 login-title">用户登录</h1>

          <div className={classNames('module-chooser', { hide: moduleId > 0 })}>
            <h3>选择模块</h3>
            {lastChooseModule && (
              <Card bordered={false} className="block-card mt15" size="small" title="上次选择的模块">
                <Card className="block mr15" onClick={() => handleSelectModule(lastChooseModule.belongModule)}>
                  <h4 className="block-name">
                    <BookTwoTone className="mr5" />
                    <span>{lastChooseModule.belongModuleName}</span>
                  </h4>
                </Card>
              </Card>
            )}
            <Card bordered={false} className="block-card mt15" size="small" title="全部模块">
              {moduleList?.map(m => (
                <Card className="block mr15 mb15" key={m.belongModule} onClick={() => handleSelectModule(m.belongModule)}>
                  <h4 className="block-name">
                    <AppstoreTwoTone className="mr5" />
                    <span>{m.belongModuleName}</span>
                  </h4>
                </Card>
              ))}
            </Card>
          </div>
          <div className={classNames('company-chooser', { visible: moduleId > 0 })}>
            <div className="navigate-back">
              <a href={null} onClick={handleBackToModule}>
                <ArrowLeftOutlined />
                <span className="ml5">返回模块选择</span>
              </a>
            </div>
            <h3 className="mt5">选择所属公司</h3>
            {lastChooseCompany && (
              <Card bordered={false} className="block-card mt15" size="small" title="上次选择的公司">
                <Card className="block mr15" onClick={() => handleJumpToWorkWechat(lastChooseCompany.workWeixinAppId, lastChooseCompany.workWeixinAgentId, lastChooseCompany.companyId)}>
                  <h4 className="block-name">
                    <BookTwoTone className="mr5" />
                    <span>{lastChooseCompany.companyName}</span>
                  </h4>
                </Card>
              </Card>
            )}
            <Card bordered={false} className="block-card mt15" size="small" title="全部公司">
              {currCompanyList.map(c => (
                <Card className="block mr15 mb15" key={c.companyId} onClick={() => handleJumpToWorkWechat(c.workWeixinAppId, c.workWeixinAgentId, c.companyId)}>
                  <h4 className="block-name">
                    <BankTwoTone className="mr5" />
                    <span>{c.companyName}</span>
                  </h4>
                </Card>
              ))}
            </Card>
          </div>
        </Card>
      )}
    </div>
  )
}

export default LoginPage
