/**
 * useObject 实现
 * @author jasonelchen
 */
import { Draft } from 'immer'
import { useImmer } from 'use-immer'

export function useObject<T extends object> (initialValue: T | (() => T)) {
  const [copy, rawSet] = useImmer(initialValue)

  function set<K extends keyof Draft<T>> (key: K, value: Draft<T>[K]): void
  function set<K extends keyof Draft<T>> (data: Partial<T>): void
  function set<K extends keyof Draft<T>> (f: (draft: Draft<T>) => void | T): void
  function set<K extends keyof Draft<T>> (data: any, value?: Draft<T>[K]): void {
    if (typeof data === 'string') {
      rawSet(draft => {
        const key = data as K
        const v = value as Draft<T>[K]
        draft[key] = v
      })
    } else if (typeof data === 'function') {
      rawSet(data)
    } else if (typeof data === 'object') {
      rawSet((draft: Draft<T>) => {
        const obj = data as Draft<T>
        for (const key of Object.keys(obj)) {
          const k = key as keyof Draft<T>
          draft[k] = obj[k]
        }
      })
    }
  }

  function reset (data?: Partial<T>) :void {
    set(draft => {
      for (const key of Object.keys(draft)) {
        if (data) {
          draft[key] = Object.prototype.hasOwnProperty.call(data, key)
            ? data[key]
            : undefined
        } else {
          draft[key] = undefined
        }
      }
    })
  }

  return [copy, set, reset] as const
}
