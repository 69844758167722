/**
 * 页面标题
 * @author jasonelchen
 */
import { useEffect } from 'react'

export const useTitle = (title: string, withSuffix = true) => {
  return useEffect(() => {
    if (typeof title !== 'string') {
      document.title = 'TCC 腾讯云呼叫中心'
    } else {
      const oldTitle = document.title
      document.title = withSuffix ? `${title} - TCC 腾讯云呼叫中心` : title
      return () => { (document.title = oldTitle) }
    }
  }, [title, withSuffix])
}
