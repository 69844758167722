/**
 * 机器人名单页面
 */
import React, { FunctionComponent, useRef, useMemo } from 'react'
import { Card, Table, Tooltip, message } from 'antd'
import { getTableXwidth } from '@configs/table'
import { ColumnProps } from 'antd/lib/table'
import { CommonFilters, PageHeader, UserAvatar, CallRobot } from '@components'
import { configRobotTaskFilterItems } from '../../../components/CommonFilters/configs'
import { listRobotCallRoll } from '@services/robot'
import { useTableService } from '@hooks/useTableService'
import {
  RobotFilterTypeEnum,
  ListRobotCallRollItem,
  CallRobotSourceEnum,
} from '@types'
import { sourceTypeMap, robotTaskStatusMap } from '@configs/map'
import { cosGetObjectUrl } from '../../../library/cosUtils'
import downloadByATag from '../../../library/downloadByATag'

const ConfigRobotTaskLogPage: FunctionComponent = () => {
  const filterRef = useRef()
  const initFilter = {
    page: 1,
    pageSize: 10,
  }

  const {
    loading,
    list,
    pagination,
    setFilter,
    resetFilter,
    rowKey,
    reload,
  } = useTableService(listRobotCallRoll, initFilter, '获取机器人名单失败')

  const downloadCosFile = async (fileKey) => {
    const [url, err] = await cosGetObjectUrl(fileKey) // 这里的key要和cosPutObject时的key匹配
    if (err) {
      message.error(`下载失败: ${err.message}`)
    } else {
      downloadByATag(fileKey, url)
    }
  }

  const columns: ColumnProps<ListRobotCallRollItem>[] = useMemo(() => [
    { title: '名单id', dataIndex: 'id', width: 80 },
    { title: '名单标题', dataIndex: 'title', width: 150 },
    {
      title: '名单状态',
      dataIndex: 'status',
      render: status => robotTaskStatusMap[status],
      width: 80,
    },
    {
      title: '名单来源',
      dataIndex: 'sourceType',
      render: sourceType => sourceTypeMap[sourceType],
      width: 80,
    },
    { title: '名单描述', dataIndex: 'description', width: 150, ellipsis: true },
    { title: '名单详情', dataIndex: 'remark', width: 150, ellipsis: true },
    {
      title: '创建人',
      dataIndex: 'creator',
      width: 150,
      render: value => <UserAvatar salesName={value} />,
    },
    { title: '创建时间', dataIndex: 'createTime', width: 150 },
    {
      title: '其他信息',
      dataIndex: 'uploadDesc',
      width: 80,
      render: (value, record) => (
        <Tooltip
          color="#fafafa"
          placement="topLeft"
          title={
            <div style={{ color: '#000', padding: 10 }}>
              {value}<br />
              {record?.filterCondition && <a onClick={() => downloadCosFile(record?.filterCondition)}>查看原上传文件</a>}<br />
              {record?.failCidFilePath && <a onClick={() => downloadCosFile(record?.failCidFilePath)}>查看失败原因</a>}
            </div>
          }
        >
          {record?.sourceType === CallRobotSourceEnum.CALL_RECORD && <a>上传详情</a>}
        </Tooltip>
      ),
    },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  return (
    <>
      <PageHeader title="机器人名单" />
      <Card className="mt20">
        <CommonFilters
          cRef={filterRef}
          className="custom-Label"
          colProps={{ flex: '1 1 300px' }}
          itemList={configRobotTaskFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <CallRobot
          reload={() => reload()}
          sourceType={CallRobotSourceEnum.CALL_RECORD}
          filterType={RobotFilterTypeEnum.BATCH_UPLOAD}
        />
        <Table
          loading={loading}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          onChange={() => console.log('change')}
          dataSource={list}
          columns={columns}
          pagination={pagination}
          tableLayout="fixed"
          className="mt20"
        />
      </Card>
    </>
  )
}

export default ConfigRobotTaskLogPage
