import { requestApiV2 } from '@library'
import {
  OperationLogFilter,
  OperationLog,
} from '@types'

/*
获取操作日志列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002026983
 */
export function getOperationLog (filter: OperationLogFilter) {
  return requestApiV2<OperationLog[]>('/trpc.tcc.tcc_main.Oplog/GetOperationLog', filter)
}
