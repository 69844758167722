/**
 * 上传线索
 * @author tylerzzheng
 */
import React, { FunctionComponent, useCallback, useMemo, useRef, useState } from 'react'
import { CommonAutoComplete, CommonCascador, CommonSelector, ModalForm, ModalFormProp } from '@components'
import {
  Button,
  Card,
  Col,
  Input,
  message,
  Modal,
  Row,
  Select, Spin,
} from 'antd'
import { addLead, getLeadCustomerFollower } from '@services/lead'
import { pattern } from '@configs/pattern'
import store from '@store'
import { IsFollowedEnum, NewLead, UinInfo } from '@types'
import { getCustomerRegisterInfoByUin } from '@services/customer'
import { UinListModal } from '@pages/lead/add/UinListModal'
import { salesName, leadTags, validPrimaryIndustryIncome } from '../../../components/CommonSelector/configs'
import { getStaffName, isEmpty, parseParams } from '@library'
import { area, industryProductsOnlyValid, leadIntentionLevel } from '../../../components/CommonCascador/configs'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { searchCustomer } from '../../../components/CommonAutoComplete/configs'
import './style.less'

const { Option } = Select

type NewLeadFV = Omit<NewLead, 'intentionLevel' | 'intentionSecondLevel' | 'smallProduct' | 'subProduct' | 'cityCode' | 'provinceCode'> & {
  leadIntention: [string, string],
  product: [string, string],
  address: [string, string],
}

const LeadAdd: FunctionComponent = () => {
  const { userInfo } = store.useSession()
  const formRef = useRef()

  // bulr的时候，用uin去查客户名称.如果uin没有拉回客户名称，则认为uin非法，非法则禁止提交
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false)
  const [isLoadingCustName, setIsLoadingCustName] = useState<boolean>(false) // 是否在获取客户姓名中...
  const getCustomerNameByUin = useCallback(async (uin: string) => {
    // console.log('getCustomerNameByUin')
    if (isEmpty(uin)) {
      setDisableSubmit(false)
      return
    }
    setIsLoadingCustName(true)
    const [res, err] = await getCustomerRegisterInfoByUin({ uin })
    setIsLoadingCustName(false)
    const { accountInfo, registerInfo } = res || {}
    if (err) {
      message.error('查询UIN报错' + err.message)
      setDisableSubmit(true)
      // @ts-ignore
      formRef.current && formRef.current.setError('uin', '查询UIN报错，请重新输入')
      return
    }
    //  优先取accountInfo，为空再取registerInfo
    const name = _.isEmpty(accountInfo) ? registerInfo?.userName : accountInfo?.name
    if (_.isEmpty(name)) {
    // 如果uin查不到信息，那么accountInfo和registerInfo的值是个空数组哈.空数组没有name，则报错并禁止提交
      message.error('UIN有误,查询信息为空')
      // @ts-ignore
      formRef.current && formRef.current.setError('uin', 'UIN有误，请重新输入')
      setDisableSubmit(true)
      return
    }

    // @ts-ignore
    formRef.current && formRef.current.setFormValues('name', name)
    setDisableSubmit(false)
    // @ts-ignore
    formRef.current && formRef.current.removeError('name') // 自动赋值之后别忘了清除掉表单报错信息
    // @ts-ignore
    formRef.current && formRef.current.removeError('uin')
  }, [formRef])

  // 根据电话查询uin
  const [showUinList, setShowUinList] = useState<boolean>(false)
  const [phone, setPhone] = useState<number | string>()
  const onClickUinList = useCallback((value: UinInfo) => {
    // @ts-ignore
    formRef.current && formRef.current.setFormValues({
      uin: value?.uin,
      name: value?.name,
    })
    // @ts-ignore
    formRef.current && formRef.current.removeError('name') // 自动赋值之后别忘了清除掉表单报错信息
    // @ts-ignore
    formRef.current && formRef.current.removeError('uin')
  }, [formRef])

  // 提交
  const handleSubmit = useCallback(async (values) => {
    const [, err] = await addLead(parseParams(values))
    if (err) {
      message.error('新增线索出错' + err.message)
    } else {
      message.success('新增线索成功')
      // @ts-ignore
      formRef.current && formRef.current.resetFormValues()
    }
  }, [formRef])

  // 检查是否已存在跟进人
  const checkLeadFollower = useCallback(async (values: NewLead) => {
    const params = {
      name: values.name || undefined,
      uin: values.uin || undefined,
      phone: values.phone || undefined,
    }
    const [res, err] = await getLeadCustomerFollower(params)
    if (err) {
      message.error('获取添加线索客户跟进人报错' + err.message)
      return
    }

    // 如果已有跟进人则需弹框提醒
    const { isFollowed, follower } = res
    if (isFollowed === IsFollowedEnum.YES) {
      Modal.confirm({
        title: '您录入的线索已存在跟进人',
        content: `该线索将分配给原跟进人：${getStaffName(follower?.salesName, follower?.salesNameCh)}。`,
        icon: <ExclamationCircleOutlined style={{ color: '#1890ff' }} />,
        width: 600,
        onOk: () => handleSubmit(values),
        onCancel: () => {},
      })
    } else {
      await handleSubmit(values)
    }
  }, [handleSubmit])

  const initValues = useMemo(() => ({ contributor: userInfo.salesName, assignFollower: userInfo.salesName }), [userInfo])
  const LeadAddForm = useMemo(() => ModalForm<NewLead, NewLeadFV>(), [])
  const leadAddFormItems: ModalFormProp<NewLead, NewLeadFV>['fieldItems'] = useMemo(() => [
    {
      label: 'UIN',
      field: 'uin',
      renderChildren: value => <Input onChange={e => e} onBlur={() => getCustomerNameByUin(value as string)} placeholder="填写后将自动拉取客户名称" />,
    },
    {
      label: '客户名称',
      field: 'name',
      required: true,
      children: ({ value, onChange }) => (
        <Spin spinning={isLoadingCustName}>
          <CommonAutoComplete
            {...searchCustomer}
            disabled={isLoadingCustName}
            value={value as string}
            onChange={onChange}
          />
        </Spin>),
    },
    {
      label: '联系人姓名',
      field: 'contactName',
      children: <Input />,
    },
    {
      label: '电话号码',
      field: 'phone',
      rule: (value, formValues) => [{ required: (String(formValues?.name ?? '').length <= 4), message: '客户名称小于等于4字时，电话必填!' },
        { pattern: pattern.PHONE_AND_MOBILE, message: '电话号码格式不正确' }],
      children: ({ value, onChange }) => (
        <Input.Search
          value={value}
          onChange={onChange}
          enterButton="查询uin"
          placeholder="如果不确定客户电话，请勿填写！"
          onSearch={async () => {
            // @ts-ignore
            const validateRes = await formRef.current.validate('phone')
            if (validateRes.isPass) {
              setPhone(value as string)
              setShowUinList(true)
            } else {
              message.error('格式错误：' + _.get(validateRes, 'errors[0].message'))
            }
          }}
        />
      ),
    },
    {
      label: '意向度',
      field: 'leadIntention',
      children: <CommonCascador {...leadIntentionLevel} changeOnSelect />,
      fromFieldValue: value => {
        const [intentionLevel, intentionSecondLevel] = value || [undefined, undefined]
        return { intentionLevel, intentionSecondLevel }
      },
    },
    {
      label: '咨询产品',
      field: 'product',
      children: (
        <CommonCascador {...industryProductsOnlyValid} changeOnSelect />
      ),
      fromFieldValue: value => {
        const [smallProduct, subProduct] = value || [undefined, undefined]
        return { smallProduct, subProduct }
      },
    },
    {
      label: '线索标签',
      field: 'leadTags',
      children: <CommonSelector mode="multiple" {...leadTags} />,
    },
    {
      label: '行业',
      field: 'primaryIndustryIncomeName',
      children: <CommonSelector {...validPrimaryIndustryIncome} valueKey="primaryIndustryIncomeName" />,
    },
    {
      label: '所在地',
      field: 'address',
      children: <CommonCascador {...area} />,
      fromFieldValue: value => {
        const [provinceCode, cityCode] = value || [undefined, undefined]
        return { provinceCode, cityCode }
      },
    },
    {
      label: '主营业务',
      field: 'mainBusiness',
      children: <Input />,
    },
    {
      label: '企业规模',
      field: 'companySize',
      children: (
        <Select placeholder="请选择">
          <Option value="1-10人">1-10人</Option>
          <Option value="11-30人">11-30人</Option>
          <Option value="31-50人">31-50人</Option>
          <Option value="51-100人">51-100人</Option>
          <Option value="100-200人">100-200人</Option>
          <Option value="201-500人">201-500人</Option>
          <Option value="500以上">500以上</Option>
        </Select>
      ),
    },
    {
      label: '客户需求',
      field: 'customerDemand',
      children: <Input />,
    },
    {
      label: '入选原因',
      field: 'selectionReason',
      children: <Input />,
    },
    {
      label: '邮箱',
      field: 'email',
      rule: [{ type: 'email' as const, message: '请输入正确的邮箱格式!' }],
      children: <Input />,
    },
    {
      label: '微信',
      field: 'wechat',
      children: <Input />,
    },
    {
      label: '职位',
      field: 'job',
      children: <Input />,
    },
    {
      label: '线索贡献人',
      field: 'contributor',
      children: <CommonSelector {...salesName} disabled allowClear={false} />,
    },
    {
      label: '指定分配人',
      field: 'assignFollower',
      rule: [{ required: true, message: '请输入指定分配人!' }],
      children: <CommonSelector {...salesName} allowClear={false} />,
    },
    {
      label: '自定义标识ID',
      field: 'customTrackId',
      children: <Input />,
    },
  ], [getCustomerNameByUin, setShowUinList, isLoadingCustName])
  const [loading, setLoading] = useState<boolean>(false)
  const handleOK = useCallback(async (values) => {
    // console.log('handleSubmit', values)
    setLoading(true)
    await checkLeadFollower(values)
    setLoading(false)
  }, [checkLeadFollower])

  return (
    <>
      <Card title="上传单个线索" className="mt20 lead-add-card" style={{ width: '750px' }}>
        <LeadAddForm
          isModal={false}
          onSubmit={handleOK}
          formProps={{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }}
          cRef={formRef}
          initFormValues={initValues}
          fieldItems={leadAddFormItems}
        />
        <Row className="mt15">
          <Col offset={4}>
            <Button
              disabled={disableSubmit} loading={loading || isLoadingCustName} type="primary" onClick={() => {
              // @ts-ignore
                formRef.current && formRef.current.onOK()
              }}
            >提交
            </Button>
            <Button
              className="ml10" loading={isLoadingCustName} onClick={() => {
                // @ts-ignore
                formRef.current && formRef.current.resetFormValues()
              }}
            >重置
            </Button>
          </Col>
        </Row>
      </Card>
      {showUinList && (
        <UinListModal
          open
          phone={phone}
          onCancel={() => setShowUinList(false)}
          onOk={onClickUinList}
        />
      )}
    </>
  )
}
export default LeadAdd
