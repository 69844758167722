import {
  indicatorCustomerFilterItems,
  indicatorLeadFilterItems,
  indicatorOpportunityFilterItems,
  indicatorTaskFilterItems,
} from '../../../components/CommonFilters/configs'
import { Indicator } from '@pages/task/Workspace/index'

// 销售机会 机会维度
export const staticOpportunityIndicator: Partial<Indicator>[] = [
  { tag: 'opportunity.estimatedOrderTimeToday', tagName: '预计今日成单', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.estimatedOrderTimeWeekDay', tagName: '预计7日内成单', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.todayWaitCallback', tagName: '今日待复呼', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.historyWaitCallback', tagName: '历史未复呼', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.winRate90', tagName: '成单率90%', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.winRate75', tagName: '成单率75%', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.winRate50', tagName: '成单率50%', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.winRate25', tagName: '成单率25%', filterKey: 'indicatorOpportunityFilterItems' },
  { tag: 'opportunity.winRateProcessing', tagName: '全部进行中', filterKey: 'indicatorOpportunityFilterItems' },
]

// 待处理任务  任务维度
export const staticTaskIndicator: Partial<Indicator>[] = [
  { tag: 'task.notHandleTask', tagName: ' 近6月待处理任务', filterKey: 'indicatorTaskFilterItems' },
]

// 待处理任务 线索维度
export const staticAssignedLeadIndicator: Partial<Indicator>[] = [
  { tag: 'lead.hasAssignedLead', tagName: ' 近6月待处理线索', filterKey: 'indicatorLeadFilterItems' },
]

// 新客户管理 线索维度
export const staticLeadIndicator: Partial<Indicator>[] = [
  { tag: 'lead.specifiedBatchIdToday', tagName: '本日新增商机', filterKey: 'indicatorLeadFilterItems' },
  { tag: 'lead.specifiedBatchIdNotFollowToday', tagName: '今日新增未触达', filterKey: 'indicatorLeadFilterItems' },
  { tag: 'lead.specifiedBatchIdNotFollowHistory', tagName: ' 近6月未触达', filterKey: 'indicatorLeadFilterItems' },
]

// 复呼任务 客户维度
export const staticRecallIndicator: Partial<Indicator>[] = [
  { tag: 'customer.predictFollowToday', tagName: '今日待复呼', filterKey: 'indicatorCustomerFilterItems' },
  { tag: 'customer.predictFollowHistory', tagName: ' 近6月待复呼', filterKey: 'indicatorCustomerFilterItems' },
  { tag: 'customer.lastFollow21Day', tagName: '关联库21天未触达', filterKey: 'indicatorCustomerFilterItems' },
]

export const filterMap = {
  indicatorCustomerFilterItems,
  indicatorLeadFilterItems,
  indicatorOpportunityFilterItems,
  indicatorTaskFilterItems,
} as const

export const IndicatorNameMap = {
  notHandleTask: '待处理任务',
  enterpriseBusiness: '企业新商机',
  sellOpportunity: '销售机会',
  callbackTask: '复呼任务',
  importantLead: '重点线索',
  default: '数据',
}

export const IndicatorTipMap = {
  notHandleTask: ['近6月待处理任务，指近6月内分配任务但未处理的客户数', '近6月待处理线索，指近6月内派发线索但未处理的客户数'],
  enterpriseBusiness: ['本日新增商机，指今日派发官网企业认证商机（批次2）的客户数', '今日新增未触达，指今日派发官网企业认证商机（批次2）但未触达的客户数', '近6月未触达，指近6月内派发官网企业认证商机（批次2）但未触达的客户数'],
  sellOpportunity: ['预计今日/7日内成单，指含进行中的销售机会且预计下单日期为今日/未来7日内的客户数', '成单率90%、75%、50%、25%，指含进行中的销售机会对应成单率的客户数', '全部进行中，指含全部进行中的销售机会的客户数'],
  callbackTask: ['今日待复呼，指今日需要复呼的客户数，由填写跟进记录的「下次跟进时间」决定', '近6月待复呼，指近6月内需要复呼的客户数，由填写跟进记录的「下次跟进时间」决定', '关联库21天未触达，指关联库中超过21天未触达联系的客户数'],
  importantLead: ['（xx批次）今日新增未触达，指今日派发xx批次线索但未触达的客户数', '（xx批次）近6月未触达，指近6月内派发xx批次线索但未触达的客户数'],
}
