/**
 * 指派失败记录
 * @author tylerzzheng
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Card, Table } from 'antd'
import { CommonFilters, PageHeader } from '@components'
import { BindFailureRecordItem, BindFailureRecordPayload } from '@types'
import { bindFailureFilterItems } from '../../../components/CommonFilters/configs'
import { getBindFailureList } from '@services/customer'
import { useTableService } from '@hooks/useTableService'
import { TableColumnProps } from '@hooks'

const initFilter: BindFailureRecordPayload = { page: 1, pageSize: 10 }

const CustomerBindFailurePage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getBindFailureList, initFilter, '获取指派失败列表失败')

  const columns: TableColumnProps<BindFailureRecordItem>[] = useMemo(() => [
    { title: 'UIN', dataIndex: 'uin' },
    { title: '指派时间', dataIndex: 'bindTime' },
    { title: '失败原因', dataIndex: 'message' },
  ], [])

  return (
    <>
      <PageHeader title="指派失败记录" />
      <Card className="mt20">
        <CommonFilters
          itemList={bindFailureFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <Table
          loading={loading}
          columns={columns}
          rowKey={rowKey}
          dataSource={list}
          pagination={pagination}
        />
      </Card>
    </>
  )
}
export default CustomerBindFailurePage
