import { AreaItem, areaTree } from '@configs/area'

/**
 * 翻译地域，可单独翻译城市或省份，也可以结合起来翻译。结合起来翻译会在中间默认用「-」拼接
 * @param cityCode 城市code
 * @param provinceCode 省份code
 * @param separator 连接符，默认为-
 */
export const getArea = (cityCode?: string, provinceCode?: string, separator: string = '-'): string => {
  let province: AreaItem
  let city: Omit<AreaItem, 'children'>
  let area: string

  if (provinceCode) {
    province = areaTree.find(province => provinceCode === province.value)
    area = province?.label
  }

  if (cityCode) {
    if (province) {
      city = province.children?.find?.(city => city.value === cityCode)
    } else {
      city = areaTree.find(province => cityCode.startsWith(province.value))?.children?.find?.(city => city.value === cityCode)
    }
    area = area ? area + separator + (city?.label ?? '') : city?.label
  }
  return area
}
