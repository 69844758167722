import React, { useEffect } from 'react'
import { Select } from 'antd'
import { getUinRegisterChannelEnum } from '@services/customer'

interface Props {
  setValues?: any
  fieldName?: string
  triggerReset?: boolean
}

const RegisterChannelNameSelect = function <T> (props: Props) {
  const { setValues, fieldName, triggerReset } = props
  const [options, setOptions] = React.useState<any>()
  const [value, setValue] = React.useState<any>()

  const fetchData = async () => {
    const [res, err] = await getUinRegisterChannelEnum()
    if (err) return {}
    setOptions(res?.[fieldName]?.map(o => ({ label: o, value: o })))
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    setValues({ [fieldName]: null })
    setValue(null)
  }, [triggerReset])

  return (
    <Select
      options={options} value={value} onChange={(v) => {
        setValues({ [fieldName]: v })
        setValue(v)
      }} allowClear
    />
  )
}

export default RegisterChannelNameSelect
