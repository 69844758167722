/**
 * 短信记录
 * @author fredhaochen
 */
import React, { useMemo } from 'react'
import { CommonFilters, PageHeader, UserAvatar } from '@components'
import { MailOutlined } from '@ant-design/icons'
import { Card, Popover, Table, Timeline, Tooltip } from 'antd'
import { getSMSRecordList } from '@services/customer'
import { needTotalEnum, ReplyContentType, SMSRecordListFilter, SMSRecordListItem, sortTypeEnum } from '@types'
import { TableColumnProps, useTableColumn } from '@hooks'
import { TableProps } from 'antd/lib/table'
import { Link } from 'react-router-dom'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { antdSortMap } from '@configs/map'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { smsRecordFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { trimStr } from '../../../library/utils'
import { isArray } from '@library'

const initFilter: SMSRecordListFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  sort: 'sendTime',
  sortType: sortTypeEnum.DESC,
}

export default function SMSRecordPage () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getSMSRecordList, initFilter, '获取短信记录失败')

  const onTableChange: TableProps<SMSRecordListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<SMSRecordListFilter>(sorter)
    setFilter(sort)
  }

  const renderReplyContent = (contents: ReplyContentType[] = []) => {
    if (!isArray(contents) || contents?.length === 0) return '-'
    const content = (
      <Timeline style={{ maxWidth: '400px' }}>
        {contents.map((content, index) => (
          <Timeline.Item dot={<MailOutlined />} key={index}>
            <p>{content.replyTime}</p>
            <p>{content.replyContent}</p>
          </Timeline.Item>))}
      </Timeline>
    )
    return <Popover content={content} title="回复内容详情"><a style={{ cursor: 'default' }}>详情</a></Popover>
  }

  const columns: TableColumnProps<SMSRecordListItem>[] = useMemo(() => [
    {
      title: '发送对象',
      dataIndex: 'contactName',
      width: 180,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    {
      title: '发送时间',
      dataIndex: 'sendTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'sendTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '模板ID', dataIndex: 'themeId', width: 180 },
    { title: '线索ID', dataIndex: 'leadId', width: 180 },
    { title: 'UIN', dataIndex: 'uin', width: 180 },
    {
      title: '发送内容',
      width: 300,
      dataIndex: 'content',
      ellipsis: true,
      render: content => <Tooltip placement="topLeft" title={content}>{content}</Tooltip>,
    },
    {
      title: '回复内容',
      width: 300,
      dataIndex: 'replyContent',
      ellipsis: true,
      render: renderReplyContent,
    },
    { title: '操作人', dataIndex: 'operator', width: 220, render: value => <UserAvatar salesName={value} /> },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.SMS_RECORD, columns, ['contactName'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="短信记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={smsRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.SMS_RECORD} onSuccess={() => forceRequest()} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columns}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
