import React, { useEffect } from 'react'
import store from '@store'
import { configureScope } from '@library'
import { configAegis } from '../../library/rum'

/**
 * 配置上报信息等， 写成组件的原因是userInfo必须在Provider里面才能获取到
 * @constructor
 */
export const ConfigInfo: React.FC<{}> = () => {
  const { userInfo } = store.useSession()

  // 配置性能监控信息
  useEffect(() => configAegis(userInfo), [userInfo])

  // 配置sentry信息
  useEffect(() => configureScope(userInfo), [userInfo])

  return null
}
