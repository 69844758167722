import { useObject } from '@hooks'
import { AuthPointItem, IStaff } from '@types'
import { AuthPointType } from '@configs/auth-point'

interface SelectDataType {
  [key: string]: any[]
}

export type AuthPointMapType = Record<AuthPointType, AuthPointItem>

/**
 * 响应式缓存,不常变的转义、翻译
 */
export function useGlobal () {
  const [selectorData, setSelectorData] = useObject<SelectDataType>({})

  const [authPointMap, setAuthPointMap] = useObject<AuthPointMapType>({} as AuthPointMapType)

  const [staffData, setStaffData] = useObject<{[key: string]: IStaff}>({})

  return {
    // 公共选择器的数据
    selectorData,
    setSelectorData,
    // 权限数据
    authPointMap,
    setAuthPointMap,
    // 用户(座席)列表数据
    staffData,
    setStaffData,
  }
}
