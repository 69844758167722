/**
 * 支持留资相关
 * @author marklei
 */

import {
  RetainListFilter,
  RetainListItem,
  CommonList,
  AddLeaveInfo,
  AddLeaveInfoRes,
  ResourceTmp, ResourceTmpFilter,
} from '@types'

import { requestApiV2 } from '@library'

/* 获取额外表单模板 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001826735 */
export function getResourceTmpList (param: ResourceTmpFilter) {
  return requestApiV2<CommonList<ResourceTmp>>('/trpc.tcc.tcc_main.ExtraForm/GetExtraForm', param)
}

/* 创建模板 */
export function createResourceTemp (param: {
  name: string
  status: number
}) {
  return requestApiV2('/trpc.tcc.tcc_main.ExtraForm/AddExtraForm', param)
}

/* 更新模板 */
export function updateResourceTemp (param) {
  return requestApiV2('/trpc.tcc.tcc_main.ExtraForm/UpdateExtraForm', param)
}

/* 删除模板 */
export async function deleteResourceTemp (param: {
  id: number
}) {
  return requestApiV2('/trpc.tcc.tcc_main.ExtraForm/DeleteExtraForm', param)
}

/* 留资记录列表 */
export function getRetainRecordList (param?: RetainListFilter) {
  return requestApiV2<CommonList<RetainListItem>>('/trpc.tcc.tcc_main.LeaveInfo/GetLeaveInfoRecordList', param)
}

/* 导出留资记录列表 */
export async function exportRetainRecordList (param?: RetainListFilter) {
  return requestApiV2<CommonList<RetainListItem>>('/trpc.tcc.tcc_main.LeaveInfo/ExportLeaveInfoRecordList', param)
}

/* 留资记录填写 */
export async function addLeaveInfoRecord (param?: AddLeaveInfo) {
  return requestApiV2<AddLeaveInfoRes>('/trpc.tcc.tcc_main.LeaveInfo/AddLeaveInfoRecord', param)
}
