/**
 * 暂时解决antd升级中的报错
 * @author tylerzzheng
 */
import React from 'react'
import { Select } from 'antd'
import store from '@store'

const CompanySelector: React.FC<any> = (props) => {
  return (
    <Select allowClear {...props}>
      {store.useSession().currCompanyList.map(company => (
        <Select.Option key={company.companyId} value={company.companyId}>{company.companyName}</Select.Option>
      ))}
    </Select>
  )
}
export default CompanySelector
