import React, { useMemo } from 'react'
import { CommonFilters, PageHeader, TableDownload, UserAvatar } from '@components'
import { Card, message, Table } from 'antd'
import { appealConflictCustomer, exportConflictRecordList, getConflictRecordList } from '@services/customer'
import { AppealStatusEnum, ConflictRecordFilter, ConflictRecordItem } from '@types'
import { TableColumnProps } from '@hooks'
import { getTableXwidth } from '@configs/table'
import { isNumber, trimStr } from '@library'
import { useTableService } from '@hooks/useTableService'
import { appealStatusMap, customerTypeMap } from '@configs/map'
import store from '@store'
import { conflictRecordFilterItems } from '../../../components/CommonFilters/configs'

const initFilter: ConflictRecordFilter = {
  page: 1,
  pageSize: 10,
}

export default function ConflictRecordPage () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
    reload,
  } = useTableService(getConflictRecordList, initFilter, '获取撞单分配记录失败')

  const onClickAppeal = async (applyId: number) => {
    if (!isNumber(applyId)) return
    const [, err] = await appealConflictCustomer(applyId)
    if (err) {
      message.error(`申诉失败${err.message}`)
    } else {
      message.success('操作成功')
    }
    reload()
  }

  const { userInfo } = store.useSession()
  const columns: TableColumnProps<ConflictRecordItem>[] = useMemo(() => [
    { title: '撞单时间', dataIndex: 'createTime', width: 180 },
    { title: '撞单申请人', dataIndex: 'applicant', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '撞单客户',
      dataIndex: 'customerName',
      width: 280,
      render: (name) => trimStr(name, '-'),
    },
    { title: '撞单CID', dataIndex: 'cid', width: 180 },
    { title: '撞单客户类型', dataIndex: 'customerType', width: 120, render: customerType => customerTypeMap[customerType] || '' },
    { title: '撞单跟进时间', dataIndex: 'followTime', width: 180 },
    {
      title: '被撞方客户',
      dataIndex: 'conflictCustomerName',
      width: 280,
      render: (name) => trimStr(name, '-'),
    },
    { title: '被撞方客户CID', dataIndex: 'conflictCid', width: 180 },
    { title: '被撞方UIN', dataIndex: 'conflictUin', width: 120 },
    { title: '被撞方原跟进人', dataIndex: 'conflictCustomerFollower', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '申诉状态', dataIndex: 'applyStatus', width: 120, render: value => appealStatusMap[value] },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 120,
      render: (formItem, item) => {
        return (item.applyStatus === AppealStatusEnum.NOAPPEAL && userInfo.salesName === item.conflictCustomerFollower) ? (<a onClick={() => onClickAppeal(item.applyId)}>申诉</a>) : undefined
      },
    },
  ], [userInfo])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])
  return (
    <>
      <PageHeader title="撞单分配记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={conflictRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <TableDownload className="ml20" exportMethod={exportConflictRecordList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columns}
        />
      </Card>
    </>
  )
}
