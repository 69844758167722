import { Pagination } from '@types'

export interface GetAccessRolesParam {
  roles: RolesItem[]
  reason: string // 申请原因
  isInternalStaff?: number // 是否内部员工，0不是内部员工，1:是内部员工
  staffName?: string // 员工姓名
}

export interface RolesItem {
  roleId: string, // 权限id
  roleName: string, // 权限名称
}

export enum ApplyStatusEnum {
  'DRAFT' = 1, // 草稿
  'APPROVAL' = 2, // 审批中
  'ACCEPT' = 3, // 已通过
  'REJECT' = 4, // 已驳回

}

export interface SalesApprovalApplyListPayload extends Pagination {
  status?: ApplyStatusEnum // 流程状态 1: 草稿, 2: 审批中, 3:已通过, 4:已驳回
}

export interface SalesApprovalApplyItem {
  id: number,
  approvalType: string, // 审批流类型
  approvalId: string, // 审批流编号
  salesName: string, // 申请人
  title: string, // 标题
  createdTime: string, // 申请时间
  status: ApplyStatusEnum, // 流程状态 (1: 草稿, 2: 审批中, 3:已通过, 4:已驳回)
  approveTime: string, // 审批时间
  handlers: string, // 当前审批人
  roleRecordInfo: {
    roleName: string, // 申请角色
    reason: string, // 申请原因
    staffName: string, // 内部员工企微ID
  }
}
