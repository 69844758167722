/**
 * 公用选择器
 * @author tylerzzheng
 */
import React, { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react'
import { message, Select, SelectProps, Spin, Cascader } from 'antd'
import { OptionProps, SelectValue } from 'antd/lib/select'
import {
  getResList,
  isArray,
  isEmpty,
  isEmptyArray,
  isFunction,
  isNullOrUndefined,
  isString,
  notEmptyArray,
  ServiceType,
} from '@library'
import store from '@store'

const _prefix = 'CommonSelector-'

export interface CommonSelectorProps<F extends object, T extends object | string> extends Omit<SelectProps, 'defaultValue' | 'onSearch'> {
  // id - 与filter结合区分后，区分不同数据源。不同service的id值应该唯一
  id: string
  // 组件名字，请求数据报错时会用到
  name?: string
  // 查询参数,
  filter?: Partial<F>
  // 数据请求接口
  service?: ServiceType<F, T>
  // 额外列表数据 - 会放在下拉列表的头部
  extraList?: OptionProps[]
  // 是否service一次请求就拉可以拉回全量数据（是否分页） - 默认false全量。全量情况下, 指定value值时不会根据value再去拉取list
  // 如果需要拉取list中没有val，则需提供一个方法，将val转换为上送的参数。注意提供的方法的入参val在单选时也是数组，是过滤后未在list中的值的集合
  // 或者可以提供一个string, 会以{[fetchMore: keyof F]: val}的对象，结合到filter当中。 其中val也是数组
  fetchMore?: keyof F | ((val: string[] | number[]) => Partial<F>)
  // 搜索参数 - 可以是一个字符串或函数。存在时会自动设置showSearch为true
  // 字符串时，会把用户输入的string和此key结合成object，merge到filter中。函数时， 会把函数返回的对象 merge到filter中。
  // 业务场景可能会有用户输入的对应多个key。如员工组件中英文都可以搜素，需要自行判断searchKey是saleName还是saleNameCh。
  searchKey?: keyof F | ((searchVal: string) => Partial<F>)
  // value对应的值字段名 - 要求T[valueKey]的值是string|number
  // 当列表数据为非对象数组时，无valueKey。组件中涉及到valueKey的地方都需判断。
  valueKey?: keyof T
  // value对应显示的text -  注意： labelInValue模式下也会影响labelInValue
  labelKey: keyof T | ((item: T, index: number) => React.ReactNode)
  // 下拉列表每一项的title
  optionTitle?: keyof T | ((item: T, index: number) => string)
  // 可以指定某些item不可选
  disableItem?: (item: T, index: number) => boolean
  // 自定义渲染option方法 - 需返回一个antd的Option组件，valueKey，disableItem和labelKey等将失效
  renderOption?: (item: T, index: number) => React.ClassicComponentClass<OptionProps>
  // 和antd的onChang相同,不同的是会多一个参数，第二个参数是所有value的原对象组成的数组，在需要获得不值对象上的多个值时非常有用
  onChanges?: (val: SelectValue, valList: T[]) => void
  // 搜索的时候的placeholder
  placeholderOnSearching?: string
  // 下拉框类型，普通 / 级联
  type?: 'Select' | 'Cascader'
  // 级联选择器模式下 是否多选
  multiple?: boolean
}

// 公共选择器共享的组件实例共享的静态数据。类似于class的静态变量
const StaticLoadingMap = new Map<string, boolean>([])
const StaticFetchingMap = new Map<string, NodeJS.Timeout>([]) // 是否有setTimeout
const StaticValueMap = new Map<string, any[]>([]) // 收集不全的value

/**
 * 公共选择器
 *
 * 支持远程搜索，自定义render，额外list，扩展onchange，同数据源的不同组件实例共享list数据，不会重复请求。
 * 当下拉list（很多后端接口都是分页的）中不包含value值时，antd会直接显示value值，而不是经由list翻译后的值。本组件解决了这个问题，如果参数中有fetchMore字段，会自动拉取list中不含value的数据。
 */
export const CommonSelector = <F extends object, T extends object> (props: PropsWithChildren<CommonSelectorProps<F, T>>) => {
  const { userInfo } = store.useSession()
  const baseFilter = useMemo(() => ({ page: 1, pageSize: 30, belongModule: userInfo?.belongModule }), [userInfo?.belongModule]) // 常用参数

  const {
    id,
    name,
    filter: filterProp,
    fetchMore,
    extraList,
    renderOption,
    service,
    valueKey,
    labelKey,
    disableItem,
    optionTitle,
    searchKey,
    onChanges,
    placeholderOnSearching,
    /* origin */
    value,
    labelInValue,
    loading,
    onChange,
    dropdownRender,
    showSearch,
    onBlur,
    placeholder,
    onDropdownVisibleChange,
    type = 'Select',
    multiple = true,
    ...otherProps
  } = props

  const key = useMemo(() => _prefix + id + JSON.stringify(filterProp), [id, filterProp])

  /* 数据 */
  const { selectorData, setSelectorData } = store.useGlobal()
  const list = useMemo(() => selectorData[key] as T[], [key, selectorData])

  const [fetchLoading, setFetchLoading] = useState(false)
  const fetchList = useCallback(async (filter?: Partial<F>) => {
    if (!isFunction(service)) return []

    if (StaticLoadingMap.get(key)) return []

    const realFilter = { ...baseFilter, ...filterProp, ...filter } as F
    setFetchLoading(true)
    StaticLoadingMap.set(key, true)
    const [res, err] = await service(realFilter)
    StaticLoadingMap.set(key, false)
    setFetchLoading(false)
    if (err) {
      message.error(`${name}选择器获取数据失败` + err.message)
    }
    const [newList] = getResList(res, [])
    if (notEmptyArray(newList)) {
      setSelectorData(draft => {
        const oldList: T[] = draft[key] || []
        draft[key] = valueKey ? _.uniqBy([...newList, ...oldList], valueKey) : [...newList, ...oldList]
      })
    } else {
      setSelectorData(draft => {
        const oldList: T[] = draft[key]
        if (isNullOrUndefined(oldList)) {
          draft[key] = []
        }
      })
    }
    return newList
  }, [filterProp, setSelectorData, service, name, key, baseFilter])

  // 这里的目的是检测value数组是否全部包含于list之中，如果没有，那么select组件给用户展示出来的只是个id，而不是翻译出来的text。此时需要请求后台数据（这里还要保证接口支持按valueKey进行筛选）
  useEffect(() => {
    if (!isFunction(service)) return

    if (isEmptyArray(list)) return// 说明已经拉过列表数据，只不过拉过的数据为空array，那么无需再拉取并直接返回

    if (isNullOrUndefined(list)) { // 列表数据不存在，拉取数据
      ;(async () => await fetchList())()
    }

    if (notEmptyArray(list)) { // 说明已经拉过列表数据，下面代码将判断是否所有value都存在于list中。对于不在list中的value将拉取一次
      if (labelInValue || isEmpty(fetchMore) || isEmpty(value)) return

      // 此时， 下拉列表有value，那么检测value是否全部包含于list之中，得到未在list中的列表
      // 若是级联选择，value将会是个二维数组，需要对value降维，否则 【检测value是否全部包含于list之中】 会报错
      const temp = (isArray(value) ? value.flat(Infinity) : [value]) as any[]
      const valuesNotIncluded = _.difference(temp, [...list?.map?.(item => valueKey ? item[valueKey] : item), ...extraList.map(item => item.value)])
      const allValuesNotIncluded = _.uniq([...valuesNotIncluded, ...(StaticValueMap.get(key) || [])])
      StaticValueMap.set(key, allValuesNotIncluded)

      // 统一获取数据
      clearTimeout(StaticFetchingMap.get(key))
      const timer = setTimeout(() => {
        const valuesNotIncluded = [...(StaticValueMap.get(key) || [])]
        if (notEmptyArray(valuesNotIncluded)) {
          StaticValueMap.set(key, [])
          const filter = isFunction(fetchMore) ? fetchMore(valuesNotIncluded) : { [fetchMore as keyof F]: valuesNotIncluded }
            ;(async () => await fetchList(filter as Partial<F>))()
        }
      }, 500)
      StaticFetchingMap.set(key, timer)
    }
  }, [list, value, valueKey, labelInValue, fetchMore, key])

  const onDataChange = useCallback((...params: Parameters<typeof onChange>) => {
    setIsSearching(false) // 选择完毕之后取消搜索模式，否则的话搜索的文本都没了，显示的却还是搜索的内容list

    if (isFunction(onChange)) onChange(...params)
    if (isFunction(onChanges)) {
      const temp = params[0]
      const values = isArray(temp) ? temp : [temp || []]
      const selectedList = (values as any[]).map(val => list?.find(item => valueKey ? item[valueKey] === val : item === val))
      onChanges(temp, selectedList)
    }
  }, [onChanges, onChange, valueKey, list])

  /* 搜索 */
  const [isSearching, setIsSearching] = useState(false)
  const [searchedList, setSearchedList] = useState<T[]>([])
  const onSearch = useCallback(async (searchVal: string) => {
    if (!showSearch || isNullOrUndefined(searchKey)) return

    if (isEmpty(searchVal)) {
      setIsSearching(false)
      return
    }

    setIsSearching(true)
    const filter = isFunction(searchKey) ? searchKey(searchVal) : { [searchKey]: searchVal }
    const list = await fetchList(filter as Partial<F>)
    setSearchedList(list)
  }, [searchKey, showSearch, fetchList])
  const onSearchDebounce = useMemo(() => (showSearch && searchKey) ? _.debounce(onSearch, 1000, { trailing: true }) : undefined, [searchKey, showSearch])

  /* 展示 */
  const getLabel = useCallback((item: T, index: number): React.ReactNode => {
    let label: React.ReactNode
    if (isFunction(labelKey)) {
      label = labelKey(item, index)
    }
    if (isString(labelKey)) {
      label = item[labelKey as string]
    }
    return label || (valueKey ? item[valueKey] : item)
  }, [labelKey, valueKey])

  const [open, setOpen] = useState(false)
  const placeholderText = useMemo(() => (showSearch && open) ? placeholderOnSearching : placeholder, [placeholderOnSearching, placeholder, showSearch, open])

  const renderList = useCallback((list: T[]) => {
    return list.map((item, index) => {
      if (isFunction(renderOption)) return renderOption(item, index)

      const label = getLabel(item, index)
      const disabled = isFunction(disableItem) && disableItem(item, index)
      const title = isFunction(optionTitle) ? optionTitle(item, index)
        : isString(optionTitle) ? item[optionTitle as string]
          : isString(label) ? label
            : ''

      return (
        <Select.Option
          key={(valueKey ? item[valueKey] : item) as unknown as (string|number)} // 这里之前key是index，不知道为什么会有重复，重复之后会引起严重的渲染问题
          disabled={disabled}
          value={(valueKey ? item[valueKey] : item) as unknown as (string|number)}
          label={label}
          title={title}
        >
          {label}
        </Select.Option>
      )
    })
  }, [renderOption, getLabel, disableItem, optionTitle, valueKey])

  const Options = useMemo(() => isSearching ? renderList(searchedList) : renderList(list || []), [renderList, isSearching, searchedList, list])
  const extraOptions = useMemo(() => isSearching ? undefined : extraList.map((option, index) => <Select.Option key={index} {...option} />), [isSearching, extraList])

  return (
    // eslint-disable-next-line
    type === 'Select' ?
      <Select
        {...otherProps}
        value={value}
        onChange={onDataChange}
        labelInValue={labelInValue}
        loading={loading || fetchLoading}
        placeholder={placeholderText}
        showSearch={showSearch}
        dropdownRender={originRender => {
          const render = isFunction(dropdownRender) ? dropdownRender(originRender) : originRender
          return <Spin size="small" spinning={fetchLoading}>{render}</Spin>
        }}
        onDropdownVisibleChange={open => {
          setOpen(open)
          if (isFunction(onDropdownVisibleChange)) onDropdownVisibleChange(open)
        }}
        onSearch={onSearchDebounce}
        onBlur={value => {
          setIsSearching(false)
          if (isFunction(onBlur)) onBlur(value)
        }}
      >
        {extraOptions}
        {Options}
      </Select>
      // eslint-disable-next-line
      :
      // @ts-ignore
      <Cascader
        options={list}
        value={value}
        multiple={multiple}
        changeOnSelect
        expandTrigger="click"
        fieldNames={{
          label: 'name',
          value: 'code',
          children: 'child',
        }}
        displayRender={label => label.join('/')}
        onChange={onDataChange}
        loading={loading || fetchLoading}
        placeholder={placeholderText}
        showSearch={showSearch}
        dropdownRender={originRender => {
          const render = isFunction(dropdownRender) ? dropdownRender(originRender) : originRender
          return <Spin size="small" spinning={fetchLoading}>{render}</Spin>
        }}
        onDropdownVisibleChange={open => {
          setOpen(open)
          if (isFunction(onDropdownVisibleChange)) onDropdownVisibleChange(open)
        }}
        onSearch={onSearchDebounce}
        onBlur={value => {
          setIsSearching(false)
          if (isFunction(onBlur)) onBlur(value)
        }}
      />
  )
}

CommonSelector.defaultProps = {
  name: '',
  style: { minWidth: '100px' },
  filter: {},
  extraList: [],
  placeholderOnSearching: '搜索...',
  /* origin */
  filterOption: false, // 默认后端搜索，不需要前端过滤
  allowClear: true,
  showArrow: true,
  showSearch: false,
}
