/**
 * 列表表单项
 * @author wzwwu
 */
import React, { ReactElement } from 'react'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Row, Col, Button } from 'antd'

interface ListFormItemProps<T> {
  list: T[]
  onChange: (index: number, ...params: any[]) => void
  onDelete: (index: number) => void
  onAdd: () => void
  children: ReactElement | ((params: { value: T, onChange: (value: T) => void }) => ReactElement)
}

export function ListFormItem<T> (props: ListFormItemProps<T>) {
  const { list, onChange, onDelete, onAdd, children } = props

  const renderEditor = (item: T, i: number) => {
    if (typeof children === 'function') {
      return children({ value: item, onChange: (...params) => onChange(i, ...params) })
    } else {
      return (
        <children.type
          key={children.key}
          {...children.props}
          value={item}
          onChange={(...params) => onChange(i, ...params)}
        />
      )
    }
  }

  return (
    <>
      {list?.map((item, i) => (
        <Row gutter={6} key={i} className="mt10">
          <Col span={22}>
            {renderEditor(item, i)}
          </Col>
          <Col span={2}>
            <Button style={{ width: '100%' }} icon={<MinusOutlined />} onClick={() => onDelete(i)} />
          </Col>
        </Row>
      ))}
      <Row gutter={6} className="mt10">
        <Button type="dashed" icon={<PlusOutlined />} style={{ width: '100%' }} onClick={onAdd} />
      </Row>
    </>
  )
}
