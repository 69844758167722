/**
 * 公共树选择器的配置项
 * @author tylerzzheng
 */
import { CustomerIntentionItem, IRoleLayer, IRoleLayerFilter, SecondIndustryItem } from '@types'
import { getCustomerIntention, getIndustryIncome } from '@services/customer'
import { getRoleLayer } from '@services/organazition'
import { CommonSelectProps } from './index'
import { isArray } from '@library'

// 客户意向配置项
export const customerIntention: CommonSelectProps<{}, CustomerIntentionItem> = {
  name: '客户意向',
  service: getCustomerIntention,
  translate: data => ({
    id: data.intentionId,
    value: data.intentionId,
    title: data.intentionName,
    children: data.subIntention?.map(customerIntention.translate),
    isLeaf: !isArray(data.subIntention),
  }),
}

// 角色树
export const roleTree: CommonSelectProps<IRoleLayerFilter, IRoleLayer> = {
  name: '角色树',
  service: getRoleLayer,
  translate: role => ({
    id: role.roleId,
    value: role.roleId,
    title: role.name,
    isLeaf: !role.hasSub,
    other: role.tag, // 加载子节点的时候需要
  }),
  loadData: data => ({ tag: data.other }),
}

// UIN行业
export const uinIncomeIndustry: CommonSelectProps<{}, SecondIndustryItem> = {
  name: 'UIN行业',
  service: getIndustryIncome,
  translate: data => ({
    id: data.primaryIndustryIncomeId,
    title: data.primaryIndustryIncomeName,
    value: data.primaryIndustryIncomeId,
    selectable: false,
    children: data.child?.map(item => ({
      id: item.secondaryIndustryIncomeId,
      title: item.secondaryIndustryIncomeName,
      value: item.secondaryIndustryIncomeId,
    })
    ),
  }),
}
