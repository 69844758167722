/**
 * setInterval 封装
 * @author jasonelchen
 */
import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay: number) => {
  const savedCallback = useRef(null)

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const handler = () => savedCallback.current()
    const id = setInterval(handler, delay)
    return () => clearInterval(id)
  }, [delay])
}
