/**
 * 所有任务列表
 * @author 郑巡卫
 */
import React, { useMemo } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { Card, Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import { CommonFilters, PageHeader, TableDownload, UserAvatar } from '@components'
import { TableColumnProps, useTableColumn } from '@hooks'
import { AllTaskFilter, AllTaskListItem, needTotalEnum, sortTypeEnum, TaskStatusEnum } from '@types'
import { exportAllTaskList, getAllTaskList } from '@services/task'
import { antdSortMap, taskStatusMap } from '@configs/map'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { allTaskListFilterItems } from '../../../components/CommonFilters/configs'
import { formatTime, trimStr } from '@library'
import { useTableService } from '@hooks/useTableService'

const initFilterValue = { taskCreateTime: [moment().subtract(1, 'month'), moment()], taskStatus: TaskStatusEnum.UNHANDLED }
const initFilter: AllTaskFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  sort: 'taskCreateTime',
  sortType: sortTypeEnum.DESC,
  taskCreateBeginTime: formatTime(initFilterValue.taskCreateTime[0], 'start'),
  taskCreateEndTime: formatTime(initFilterValue.taskCreateTime[1], 'end'),
  taskStatus: TaskStatusEnum.UNHANDLED,
}

export default function AllTaskPage () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getAllTaskList, initFilter, '获取所有任务列表失败')

  const onTableChange: TableProps<AllTaskListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<AllTaskFilter>(sorter)
    setFilter(sort)
  }

  const columns: TableColumnProps<AllTaskListItem>[] = useMemo(() => [
    {
      title: '任务创建时间',
      dataIndex: 'taskCreateTime',
      width: 180, // 注意和时间相关都建议180
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'taskCreateTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '客户名称',
      dataIndex: 'name',
      width: 180,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: 'uin', dataIndex: 'uin', width: 150 },
    { title: 'cid', dataIndex: 'cid', width: 150 },
    { title: '任务类型', dataIndex: 'ruleTypeName', width: 150 },
    { title: '任务名称', dataIndex: 'taskName', width: 150 },
    {
      title: '任务状态',
      dataIndex: 'taskStatus',
      width: 150,
      render: taskStatus => taskStatusMap[taskStatus] || '',
    },
    { title: '一级来源', dataIndex: 'sourcePrimaryTagName', width: 120 },
    { title: '二级来源', dataIndex: 'sourceSecondaryTagName', width: 120 },
    {
      title: '当前归属业务组',
      dataIndex: 'groups',
      width: 200,
      render: groups => groups?.map?.(group => group?.groupName)?.join?.(','),
    },
    { title: '当前跟进人', dataIndex: 'follower', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '最近跟进记录时间', dataIndex: 'lastFollowTime', width: 180 },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.ALL_TASK, columns, ['name'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="所有任务" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          initValue={initFilterValue}
          itemList={allTaskListFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.ALL_TASK} onSuccess={forceRequest} />
        <TableDownload className="ml10" exportMethod={exportAllTaskList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
