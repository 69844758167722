/**
 * 应用主入口
 * @author jasonelchen
 */
import React from 'react'
import { render } from 'react-dom'
import App from './App'
import './styles'
import { initAegis } from './library/rum'

initAegis()

render(<App />, document.getElementById('root'))
