/**
 * 登录接口相关
 * @author jasonelchen
 */
import { requestApi, requestApiV2 } from '@library'
import {
  CommonList,
  UserInfo,
  CompanyInfo,
  AuthPointItem,
  StaffisAssign,
  TableConfig,
  TableFieldsInfo,
} from '@types'

/**
 * @deprecated
 * 这个接口已经过时，改成getModuleCompanyMap 和 getModuleCompanyMapNoSession 统一获取模块和公司列表
 * 获取模块和公司信息 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000779577
 */
export function getCompanyList () {
  return requestApi<CommonList<CompanyInfo>>('GetCompanyList')
}

/**
 * 获取模块公司MAP(非登录态) http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000510487
 * 在非登录态调用，仅获取到所有模块和公司
 */
export function getModuleCompanyMapNoSession () {
  return requestApiV2<{list: CompanyInfo[]}>('/trpc.tcc.tcc_main.Company/GetModuleCompanyMap')
}

/**
 * @deprecated 新版后端服务不再提供调用，使用noSession的即可。
 * 获取模块公司MAP http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000510487
 * 在有登录态的前提下才能调用，仅获取到当前登录模块的公司和模块
 */
export function getModuleCompanyMap () {
  return requestApi<CompanyInfo[]>('GetModuleCompanyMap')
}

// 获取获取列表页可自定义显示列  http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001746637
export function getTableConfig (fieldsUsage: string) {
  return requestApiV2<TableConfig>('/trpc.tcc.tcc_main.Table/GetTableFieldsInfo', { fieldsUsage })
  // return requestApi<TableConfig>('GetTableFieldsInfo', { fieldsUsage })
}

// 更新列表页可自定义显示列 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001746633
export function UpdateTableFieldsInfo (tableInfo: TableFieldsInfo) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Table/UpdateTableFieldsInfo', tableInfo)
}

// 登录接口
export function login (code: string, moduleId: number, appId: string, agentId: number, tccCompany: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Auth/GetTccToken', { code, tccModule: moduleId, tccCorp: appId, tccAgentId: agentId, tccCompany })
}

// 获取用户信息接口
export function getUserInfo () {
  return requestApiV2<UserInfo>('/trpc.tcc.tcc_main.Auth/GetUserInfo')
}

// 登出
export function clearTccToken () {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Auth/ClearTccToken')
}

// 员工签入签出
export function CheckInOrOut (param: { id: number, isAssign: StaffisAssign }) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Sales/CheckInOrOut', param)
}

// 获取登陆态下的权限点
export function getUserAuthPoint (authPoint: AuthPointItem[], belongModule?: number[]) {
  return requestApiV2<{ authPoints: AuthPointItem[] }>('/trpc.tcc.tcc_main.Auth/GetUserAuthPoint', { authPoint, belongModule })
}

/**
 * 模拟用户退出 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001752901
 */
export function simulatedLogOut () {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Auth/SimulatedLogOut')
}

/**
 * 模拟用户登录 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001752793
 */
export function simulatedLogin (param: {salesName: string}) {
  return requestApiV2<{originTccToken: string}>('/trpc.tcc.tcc_main.Auth/SimulatedLogin', param)
}

/**
 * 切换登录模块 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002080201
 */
export function switchBelongModule (belongModule: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Auth/SwitchModule', { belongModule })
}
/**
 * 获取临时签名 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001755813
 * 上传文件使用的COS，new COS对象的时候需要用到临时签名，有效期是一个小时，然后用cos实例上传文件，具体参考COS文档：
 * https://cloud.tencent.com/document/product/436/11459
 *
 * @param param // 下面这两我也不熟悉，详见文档中的解释
 * method: 当前请求的 Method
 * Pathname: 请求路径，用于签名计算
 *
 * @authorization: 返回的临时签名
 */
export function getCosSignature (param: {method: string, path: string}) {
  return requestApiV2<{authorization: string}>('/trpc.tcc.tcc_main.Cos/GetCosSignature', param)
}
