/**
 * 标签
 * @author tylerzzheng
 */
import { Pagination } from './common'

export enum TagType {
  PRIVATE= 0,
  PUBLIC= 1,
}
export enum TagStatus {
  ACTIVE= 0,
  DEACTIVE = -1,
}

/**
 * 标签对象
 */
export interface Tag{
  tagId: number,
  tagName: string,
  tagType: TagType,
  lastModifier: string,
  belongModule: number,
  createTime: string,
  updateTime: string,
  status: TagStatus,
  creator: string,
}

/**
 * 获取标签列表筛选
 */
export interface TagFilter extends Pagination{
  tagId?: number | number[],
  tagIds?: number[],
  tagName?: string,
  tagType?: TagType,
  lastModifier?: string,
  belongModule?: number,
  status?: TagStatus,
  creator?: string,
}
