import React from 'react'
import { ModalFormProp, CommonSelector } from '@components'
import { robotTaskList, publicTag } from '../../components/CommonSelector/configs'
import CosUpload from '../../components/COSUpload'
import { CreateRobotCallRollParams } from '@types'
import { Input, Switch, InputNumber } from 'antd'

const InputNumberWapper = (props: {placeholder: string, onChange?: () => void}) => {
  const { placeholder, onChange } = props
  return (<div><InputNumber onChange={onChange} /> {placeholder} </div>)
}

export const RobotOutBoundFormItems: ModalFormProp<CreateRobotCallRollParams, any>['fieldItems'] = [
  {
    field: 'rollTitle',
    required: true,
    label: '名单标题',
    children: <Input maxLength={20} placeholder="请输入，最多20字" />,
  },
  {
    field: 'taskId',
    required: true,
    label: '关联任务',
    renderChildren: () => <CommonSelector {...robotTaskList} placeholder="请选择" />,
  },
  {
    field: 'excludeNamedCustomer',
    label: '过滤有效跟进人',
    children: <Switch defaultChecked />,
  },
  {
    field: 'callOutDay',
    label: '过滤已外呼',
    children: <InputNumberWapper placeholder="天内有机器人外呼则过滤不进入外呼名单" />,
  },
  {
    field: 'answeredDay',
    label: '过滤已接通',
    children: <InputNumberWapper placeholder="天内有机器人外呼且接通则过滤不进入外呼名单" />,
  },
  {
    field: 'publicTags',
    label: '过滤公共标签',
    renderChildren: () => <CommonSelector {...publicTag} mode="multiple" />,
  },
  {
    field: 'rollDescription',
    label: '名单描述',
    children: <Input.TextArea rows={3} maxLength={50} placeholder="请输入，最多50字" />,
  },
]

export const BatchCallRobotFormItems: ModalFormProp<CreateRobotCallRollParams, any>['fieldItems'] = [
  ...RobotOutBoundFormItems,
  {
    field: 'uploadFileKey',
    label: '批量上传',
    required: true,
    children: (
      <CosUpload multiple={false} maxCount={1} isShowUploadTemplate size={10} accept=".xlsx,.xls" />
    ),
  },
]
