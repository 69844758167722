/**
 * 坐席日常任务明细
 * @author v_linhairao
 */
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Button, Card, message, Table, Tooltip } from 'antd'
import { PageHeader, UserAvatar } from '@components'
import { ColumnProps, TableProps } from 'antd/lib/table'
import { commonTablePaginationConfig, getSortOrder } from '@configs/table'
import Filters, { FormValueType } from '@pages/report/agent-task/components/Filters'
import store from '@store'
import {
  OrgGroupFilter, Pagination,
  RuleStatisticsData,
  RuleStatisticsParamType,
  sortTypeEnum,
  pageSizeEnum,
} from '@types'
import { getSalesStatistics } from '@services/report'
import moment from 'moment'
import { getOrgGroupList } from '@services/organazition'
import { exportTable, isNullOrUndefined, parseDuration } from '@library'
import { antdSortMap } from '@configs/map'
import { useTableService } from '@hooks/useTableService'
import { useObject } from '@hooks'
import { TablePaginationConfig } from 'antd/lib/table/interface'

const tipsMap = {
  recordTime: { title: '时间' },
  companyName: { title: '公司' },
  groupName: { title: '群组' },
  salesName: { title: '员工' },
  callTotal: { title: '外呼次数', tip: '按外呼时间，统计周期内，外呼次数累加，无论是否接通' },
  validCallTotal: { title: '接通次数', tip: '按外呼时间，统计周期内，已接通的外呼次数' },
  durationTotal: { title: '外呼总时长', tip: '按外呼时间，统计周期内，外呼已接通的通话时长累加' },
  validCallRate: { title: '接通率', tip: '接通次数/外呼次数' },
  averageDuration: { title: '平均通话时长', tip: '外呼总时长/接通次数' },
  att: { title: 'ATT', tip: '外呼总时长/外呼次数' },
  followTotal: { title: '跟进记录数', tip: '按跟进记录创建时间，统计周期内，跟进记录条数累加' },
  validFollowTotal: { title: '有效跟进记录数', tip: '按跟进记录创建时间，统计周期内，有效跟进记录条数累加' },
  newCustomerTotal: { title: '新增客户数', tip: '按客户分配时间，统计周期内，分配给该员工的所有客户' },
  touchTotal: { title: '触达客户数', tip: '按通话记录生成时间，统计周期内，呼叫的客户数，一个客户呼叫多次算一个' },
  communicateTotal: { title: '沟通客户数', tip: '按通话记录生成时间，统计周期内，呼叫并接通的客户数，一个客户呼通多次算一个' },
}
type RenderTitleType = (key: string) => React.ReactNode
const renderTitle: RenderTitleType = key => (<Tooltip title={tipsMap[key]?.tip}>{tipsMap[key]?.title} <QuestionCircleOutlined /></Tooltip>)
const headers = _.keys(tipsMap).map(key => ({ title: tipsMap[key]?.title, dataIndex: key })) as {
  title: string
  dataIndex: string
}[]

const AgentTask: FunctionComponent<{}> = () => {
  const { userInfo } = store.useSession()

  /**
   * 进入页面的时候默认展示
   * 默认是员工本人，最近7天，日期维度
   * 选择公司： 有权限点，则可以选择组织架构管理-公司列表的所有公司。没有权限点，则可以选择负责的公司。都没有就看不到公司选择下拉框。
   * 群组和员工选择，同之前的逻辑。
   */
  const initFilterValue: FormValueType = useMemo(() => ({
    timeType: 'day',
    salesName: userInfo.salesName,
    rangeTime: [moment().subtract(1, 'day'), moment().subtract(1, 'day')],
  }), [userInfo])

  const initFilter = useMemo<RuleStatisticsParamType>(() => ({
    timeType: initFilterValue?.timeType,
    salesName: [initFilterValue.salesName],
    beginTime: (initFilterValue.rangeTime[0]).startOf(initFilterValue.timeType || 'day').format('YYYY-MM-DD HH:mm:ss'),
    endTime: (initFilterValue.rangeTime[1]).startOf(initFilterValue.timeType || 'day').format('YYYY-MM-DD HH:mm:ss'),
    sort: 'recordTime',
    sortType: sortTypeEnum.DESC,
  }), [initFilterValue])

  const {
    loading,
    list,
    total,
    filter,
    setFilter,
    rowKey,
  } = useTableService(getSalesStatistics, initFilter, '获取坐席日常任务明细失败')

  // 前端分页
  const [pageConfig, setPageConfig] = useObject<Pagination>({ page: 1, pageSize: 10 })
  const pagination: TablePaginationConfig = useMemo(() => ({
    ...commonTablePaginationConfig,
    current: pageConfig.page,
    pageSize: pageConfig.pageSize,
    total,
    onShowSizeChange: (current, pageSize) => setPageConfig({ pageSize, page: 1 }),
    onChange: page => setPageConfig({ page }),
  }), [total, pageConfig])
  const datalist = useMemo(() => {
    const { page, pageSize } = pageConfig
    const start = (page - 1) * pageSize
    return list.slice(start, start + pageSize)
  }, [list, pageConfig])
  useEffect(() => setPageConfig({ page: 1 }), [list])

  const onTableChange: TableProps<RuleStatisticsData>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<RuleStatisticsParamType>(sorter)
    setFilter(sort)
  }

  const onFilterChanged = useCallback(async (filterValue: FormValueType) => {
    let salesNames: string[]
    // 当有选择员工时，以选择的员工为准，否则根据选择的公司或群组，查询下面全部的员工。最终上送的参数都是员工
    if (filterValue.salesName) {
      salesNames = [filterValue.salesName]
    } else {
      const orgGroupFilter: OrgGroupFilter = {
        companyId: filterValue?.companyId ?? undefined,
        groupIds: isNullOrUndefined(filterValue?.groupId) ? undefined : [filterValue.groupId],
        status: 0,
        page: 1,
        pageSize: pageSizeEnum.ALL,
      }
      const [res, err] = await getOrgGroupList(orgGroupFilter)
      if (err) {
        message.error(`获取员工失败${err.message}`)
      }
      salesNames = Array.from(res?.list || []).reduce((arr, { groupMember }) => _.union(arr, groupMember), [])
    }
    // 这里还可以在优化，转化格式然后对比filter和filterValue，只有当值不同时才set，避免表单重复提交
    setFilter({
      timeType: filterValue?.timeType,
      salesName: salesNames,
      beginTime: (filterValue?.rangeTime?.[0])?.startOf(filterValue?.timeType || 'day')?.format('YYYY-MM-DD HH:mm:ss'),
      endTime: (filterValue?.rangeTime?.[1])?.startOf(filterValue?.timeType || 'day')?.format('YYYY-MM-DD HH:mm:ss'),
    })
  }, [userInfo])

  const columns: ColumnProps<RuleStatisticsData>[] = useMemo(() => [
    {
      title: '时间',
      dataIndex: 'recordTime',
      fixed: 'left',
      sorter: true,
      width: 180,
      sortOrder: filter.sort === 'recordTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '公司', dataIndex: 'companyName' },
    { title: '群组', dataIndex: 'groupName' },
    { title: '员工', dataIndex: 'salesName', render: value => <UserAvatar salesName={value} size="small" /> },
    {
      title: renderTitle('callTotal'),
      dataIndex: 'callTotal',
      sorter: true,
      sortOrder: filter.sort === 'callTotal' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: renderTitle('validCallTotal'),
      dataIndex: 'validCallTotal',
      sorter: true,
      sortOrder: filter.sort === 'validCallTotal' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: renderTitle('durationTotal'),
      dataIndex: 'durationTotal',
      render: durationTotal => parseDuration(durationTotal, 's'),
      sorter: true,
      sortOrder: filter.sort === 'durationTotal' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: renderTitle('validCallRate'),
      dataIndex: 'validCallRate',
      sorter: true,
      sortOrder: filter.sort === 'validCallRate' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: renderTitle('averageDuration'), dataIndex: 'averageDuration', render: averageDuration => parseDuration(averageDuration, 's') },
    { title: renderTitle('att'), dataIndex: 'att', render: att => parseDuration(att, 's') },
    { title: renderTitle('followTotal'), dataIndex: 'followTotal' },
    { title: renderTitle('validFollowTotal'), dataIndex: 'validFollowTotal' },
    {
      title: renderTitle('newCustomerTotal'),
      dataIndex: 'newCustomerTotal',
      sorter: true,
      sortOrder: filter.sort === 'newCustomerTotal' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: renderTitle('touchTotal'),
      dataIndex: 'touchTotal',
      sorter: true,
      sortOrder: filter.sort === 'touchTotal' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: renderTitle('communicateTotal'), dataIndex: 'communicateTotal' },
  ], [filter.sort, filter.sortType])

  const exportData = useCallback(() => {
    const key = Math.random()
    message.loading({ content: '正在导出，请稍后...', key })
    exportTable({ headers, list: list, widthData: _.fill([], { wch: 20 }, 0, headers?.length ?? 0) }, '坐席日常任务明细.xlsx')
    message.success({ content: '导出完成', key, duration: 2 })
  }, [list])

  return (
    <>
      <PageHeader title="坐席日常任务明细" />
      <Filters initValues={initFilterValue} onSubmit={onFilterChanged} />
      <Card className="mt20">
        <Button type="primary" onClick={() => exportData()}>导出数据</Button>
        <Table
          className="mt20"
          size="small"
          loading={loading}
          pagination={pagination}
          rowKey={rowKey}
          dataSource={datalist}
          onChange={onTableChange}
          columns={columns}
          scroll={{ x: 2000 }}
        />
      </Card>
    </>
  )
}

export default AgentTask
