import { ISortOrder, ExtendItem, Pagination } from '@types'

// 额外表单模板对象 上送参数
export interface ResourceTmpFilter extends Pagination, ISortOrder{
  name?: string; // 名称
}

// 额外表单模板对象 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001826735
export interface ResourceTmp {
  id: number,
  name: string,
  createTime: string,
  status: boolean,
  creator: string,
  updateTime: string,
  lastModifier: string,
  belongModule: string,
  isEdit: boolean
}

export enum pageConfigEnum {
  EXTRAFORM = 'extraForm',
  DEFALUT = 'defalut',
}

export interface ExtraForm {
  extraFormId?: number
  belongFormType?: number // 0跟进记录表单，1额外表单
}

// 留资记录过滤器
export interface RetainListFilter extends ISortOrder{
  page?: number // 页码
  pageSize?: number // 每页数量
  extraFormId?: number | number[] // 模板ID
  extraFormName?: string // 模板名称
  customerName?: string // 所属客户
  taskName?: string // 所属任务
  leadId?: number | number[]
  follower?: string | string[] // 当前跟进人
  createTimeFrom?: string // 创建开始时间（格式为2020-04-17 16:46:45）
  createTimeTo?: string // 创建结束时间（格式为2020-04-17 16:46:45）
  creator?: string | string[] // 创建人
  sort?: 'createTime'
  cid?: string[] // cid
}

// 留资记录项
export interface RetainListItem {
  extraFormId?: number // 模板ID
  extraFormName?: string // 模板名称
  customerName?: string // 所属客户
  taskName?: string // 所属任务
  follower?: string | string[] // 当前跟进人
  createTime?: string // 创建时间（格式为2020-04-17 16:46:45）
  creator?: string | string[] // 创建人
  extend?: ExtendItem[]
}

export interface AddLeaveInfo {
  extraFormId: number
  leadId: number
  extend: string
}

export interface AddLeaveInfoRes {
  rows: boolean
  affected_rows: number
  insert_id: number
}
