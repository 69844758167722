/**
 * 公共权限组件
 * @author tylerzzheng
 */
import React, { FC, ReactNode, useMemo } from 'react'
import store from '@store'
import { AuthPointType } from '@configs/auth-point'
import { isArray, isString } from '@library'

export interface AuthCheckerProps {
  /**
   * 权限点集合，格式是AUTH_POINT_MAP的 key 值
   * 仅在初始化时检查，不会监听point的变化
   */
  points: AuthPointType | Array<AuthPointType>,
  //  'and' | 'or' 默认为and， 这会影响到是否渲染子元素
  type?: 'and' | 'or'
  //  没有权限的时候渲染内容
  renderDeny?: ReactNode
  // 为ture的时候直接绕过权限检测，都会认为是有权限的
  isWhite?: boolean
}

export const AuthChecker: FC<AuthCheckerProps> = (props) => {
  const { points, type, isWhite, renderDeny, children } = props

  const { authPointMap } = store.useGlobal()

  const hasPermission = useMemo(() => {
    if (isString(points)) {
      return authPointMap[points]?.result || false
    }
    if (isArray(points)) {
      if (type === 'and') {
        return points.every(point => authPointMap[point]?.result || false)
      }
      if (type === 'or') {
        return points.some(point => authPointMap[point]?.result || false)
      }
    }
  }, [authPointMap])

  const node = useMemo<ReactNode>(() => (isWhite || hasPermission) ? children : renderDeny, [isWhite, hasPermission, children, renderDeny])

  return <> {node} </>
}

AuthChecker.defaultProps = {
  points: [],
  type: 'and',
  isWhite: false,
}
