/**
 * 解析 extend
 * @author wzwwu
 */
import { ExtendItem } from '@types'

// 解析跟进记录的 extend
export const parseFollowExtend = (extendStr: string) => {
  const extend: { [key: string]: any } = {}
  try {
    const extendArr: ExtendItem[] = JSON.parse(extendStr)
    for (const item of extendArr) {
      let val
      switch (item?.key) {
        case 'predictPrice':
          val = item?.keyValue?.split?.(',')
          extend[item?.key] = val?.[0] && val?.[1]
            ? `${val?.[0]}-${val?.[1]} 万`
            : `${val?.[0] || val?.[1] || '-'} 万`
          break
        default:
          extend[item?.key] = item?.keyValue
      }
    }
  } catch (error) { }
  return extend
}

/**
 * 解析ExtendItem，返回array
 * @param extend
 */
export const parseExtend = (extend: string): ExtendItem[] => {
  let arr: ExtendItem[]
  try {
    arr = JSON.parse(extend)
  } catch (error) {
    return []
  }
  return arr
}

// 解析客户信息的 extend
export const parseCustomerExtend = (extendStr: string) => {
  const extend: { [key: string]: any } = {}
  try {
    const extendArr: ExtendItem[] = JSON.parse(extendStr)
    for (const item of extendArr) {
      extend[item?.key] = item?.keyValue
    }
  } catch (error) { }
  return extend
}
