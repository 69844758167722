import { apiHostMap } from '@configs'
/**
 * 根据环境获取对应环境磐石链接
 */
const envMap: {[key in keyof typeof apiHostMap]: string} = {
  // 'local.tcc.tencent.com': '//dev.panshi.woa.com',
  'local.tcc.tencent.com': '//dev.panshi.woa.com',
  'dev.tcc.tencent.com': '//dev.panshi.woa.com',
  'test.tcc.tencent.com': '//dev.panshi.woa.com',
  'pre.tcc.tencent.com': '//pre.panshi.woa.com',
  'tcc.tencent.com': '//panshi.woa.com',
  'bak.tcc.tencent.com': '//panshi.woa.com',
  'tcc-web-7gwart8i0811324b-1258344699.tcloudbaseapp.com': '//panshi.woa.com',
} as const

export const getPanshiUrl = (): string => {
  return envMap[window.location.hostname] || '//panshi.woa.com'
}
