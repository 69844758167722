/**
 * 机器人通话记录页面
 */
import React, { useRef, useMemo } from 'react'
import { Card, Table, Popover } from 'antd'
import { Link } from 'react-router-dom'
import { getTableXwidth } from '@configs/table'
import { CommonFilters, PageHeader, ReplayRecord, TableDownload } from '@components'
import { robotCallRecordFilterItems } from '../../../components/CommonFilters/configs'
import { trimStr, handleCallRecord, ExpandCallRecordDataType } from '@library'
import { useTableService } from '@hooks/useTableService'
import { listRobotCallRecord, exportRobotCallRecordList } from '@services/robot'
import { RobotCallRecordItem } from '@types'
import { TableColumnProps } from '@hooks'

const RobotCallRecordPage: React.FC = () => {
  const filterRef = useRef()
  const initFilter = {
    page: 1,
    pageSize: 10,
  }

  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(listRobotCallRecord, initFilter, '获取机器人外呼通话列表失败')

  type RobotCallRecordData = RobotCallRecordItem & ExpandCallRecordDataType

  const robotCallRecordList = useMemo<RobotCallRecordData[]>(() => handleCallRecord(list), [list])

  const columns: TableColumnProps<RobotCallRecordData>[] = useMemo(() => [
    { title: '任务名称', dataIndex: 'taskName', fixed: true, width: 150, ellipsis: true },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      fixed: true,
      width: 280,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '名单标题', dataIndex: 'rollTitle', width: 180, ellipsis: true },
    {
      title: '标签',
      dataIndex: 'tags',
      width: 200,
      ellipsis: true,
      render: (tags) => (<Popover placement="topLeft" content={tags?.join('，')}> {tags?.join('，')} </Popover>),
    },
    {
      title: '记录生成时间',
      dataIndex: 'createTime',
      width: 180,
    },
    {
      title: 'cid',
      dataIndex: 'cid',
      width: 180,
      ellipsis: true,
      render: (cid) => (<Popover placement="topLeft" content={cid}> {cid} </Popover>),
    },
    {
      title: 'callId',
      dataIndex: 'qidianCallId',
      width: 180,
      ellipsis: true,
      render: (qidianCallId) => (<Popover placement="topLeft" content={qidianCallId}> {qidianCallId} </Popover>),
    },
    { title: '呼叫次数', dataIndex: 'callCount', width: 100 },
    { title: '主叫号码', dataIndex: 'callNumber', width: 180 },
    { title: '被叫号码', dataIndex: 'phone', width: 180 },
    { title: '通话结果', dataIndex: 'failReasonText', width: 180 },
    {
      title: '通话开始时间',
      dataIndex: 'callBeginTime',
      width: 180,
    },
    {
      title: '通话结束时间',
      dataIndex: 'callEndTime',
      width: 180,
    },
    { title: '通话时长', dataIndex: 'durationText', width: 120 },
    { title: '满意度', dataIndex: 'satisfactionResultText', width: 120 },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 120,
      render: (formItem, item) => {
        return (
          <ReplayRecord
            disabled={item.disabled}
            playTips={item.playTips}
            logId={item?.qidianCallId}
            title={`与${item?.customerName}的通话录音`}
            key={item?.qidianCallId}
            robotFlag
          />
        )
      },
    },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  return (
    <>
      <section>
        <PageHeader title="机器人通话记录" />
        <Card className="mt20">
          <CommonFilters
            cRef={filterRef}
            className="custom-Label"
            itemList={robotCallRecordFilterItems}
            onSubmit={filter => setFilter({ ...filter, page: 1 })}
            onReset={() => resetFilter(initFilter)}
          />
        </Card>
      </section>
      <Card className="mt20">
        <TableDownload className="ml20" exportMethod={exportRobotCallRecordList} filter={filter} />
        <Table
          loading={loading}
          scroll={{ x: tableXWidth }}
          tableLayout="fixed"
          className="mt20"
          rowKey={rowKey}
          dataSource={robotCallRecordList}
          columns={columns}
          pagination={pagination}
        />
      </Card>
    </>
  )
}

export default RobotCallRecordPage
