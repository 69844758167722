/**
 * Query Hooks
 * @author jasonelchen
 */
import { useState, useEffect } from 'react'
import { parse } from 'qs'

export function useQuery<T extends any> () {
  const [query, setQuery] = useState(parse(window.location.search, { ignoreQueryPrefix: true }) as T)

  useEffect(() => {
    const query = parse(window.location.search, { ignoreQueryPrefix: true })
    setQuery(query as T)
  }, [window.location.search])

  return query
}
