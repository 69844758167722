/**
 * $
 * @author tylerzzheng
 */
import React, { Children, cloneElement, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { SettingOutlined } from '@ant-design/icons'
import { Alert, Checkbox, Col, message, Modal, Row } from 'antd'
import { ColumnHeader, TableIdEnum } from '@configs/table'
import { UpdateTableFieldsInfo } from '@services/session'
import { usePropState } from '@hooks'

interface ConfigTableHeaderProps<T> {
  className?: string
  tableId: TableIdEnum
  columnConfigs: ColumnHeader[]
  onSuccess: Function // 更新之后的回调，一般用作去拉新的数据
}

/**
 * 设置表头
* @param props
* @constructor
*/
export function ConfigTableHeader<T> (props: PropsWithChildren<ConfigTableHeaderProps<T>>) {
  const { className, columnConfigs = [], tableId, onSuccess, children, ...otherProps } = props

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showWarning, setShowWarning] = useState(false)
  const [configs, setConfigs] = usePropState(columnConfigs)

  const onChange = useCallback((index: number, checked: boolean) => {
    setConfigs(prevHeaders => {
      const temp = _.cloneDeep(prevHeaders)
      temp.splice(index, 1, { ...prevHeaders[index], hide: !checked })
      return temp
    })
  }, [setConfigs])

  useEffect(() => {
    setShowWarning(configs.filter(c => !c.hide)?.length <= 3)
  }, [configs])

  const onSubmit = useCallback(async () => {
    setLoading(true)
    const fields = configs?.filter(header => header.hide)?.map(ch => ({ key: ch.dataIndex }))
    const [, err] = await UpdateTableFieldsInfo({ fieldsUsage: tableId, fields })
    setLoading(false)
    if (err) {
      message.error('设置列表表头显示字段失败，:' + err.message)
    } else {
      setOpen(false)
      onSuccess()
    }
  }, [configs, onSuccess])

  const renderChildren = () => {
    if (!children) {
      return (
        <SettingOutlined
          onClick={() => setOpen(true)}
          style={{ cursor: 'pointer' }}
          className={className}
        />
      )
    } else {
      // 只能有一个子元素
      Children.only(children)

      return cloneElement(children as React.ReactElement, { ...otherProps, onClick: () => setOpen(true) })
    }
  }

  return (
    <>
      {renderChildren()}
      <Modal
        open={open}
        width={800}
        title="设置列表表头显示字段"
        onCancel={() => setOpen(false)}
        onOk={onSubmit}
        okButtonProps={{ loading }}
      >
        <Row>
          {configs?.map((header, index) => (
            <Col span={6} key={index}>
              <Checkbox
                key={header.dataIndex}
                onChange={e => onChange(index, e.target.checked)}
                disabled={header.disabled}
                checked={!header.hide}
              >
                {header.title}
              </Checkbox>
            </Col>
          ))}
        </Row>
        {showWarning && <Alert className="mt10" message="当展示列数特别少时，列表显示可能会出现渲染错位问题" type="warning" showIcon closable />}
      </Modal>
    </>
  )
}
