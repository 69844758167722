/**
 * 标签相关
 * @author tylerzzheng
 */
/* 添加线索 */
import { CommonList, StatisticsData, StatisticsParamType, Tag, TagFilter, TagType } from '@types'
import { requestApiV2 } from '@library'

export function getTagList (tagFilter: TagFilter) {
  return requestApiV2<CommonList<Tag>>('/trpc.tcc.tcc_main.Tag/GetTagList', tagFilter)
}

// 任务概览部分接口（日常任务报表） http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001818835
export function getStatistics (params: StatisticsParamType) {
  return requestApiV2<StatisticsData>('/trpc.tcc.tcc_main.Statistics/GetStatistics', params)
}

export function getPublicTagList (tagFilter: TagFilter) {
  return getTagList({ ...tagFilter, tagType: TagType.PUBLIC })
}

export function getPrivateTagList (tagFilter: TagFilter) {
  return getTagList({ ...tagFilter, tagType: TagType.PRIVATE })
}

/* 新增标签 */
export function addTag (tag: Partial<Tag>) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Tag/AddTag', tag)
}

/* 编辑标签 */
export function updateTag (tag: Partial<Tag>) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Tag/UpdateTag', tag)
}

/* 删除标签 */
export function deleteTag (tagId: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Tag/DeleteTagById', { tagId })
}
