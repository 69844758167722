/**
 * 处理 service 错误情况
 * @author jasonelchen
 */
import { useEffect } from 'react'
import { CommonError } from '@types'
import { message } from 'antd'

export function useShowError (msg: string, error: CommonError) {
  useEffect(() => {
    if (error) {
      return message.error(`${msg}(${error.message})`)
    }
  }, [error])
}
