/**
 * 公用级联选择器 配置项
 * 使用场景： 仅单选，多选请使用CommonTreeSelector。且需选择完整的父子节点，而不是仅父或子节点
 * 自带前端搜索，不需要搜索需要设置showSearch: false。配置后端搜索时（searchKey）前端搜索自动失效
 * 注意：loadData 与 showSearch 无法一起使用。 这是官方的问题，官方文档有说明，很坑的。所以本地搜索的时候就最好是全量的数据，否则最好后端搜索。
 */
import {
  CustomerIntentionItem,
  IndustryProduct,
  IndustryProductFilter, LeadIntentionLevel,
  SourceTag,
} from '@types'
import { getCustomerIntention, getIndustryProduct } from '@services/customer'
import { CommonCascadorProps } from './index'
import { getLeadIntentionLevel, getSourceTags } from '@services/lead'
import { AreaItem, areaTree } from '@configs/area'
import { ProductStatus } from '@configs'

// 行业产品 ，3、4级产品 - 通用配置
export const industryProducts: CommonCascadorProps<IndustryProductFilter, IndustryProduct> = {
  name: '产品',
  // 由于场景为全量，考虑页面性能，按1000为最大上限，但和后端确认了下，此份数据预期不太会有增长
  filter: { level: 3, page: 1, pageSize: 1000, status: ProductStatus.BOTH },
  service: getIndustryProduct,
  translate: data => ({
    id: data?.code,
    label: `${data ? `${data?.name}` : ''} ${data?.status === ProductStatus.IN_VALID ? '(失效)' : ''}`,
    value: data?.code,
    isLeaf: data?.level >= 4,
    children: data?.child?.map?.(industryProducts.translate),
  }),
  placeholderOnSearching: '按产品名称模糊搜索...',
}

// 行业产品 ，3、4级产品 - 仅生效数据交互场景
export const industryProductsOnlyValid: CommonCascadorProps<IndustryProductFilter, IndustryProduct> = {
  ...industryProducts,
  filter: { level: 3, page: 1, pageSize: 1000, status: ProductStatus.VALID },
}

// 客户意向
export const customerIntention: CommonCascadorProps<{}, CustomerIntentionItem> = {
  name: '客户意向',
  service: getCustomerIntention,
  translate: data => ({
    label: data?.intentionName,
    value: data?.intentionId,
    children: data?.subIntention?.map?.(customerIntention.translate),
  }),
  placeholderOnSearching: '按客户意向模糊搜索...',
}

// 客户来源
export const sourceTag: CommonCascadorProps<{}, SourceTag> = {
  name: '客户来源',
  service: getSourceTags,
  translate: data => ({
    label: data?.sourceName,
    value: data?.sourceId,
    children: data?.subSource?.map?.(sourceTag.translate),
  }),
  placeholderOnSearching: '按客户来源模糊搜索...',
}

// 意向度
export const leadIntentionLevel: CommonCascadorProps<{}, LeadIntentionLevel> = {
  name: '意向度',
  service: getLeadIntentionLevel,
  translate: data => ({
    label: data?.name,
    value: data?.code,
    children: data?.child?.map?.(leadIntentionLevel.translate),
  }),
  placeholderOnSearching: '按意向度模糊搜索...',
}

// 省市地区
export const area: CommonCascadorProps<{}, AreaItem> = {
  name: '省市地区',
  service: () => Promise.resolve([areaTree, null]),
  translate: data => ({
    label: data.label,
    value: data.value,
    children: data.children?.map?.(area.translate),
  }),
  placeholderOnSearching: '按省市地区模糊搜索...',
}
