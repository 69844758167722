import * as React from 'react'

/**
 * 类型公共接口
 * @author fredhaochen
 */

export interface PublicTags {
  publicTagId: number,
  publicTagName: string,
}

export interface PrivateTags {
  privateTagId: number,
  privateTagName: string,
}

export type TimeTypeEnum = 'hour' | 'day' | 'month'

export interface BelongModule {
  belongModule: number
}

export interface StatisticsParamType {
  timeType: TimeTypeEnum // 是 int 时间类型 hour|day|month
  // groupType: string // 是 sting 规则类型名称（模糊匹配）person|group|company
  beginTime: string // 是 string 查询开始时间
  endTime: string // 是 string 查询结束时间
  salesName?: string // 否 string 销售名字
  groupId?: number // 否 int 小组ID
  companyId?: number // 否 int 销售名字
}

export interface StatisticsData {
  xAxis: string[] | number[] // 横轴坐标点
  yAxis: StatisticsLine[] // 指标数据集合
}

// 一条指标数据
export interface StatisticsLine {
  data: number[] // 数据值
  key: string // 唯一标识
  name: string // 指标名称
}

/**
 * 页码， 这应该是一个公共的type
 */
export interface Pagination {
  page?: number // 页码
  /**
   * 每页数量
   * 当值为-1时，在部分接口拉取全量数据
   */
  pageSize?: number // 每页数量
}

export interface CssStyle {
  style?: React.CSSProperties
  className?: string
}

export enum pageSizeEnum {
  ALL = -1
}
export enum sortTypeEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}
/**
 * 排序方式
 */
export interface ISortOrder {
  sort?: string // 排序字段
  sortType?: sortTypeEnum
}

/**
 * 对于list/total这种分页的数据，当参数needTotal值为0的时候，后端只返回list内容，total值固定为-1
 * 当needTotal为1的时候，正常返回
 * 这里的目的是解决后端接口耗时过长，主要是花费在计算total上了
 * 默认是1
 *
 * 另外，为了方便起见。这里增加了第三种类型，needTotalEnum.Total，使用时需要在请求方法中特殊设置
 */
export interface AsyncTotal {
  needTotal?: needTotalEnum
}

export enum needTotalEnum {
  // 默认。 list和total都返回
  ALL = 1,
  // 只返回list， 返回的total固定值为-1
  LIST = 0,
  // 只返回total，list为空。 注意这个类型只是前端设置的，需要在service中特别处理
  TOTAL = 2,
}
