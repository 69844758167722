
import { ISortOrder, Pagination } from '@types'

export interface TaskLogItem {
  missionId: number
  missionType: number
  relatedId: string
  status: number
  creator: string
  createTime: string
  updateTime: string
  belongModule: number
  extend: string
  message: string
}

export interface TaskLogExtend {
  key: string,
  keyName: string,
  keyValue: string
}

// 系统任务日志查询条件
export interface AutoScriptMissionListPayload extends Pagination, ISortOrder {
  missionType?: number
  missionId?: number
  status?: number
  createTimeFrom?: string
  createTimeTo?: string
  creator?: string
}

// 任务状态
export const missionStatusMap = {
  0: '待执行',
  1: '执行中',
  2: '执行成功',
  3: '执行失败',
}
