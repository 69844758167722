
import React, { Children, cloneElement, PropsWithChildren } from 'react'
import { CheckCircleTwoTone } from '@ant-design/icons'
import { message, Modal, Button } from 'antd'
import { getPanshiUrl } from 'src/library/getPanshiUrl'
import { CommonError } from '@types'
interface DownloadProps<T> {
  exportMethod: (filters?: T) => Promise<[any, CommonError]>,
  filter?: T,
  [propName: string]: any,
}

/**
 * 统一的table数据导出组件。
 * 需指定service和service所需要的参数，即filters。
 * 默认渲染成一个Button，也可通过children来渲染成自定义的element，此时导出方法将自动绑定在children顶级的元素的onClick属性上
 *
 * @param props
 * @constructor
 */
export function TableDownload<T> (props: PropsWithChildren<DownloadProps<T>>) {
  const { exportMethod, filter, children, ...otherProps } = props

  const download = async () => {
    const [res, err] = await exportMethod(filter)
    if (err) {
      message.error(err?.message)
    } else if (res) {
      Modal.confirm({
        icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
        content: (<>您的导出文件正在后台准备中，点击 <a href={`${getPanshiUrl()}/system-manager/data-export`} rel="noopener noreferrer" target="_blank" style={{ color: '#3a87f1' }}>任务详情</a> 前往查看，或可通过【系统管理】-【我的文件导出】查看下载文件。</>),
        okText: '查看任务详情',
        cancelText: '知道了',
        onOk: () => window.open(`${getPanshiUrl()}/system-manager/data-export`),
      })
    }
  }

  const renderChildren = () => {
    if (!children) {
      return (<Button {...otherProps} onClick={() => download()}>下载数据</Button>)
    } else {
      // 只能有一个子元素
      Children.only(children)

      return cloneElement(children as React.ReactElement, { ...otherProps, onClick: download })
    }
  }

  return (
    <>
      {renderChildren()}
    </>
  )
}
