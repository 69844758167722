/**
 * 根据手机号查询UIN列表，用户选择之后。onOK事件将用户选择的UIN和UIN列表作为参数返回
 * @author tylerzzheng
 */
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { message, Modal, Table } from 'antd'
import { UinInfo } from '@types'
import { getUinByPhone } from '@services/customer'
import { ColumnProps } from 'antd/lib/table'
import { TableRowSelection } from 'antd/lib/table/interface'

interface UinListModalProps {
  /**
   * 根据此手机号搜索UIN列表
   */
  phone: number | string
  open: boolean
  onCancel: () => void
  onOk: (uinInfo: UinInfo, list: UinInfo[]) => void
}

export const UinListModal: React.FC<UinListModalProps> = (props) => {
  const { phone, open, onCancel, onOk } = props

  const [uinInfoList, setUinInfoList] = useState<UinInfo[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedUin, setSelectedUin] = useState<UinInfo>()

  useEffect(() => {
    if (_.isEmpty(phone)) {
      setSelectedUin(null)
      setLoading(false)
      return
    }
    ;(async () => {
      setLoading(true)
      const [res, err] = await getUinByPhone(phone + '')
      setLoading(false)
      if (err) {
        message.error('查询UIN报错' + err.message)
      }
      setUinInfoList(res?.list || [])
      // setUinInfoList([{ uin: '1233434435', name: '张三', cid: '123456789', regTime: '2021-06-27' }])
    })()
  }, [phone])

  const rowSelection: TableRowSelection<UinInfo> = useMemo(() => ({
    type: 'radio',
    onChange: (vals, uinInfo) => {
      console.log('onChange uinInfo===============', uinInfo)
      setSelectedUin(uinInfo[0])
    },
    getCheckboxProps: (record) => {
      console.log('record==========', record)
      const checkboxProps = { disabled: false }
      if (record?.authState === -1) {
        checkboxProps.disabled = true
      }
      return checkboxProps
    },
  }), [])

  const columns: ColumnProps<UinInfo>[] = useMemo(() => [
    {
      title: 'UIN',
      dataIndex: 'uin',
      render: (v, row) => {
        return <>{row?.authState === -1 ? row.uin + '(已注销)' : row.uin}</>
      },
    },
    {
      title: '客户名称',
      dataIndex: 'name',
    },
  ], [])

  const handleSubmit = useCallback(() => {
    onOk(selectedUin, uinInfoList)
    onCancel()
  }, [selectedUin, uinInfoList])

  return (
    <Modal
      open={open}
      width={650}
      title={`根据手机号${phone}共匹配出以下客户`}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText="使用该选定的客户信息"
      okButtonProps={{ disabled: !selectedUin }}
    >
      <Table
        pagination={{ hideOnSinglePage: true }}
        loading={loading}
        rowKey="uin"
        rowSelection={rowSelection}
        columns={columns}
        dataSource={uinInfoList}
        size="small"
      />
    </Modal>
  )
}
