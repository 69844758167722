/**
 * 可编辑文本
 * 会给children对象提供onChange，和onPressEnter两个属性
 * @author wzwwu
 */
import React, { ReactElement, useState, cloneElement, useMemo, useEffect, useCallback } from 'react'
import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons'

const iconStyle = { marginLeft: 10, color: '#1890ff' }

interface EditableTextProps<T> {
  text: React.ReactNode
  value: T
  onSubmit: (value: T) => Promise<void>
  children: ReactElement
  readonly?: boolean // 只读，不可以修改
}

export function EditableText<T> (props: EditableTextProps<T>) {
  const { text, value: propValue, onSubmit, readonly = false, children } = props

  const [editing, setEditing] = useState(false)
  const [value, setValue] = useState<T>(propValue)

  useEffect(() => {
    setValue(propValue)
  }, [propValue])

  const onClickOK = useCallback(async () => {
    await onSubmit(value)
    setEditing(false)
  }, [value, onSubmit, setEditing])

  const editor = useMemo(() => cloneElement(children, {
    ...children.props,
    value,
    onChange: setValue,
    onPressEnter: () => onClickOK(),
  }), [children, value, setValue])

  return !readonly && editing ? (
    <>
      {editor}
      <CheckOutlined style={iconStyle} onClick={onClickOK} />
      <CloseOutlined
        style={iconStyle}
        onClick={() => {
          setValue(propValue)
          setEditing(false)
        }}
      />
    </>
  ) : (
    <>
      {text}
      {!readonly && <EditOutlined style={iconStyle} onClick={() => setEditing(true)} />}
    </>
  )
}
