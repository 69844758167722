/**
 * 获取 app 菜单, 路由, 权限
 * @author wzwwu
 */
import { useMemo, useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import menu from '@configs/menu'
import routes from '@configs/router'
import { getUserAuthPoint } from '@services/session'
import store from '@store'
import AUTH_POINT_MAP, { AuthPointType } from '@configs/auth-point'
import { notEmptyArray } from '@library'
import { AuthPointMapType } from '../store/global'
import { MenuItem } from '@components'
import { useInterval } from '@hooks/useInterval'

export function useAppConfigs () {
  const uncheckedAuthPoints = useMemo(() => Object.values(AUTH_POINT_MAP).map(point => {
    const [object, action] = point?.split?.('-') ?? []
    return { object, action }
  }), [])

  const { logging, userInfo } = store.useSession()
  const { authPointMap, setAuthPointMap } = store.useGlobal()
  const [loading, setLoading] = useState(false)
  const [flag, setFlag] = useState<number>(0)

  useEffect(() => {
    if (logging) return
    (async () => {
      setLoading(true)
      const [res, err] = await getUserAuthPoint(uncheckedAuthPoints)
      setLoading(false)
      if (err) {
        message.error(`校验权限点失败(${err.message}), 请刷新页面重试`) // 静默失败
      }
      const invert = _.invert(AUTH_POINT_MAP) as { [p: string]: AuthPointType}
      if (Object.keys(invert).length !== Object.keys(AUTH_POINT_MAP).length) throw new Error('AUTH_POINT_MAP配置的权限点重复!!')
      const map = {} as AuthPointMapType
      if (notEmptyArray(res?.authPoints)) {
        for (const point of res?.authPoints) {
          const key: AuthPointType = invert[`${point.object}-${point.action}`]
          map[key] = point
        }
      }
      setAuthPointMap(map)
    })()
  }, [logging, flag])

  const updateAuthPoint = () => setFlag(f => f + 1)

  // 每一段时间更新一次权限
  useInterval(updateAuthPoint, 60 * 60 * 1000)

  // 根据权限筛选菜单
  const filterByAuthPoints = useCallback(
    <T extends Pick<MenuItem, 'subMenus' | 'authPoints' | 'belongModule'>>
    (arr: T[], map: AuthPointMapType): T[] => {
      return arr.reduce((newArr: T[], item) => {
        // 如果 item 设置了 authPoints，但是权限校验都没通过，过滤掉。 只有有一个满足就好
        if (notEmptyArray(item.authPoints) && item.authPoints.every(point => !map[point]?.result)) return newArr

        // 如果设置了belongModule, 仅在设置的belongModule显示
        if (notEmptyArray(item.belongModule) && !item.belongModule.includes(userInfo.belongModule)) return newArr

        // 如果 item 有子菜单，继续筛选子菜单
        const newItem: T = notEmptyArray(item.subMenus) ? { ...item, subMenus: filterByAuthPoints(item.subMenus, map) } : item

        // 如果原来有子菜单，筛选完后变成空的了，过滤掉，否则保留
        return newItem.subMenus && !newItem.subMenus.length ? newArr : newArr.concat(newItem)
      }, [])
    }, [userInfo])

  // 有权限路由
  const authRoutes = useMemo(() => filterByAuthPoints(routes, authPointMap), [authPointMap])

  // 有权限菜单
  const authMenu = useMemo(() => filterByAuthPoints(menu, authPointMap), [authPointMap])

  return {
    loading,
    routes: authRoutes,
    menu: authMenu,
    updateAuthPoint,
  }
}
