/**
 * 批次信息页面
 * @author v_linhairao
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Card, Table } from 'antd'
import { PageHeader, CommonFilters } from '@components'
import { BatchInfoListPayload, BatchInfoItem } from '@types'
import { ColumnProps } from 'antd/lib/table'
import { getBatchInfoList } from '@services/batch-info'
import { getTableXwidth } from '@configs/table'
import { batchInfoFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'

const initFilter: BatchInfoListPayload = {
  page: 1,
  pageSize: 10,
}

const ConfigBatchInfoPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getBatchInfoList, initFilter, '获取批次信息列表失败')

  const columns: ColumnProps<BatchInfoItem>[] = useMemo(() => [
    { title: '批次id', dataIndex: 'batchId', width: 80 },
    { title: '批次名称', dataIndex: 'batchName', width: 120 },
    { title: '线索下发方标识', dataIndex: 'leadReleaseSource', width: 120 },
    { title: '线索下发方原始信息', dataIndex: 'leadReleaseOriginMsg', width: 120 },
    { title: '创建时间', dataIndex: 'createTime', width: 120 },
    { title: '最后变更时间', dataIndex: 'updateTime', width: 120 },
  ], [])
  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  return (
    <>
      <PageHeader title="批次信息" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={batchInfoFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <Table
          className="mt20"
          loading={loading}
          columns={columns}
          dataSource={list}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
        />
      </Card>
    </>
  )
}
export default ConfigBatchInfoPage
