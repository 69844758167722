/**
 * 公用组件配置，
 * @deprecated 这里的配置已经不建议使用，将逐渐移除。建议使用相应的组件
 */
import { TypeConfig, BatchActionConfigItem } from '@types'

export const batchActionTypeConFig: TypeConfig<BatchActionConfigItem> = {
  'tag': {
    // batchType: true,
    publicTagIds: true,
    privateTagIds: true,
  },
  'batchTransfer': {
    // batchType: true,
    belongModule: true,
    remark: true,
  },
  // 'singleTransfer': {
  //   belongModule: true,
  //   remark: true,
  // },
}
