/**
 * 通话记录
 * @author fredhaochen
 */
import React, { useMemo } from 'react'
import { CommonFilters, PageHeader, ReplayRecord, TableDownload, UserAvatar } from '@components'
import { Card, Table } from 'antd'
import { exportCallRecordList, getCallRecordList } from '@services/customer'
import { CallRecordListFilter, CallRecordListItem, needTotalEnum, sortTypeEnum } from '@types'
import { TableColumnProps, useTableColumn } from '@hooks'
import { Link } from 'react-router-dom'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { antdSortMap } from '@configs/map'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { callRecordFilterItems } from '../../../components/CommonFilters/configs'
import { trimStr, handleCallRecord, ExpandCallRecordDataType } from '@library'
import { useTableService } from '@hooks/useTableService'
import { TableProps } from 'antd/lib/table'

const initFilter: CallRecordListFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}

export type CallRecordData = CallRecordListItem & ExpandCallRecordDataType

export default function CallRecordPage () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getCallRecordList, initFilter, '获取通话记录失败')

  const callRecordList = useMemo<CallRecordData[]>(() => handleCallRecord(list), [list])

  const onTableChange: TableProps<CallRecordListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<CallRecordListFilter>(sorter)
    setFilter(sort)
  }

  const columns: TableColumnProps<CallRecordData>[] = useMemo(() => [
    {
      title: '记录生成时间',
      dataIndex: 'createTime',
      width: 180,
      fixed: true,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 280,
      fixed: true,
      render: (name, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(name, '-')}
        </Link>),
    },
    { title: '主叫号码', dataIndex: 'callNumber', width: 180 },
    { title: '被叫号码', dataIndex: 'phone', width: 180 },
    { title: '通话结果', dataIndex: 'failReasonText', width: 180 },
    {
      title: '通话开始时间',
      dataIndex: 'callBeginTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callBeginTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '通话结束时间',
      dataIndex: 'callEndTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'callEndTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '通话时长', dataIndex: 'durationText', width: 120 },
    { title: '跟进人', dataIndex: 'salesName', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '满意度', dataIndex: 'satisfactionResultText', width: 120 },
    {
      title: '操作',
      dataIndex: 'actions',
      width: 120,
      render: (formItem, item) => {
        return (
          <ReplayRecord
            disabled={item.disabled}
            playTips={item.playTips}
            logId={item?.callId}
            title={`与${item?.customerName}的通话录音`}
            key={item?.callId}
          />
        )
      },
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.CALL_RECORD, columns, ['actions', 'customerName'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="通话记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={callRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.CALL_RECORD} onSuccess={forceRequest} />
        <TableDownload className="ml20" exportMethod={exportCallRecordList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={callRecordList}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
