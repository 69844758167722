/**
 * @deprecated 不建议使用。换成ModalForm
 * 批量打标
 * @author tylerzzheng
 */
import React, { Children, cloneElement, PropsWithChildren, useCallback, useEffect, useState } from 'react'
import { Alert, Button, Input, Modal, Select } from 'antd'
import { CommonSelector } from '@components'
import { BatchActionFormValues } from '@types'
import { batchActionTypeConFig } from '@configs/type-config'
import store from '@store'
import { privateTag, publicTag } from '../CommonSelector/configs'
import { createForm, Form } from '@library'

const { Option } = Select

interface BatchActionProps {
  type: string,
  banner?: React.ReactNode
  title?: string,
  onSubmit: (formData: BatchActionFormValues) => Promise<boolean | void>, // 当返回为true时，弹窗不会被关闭
  [propName: string]: any,
}

const formStore = createForm<BatchActionFormValues>()
const { useValues, useValidate, Field, withForm } = formStore

function BatchAction (props: PropsWithChildren<BatchActionProps>) {
  const {
    type,
    banner,
    onSubmit,
    title = '',
    children,
    ...otherProps
  } = props

  const field = batchActionTypeConFig[type]

  const { validate } = useValidate()
  const { values, setValues } = useValues()

  const { userInfo, moduleList } = store.useSession()

  const [showTagModal, setShowTagModal] = useState(false)
  const [loading, setLoading] = useState(false)

  // 入口渲染
  const renderEntrance = () => {
    if (!children) {
      return (<Button {...otherProps} onClick={() => setShowTagModal(true)}>{title}</Button>)
    } else {
      // 只能有一个子元素
      Children.only(children)

      return cloneElement(children as React.ReactElement, { ...otherProps, onClick: () => setShowTagModal(true) })
    }
  }

  // 表单初始化
  useEffect(() => {
    setValues({
      publicTagIds: [],
      privateTagIds: [],
      belongModule: undefined,
      remark: '',
    })
  }, [showTagModal])

  // 提交
  const submitAssignAction = useCallback(async () => {
    const validateRes = await validate()
    if (!validateRes.isPass) return

    setLoading(true)
    const formData = values as BatchActionFormValues
    console.log('formData', formData)
    const isErr = await onSubmit(formData)
    setLoading(false)
    if (!isErr) setShowTagModal(false)
  }, [values, onSubmit])

  return (
    <>
      {renderEntrance()}
      {showTagModal && (
        <Modal
          open
          width={650}
          title={title}
          onCancel={() => setShowTagModal(false)}
          onOk={submitAssignAction}
          confirmLoading={loading}
          okText="提交"
        >
          {banner && (<Alert message={banner} type="info" showIcon />)}
          <Form className="mt20" labelCol={{ span: 4 }} wrapperCol={{ span: 20 }}>
            {field?.publicTagIds && (
              <Field field="publicTagIds" label="公共标签">
                <CommonSelector {...publicTag} mode="multiple" />
              </Field>
            )}
            {field?.privateTagIds && (
              <Field field="privateTagIds" label="个人标签">
                <CommonSelector {...privateTag} mode="multiple" />
              </Field>
            )}
            {field?.belongModule && (
              <Field field="belongModule" label="转出方向" required>
                <Select>
                  {moduleList?.map(m => (
                    <Option
                      key={m.belongModule}
                      value={m.belongModule}
                      disabled={m.belongModule === userInfo.belongModule}
                    >
                      {m.belongModuleName}
                    </Option>
                  ))}
                </Select>
              </Field>
            )}
            {field?.remark && (
              <Field field="remark" label="转出说明" required>
                <Input type="string" />
              </Field>
            )}
          </Form>
        </Modal>
      )}
    </>
  )
}

export default withForm(BatchAction)
