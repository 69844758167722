/**
 * 客户管理接口
 * @author wzwwu
 */
import { getInterfaceName, isEmpty, requestApi, requestApiV2 } from '@library'
import {
  AddFollowFormItemParams,
  CallRecord,
  CallRecordListFilter,
  CallRecordListItem,
  ChangeRecordItem,
  CommonError,
  CommonList,
  ContactInfoFilter,
  ContactInfoItem,
  CustomerFilter,
  CustomerFlowItem,
  CustomerInfo,
  CustomerIntentionItem,
  CustomerItem,
  ExtraForm,
  FlowFilter,
  FlowRecordFilter,
  FollowRecordPayload,
  HistoryFollowListFilter,
  HistoryFollowListItem,
  IaccountInfo,
  IAddRuleTypePayload,
  ICustomerShouldIncomeLevel,
  IEditRuleTypePayload,
  IFollowFormItem,
  ImportCustomerBackflowFilter,
  ImportCustomerBackflowRes,
  IntentionProductItem,
  IRegisterInfo,
  IRuleType,
  MessageTemplateItem,
  ModuleDetailConfigItem,
  MsgRecord,
  OrderRecordFilter,
  OrderRecordItem,
  ReleaseCustomerFilter,
  RuleTypePayload,
  SendMessagePayload,
  SMSRecordListFilter,
  SMSRecordListItem,
  SmsReplyAutoBoundItem,
  SmsReplyAutoBoundListPayload,
  SmsTemplateContentPayload,
  TransferFilter,
  TransferItem,
  UinBaseInfoType,
  UinInfo,
  UpdateCustomerFilter,
  UpdateFollowFormItemParams,
  UserPortrait,
  VoucherApplication,
  VoucherApplicationItem,
  VoucherCreate,
  VoucherFilter,
  VoucherItem,
  VoucherProdItem,
  VoucherTypeItem,
  LeadOrCustomer,
  WorkOrderFilter,
  TransferCustomerToDianShiParam,
  PrimaryIndustryIncome,
  TransferCustomerParam,
  BindFailureRecordPayload,
  BindFailureRecordItem,
  CustomerNameFilter,
  ICustomerName,
  IndustryProductFilter,
  IndustryProduct,
  IndustryProductSearchFilter,
  SecondIndustryItem,
  SearchedCustomer,
  SearchedCustomerFilter,
  ApplyConflictParam,
  ConflictRecordItem,
  ConflictRecordFilter,
  IsNotifySales,
  CallCustomerPayload,
  PostOrderRecordFilter,
  PostOrderRecordItem,
  PostOrderProductCode,
  WorkOrderRecordItem,
  CustomerTicketComment, CustomerTicketCommentParams, IntentionChangeFilter, IntentionChangeItem,
  OpportunityTypeItem, StageInProgressTypeItem,
} from '@types'

// 获取客户信息 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000510955
export function getCustomerInfo (customerId: number) {
  return requestApiV2<CustomerInfo>('/trpc.tcc.tcc_main.Customer/GetCustomerInfo', { customerId })
}

// @deprecated 已废弃 根据UIN生成注册链接 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001792975
export function getRegisterLinkByUin (uin: string) {
  return requestApi<{url: string}>('GetRegisterLinkByUin', { uin })
}

// 根据CustomerId生成注册链接 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001969955
export function getRegisterLinkByCustomerId (customerId: number) {
  return requestApiV2<{url: string}>('/trpc.tcc.tcc_main.Customer/GetRegisterLinkByCustomerId', { customerId })
}

// 申请撞单 http://opc-mock.woa.com/project/530/interface/api/4084
export function applyConflictCustomer (param: ApplyConflictParam) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Customer/ApplyConflictCustomer', param)
}

// 获取撞单记录列表 http://opc-mock.woa.com/project/530/interface/api/4075
export function getConflictRecordList (param: ConflictRecordFilter) {
  return requestApiV2<CommonList<ConflictRecordItem>>('/trpc.tcc.tcc_main.Customer/GetConflictCustomerList', param)
}

// 导出撞单记录列表 http://opc-mock.woa.com/project/530/interface/api/4075
export function exportConflictRecordList (param: ConflictRecordFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Customer/ExportConflictRecordList', param)
}

// 申诉撞单
export function appealConflictCustomer (applyId: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Customer/AppealCustomer', { applyId })
}

// 获取历史跟进记录 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412000510971
export function getHistoryFollowList (filter: HistoryFollowListFilter) {
  return requestApiV2<CommonList<HistoryFollowListItem>>(getInterfaceName('/trpc.tcc.tcc_main.FollowRecord/GetHistoryFollowList', filter), filter)
}

// 获取短信记录 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001710815
export function getSMSRecordList (filter: SMSRecordListFilter) {
  return requestApiV2<CommonList<SMSRecordListItem>>(getInterfaceName('/trpc.tcc.tcc_main.Sms/GetSMSRecordList', filter), filter)
}

// 下载历史跟进记录
export function exportHistoryFollowList (filter: HistoryFollowListFilter) {
  return requestApiV2<CommonList>('/trpc.tcc.tcc_main.FollowRecord/ExportHistoryFollowList', filter)
}

// 获取通话记录 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001737569
export function getCallRecordList (filter: CallRecordListFilter) {
  return requestApiV2<CommonList<CallRecordListItem>>(getInterfaceName('/trpc.tcc.tcc_main.Call/GetCallRecordList', filter), filter)
}

// 下载通话记录
export function exportCallRecordList (filter: CallRecordListFilter) {
  return requestApiV2<CommonList>('/trpc.tcc.tcc_main.Call/ExportCallRecordList', filter)
}

// 提交跟进记录
export function addFollowRecord (payload: FollowRecordPayload) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.FollowRecord/AddFollowRecord', payload)
}

// 添加跟进记录表单项
export function addFollowFormItem (payload: AddFollowFormItemParams) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.FollowFormItem/AddFollowFormItem', payload)
}

// 删除跟进记录表单项
export function deleteFollowFormItem (payload: { id: number }) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.FollowFormItem/DeleteFollowFormItem', payload)
}

// 更新跟进记录表单项
export function updateFollowFormItem (payload: UpdateFollowFormItemParams) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.FollowFormItem/UpdateFollowFormItem', payload)
}

// 获取跟进记录表单项列表
export function getFollowFormItemList (payload?: ExtraForm) {
  return requestApiV2<CommonList<IFollowFormItem>>('/trpc.tcc.tcc_main.FollowFormItem/GetFollowFormItemList', payload)
}

// 搜索企业名称 https://opc-mock.woa.com/project/530/interface/api/3503
export function searchCustomerName (filter: SearchedCustomerFilter) {
  return requestApiV2<CommonList<SearchedCustomer>>('/trpc.tcc.tcc_main.Customer/SearchCustomerName', filter)
}

// 获取客户意向
export function getCustomerIntention () {
  return requestApiV2<CustomerIntentionItem[]>('/trpc.tcc.tcc_main.Customer/GetCustomerIntention')
}

// 获取客户意向产品
export function getIntentionProducts () {
  return requestApiV2<{ list: IntentionProductItem[] }>('/trpc.tcc.tcc_main.Customer/GetIntentionProducts')
}

/*
 * 获取行业产品 https://opc-mock.woa.com/project/530/interface/api/3350
 * 注意: 上送参数是带分页的，但返回不带，只有array结构，而不是total/list结构
 */
export function getIndustryProducts (filter: IndustryProductFilter) {
  return requestApiV2<IndustryProduct[]>('/trpc.tcc.tcc_main.Other/GetIndustryProducts', { ...filter, status: 1 })
}

// 获取行业产品按name模糊搜索 https://opc-mock.woa.com/project/530/interface/api/3358
export function getIndustryProductsSearch (filter: IndustryProductSearchFilter) {
  return requestApiV2<IndustryProduct[]>('/trpc.tcc.tcc_main.Other/GetIndustryProductsSearch', filter)
}

// 获取行业产品 把上面两个接口结合起来
export function getIndustryProduct (filter: IndustryProductFilter & IndustryProductSearchFilter) {
  // 如果有count就认为是在搜索，没有就认为是在查列表
  if (isEmpty(filter.count)) {
    return getIndustryProducts(_.omit(filter, ['count']))
  } else {
    return getIndustryProductsSearch({ ...filter, count: filter.count, name: filter.name, level: filter.level })
  }
}

// 获取可用短信模板列表
export function getAvailableTemplateList (params: {leadId?: number, contactId?: number}) {
  return requestApiV2<{list: MessageTemplateItem[]}>('/trpc.tcc.tcc_main.Sms/GetAvailableTemplateList', params)
}

// 根据电话获取UIN信息 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001771995
export function getUinByPhone (phone: string) {
  return requestApiV2<{ list: UinInfo[] }>('/trpc.tcc.tcc_main.Customer/GetUinByPhone', { phone })
}

// 发送短信给用户
export function sendSmsToCustomer (payload: SendMessagePayload) {
  return requestApiV2<{ logId: string }>('/trpc.tcc.tcc_main.Sms/SendSmsToCustomer', payload)
}

// 发送短信前查询预览用的专属链接
export function queryLinkUrl (cid: string) {
  return requestApiV2('trpc.tcc.tcc_main.Binding/GetLatestBindingURL', { cid })
}

// 呼叫用户
export function callCustomer (params: CallCustomerPayload) {
  return requestApiV2<{ logId: string }>('/trpc.tcc.tcc_main.Call/CallCustomer', params) // logId 就是CallRecordListItem.callId
}

// 获取外呼记录
export function getCallRecord (callId: string, robotFlag?: boolean) {
  const params = robotFlag ? { callId, robotFlag } : { callId }
  return requestApiV2<CallRecord>('/trpc.tcc.tcc_main.Call/GetCallRecord', params)
}

// 获取发送短信记录
export function getSMSRecord (logId: string) {
  return requestApiV2<MsgRecord>('/trpc.tcc.tcc_main.Sms/GetSMSRecord', { logId })
}

// 获取商品码 http://opc-mock.woa.com/project/530/interface/api/4840
export function getPostOrderProductList () {
  return requestApiV2<PostOrderProductCode[]>('/trpc.tcc.tcc_main.Trade/GetGoodsCodeReport')
}

// 获取客户订单记录 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001206197
export function getOrderRecord (params: OrderRecordFilter) {
  return requestApiV2<CommonList<OrderRecordItem>>('/trpc.tcc.tcc_main.Customer/GetCustomerOrder', params)
}

// 获取客户订单记录（大订单维度）https://iwiki.woa.com/p/4013014890
export function getBigOrderRecord (params: OrderRecordFilter) {
  return requestApiV2<CommonList<OrderRecordItem>>('/trpc.tcc.tcc_main.Customer/GetCustomerBigOrderList', params)
}

// 订单计费项映射产品子类
export function getOrderIndustryMapping (params: any) {
  return requestApiV2('/trpc.tcc.tcc_main.Voucher/GetOrderIndustryMapping', params)
}

export function getOrderRecordWithCheck (...params: Parameters<typeof getOrderRecord>) {
  if (isEmpty(_.get(params, '[0].uin'))) {
    return Promise.resolve([{ total: 0, list: [] }, null]) as ReturnType<typeof getOrderRecord>
  } else {
    return getOrderRecord(...params)
  }
}

// 获取后付费月度账单列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001206197
export function getPostOrderRecord (params: PostOrderRecordFilter) {
  return requestApiV2<PostOrderRecordItem[]>('/trpc.tcc.tcc_main.Trade/GetPostPaidMonthlyReport', params)
}

export function getPostOrderRecordWithCheck (...params: Parameters<typeof getPostOrderRecord>) {
  if (isEmpty(_.get(params, '[0].uin'))) {
    return Promise.resolve([{ total: 0, list: [] }, null]) as ReturnType<typeof getPostOrderRecord>
  } else {
    return getPostOrderRecord(...params)
  }
}

// 获取计费goodsId中文
export function getGoodsIdDesc () {
  return requestApiV2<{ idDesc: { number: string } }>('/trpc.tcc.tcc_main.Customer/GetGoodsIdDesc')
}

// 订单明细列表
export function getBillingOrderList (data) {
  return requestApiV2('/trpc.tcc.tcc_main.BillingOrder/GetBillingOrderList', data)
}

// 订单明细列表下载
export function exportBillingOrderList (data) {
  return requestApiV2('/trpc.tcc.tcc_main.BillingOrder/ExportBillingOrderList', data)
}

// 获取工单记录 http://opc-mock.woa.com/project/530/interface/api/4930
export function getWorkOrderRecord (filter: WorkOrderFilter) {
  return requestApiV2<CommonList<WorkOrderRecordItem>>('/trpc.tcc.tcc_main.Customer/GetCustomerAndonTicket', filter)
}

export function getWorkOrderRecordWithCheck (...params: Parameters<typeof getWorkOrderRecord>) {
  if (isEmpty(_.get(params, '[0].uin'))) {
    return Promise.resolve([{ total: 0, list: [] }, null]) as ReturnType<typeof getWorkOrderRecord>
  } else {
    return getWorkOrderRecord(...params)
  }
}

/**
 * 获取客户联系人信息列表  http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001587809
 * 注意: 不接受分页!!!虽然是分页的数据结构返回，但实际上是全量的
  */
export function getCustomerContactInfoList (filter: ContactInfoFilter) {
  return requestApiV2<CommonList<ContactInfoItem>>('/trpc.tcc.tcc_main.Contact/GetCustomerContactInfoList', filter)
}

// 添加客户联系人信息
export function createCustomerContactInfo (params: ContactInfoItem) {
  return requestApiV2('/trpc.tcc.tcc_main.Contact/CreateCustomerContactInfo', params)
}

// 更新客户联系人信息
export function updateCustomerContactInfo (params: ContactInfoItem) {
  return requestApiV2('/trpc.tcc.tcc_main.Contact/UpdateCustomerContactInfo', params)
}

// 获取客户分配记录 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001588945
// export function getCustomerChangeRecordList (params: ChangeRecordFilter) {
//   return requestApi<CommonList<ChangeRecordItem>>('GetCustomerChangeRecordList', params)
// }

// 获取客户流转日志 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001856941
export function getCustomerFlowRecordList (params: FlowRecordFilter) {
  return requestApiV2<CommonList<ChangeRecordItem>>(getInterfaceName('/trpc.tcc.tcc_main.Customer/GetCustomerFlowRecordList', params), params)
}

// 专属链接绑定记录列表
export function getBindingRecords (params: any) {
  return requestApiV2('trpc.tcc.tcc_main.Binding/GetBindingRecordList', params)
}

// 下载绑定记录列表
export function exportBindingRecordList (params: any) {
  return requestApiV2('trpc.tcc.tcc_main.Binding/ExportBindingRecordList', params)
}

// 获取回流池客户 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001951733
export function getCustomerBackflowList (params: FlowFilter) {
  return requestApiV2<CommonList<CustomerFlowItem>>('/trpc.tcc.tcc_main.Customer/GetCustomerBackflowList', params)
}

/**
 * 导入回流池客户 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001974579
 * 返回仅会返回失败的, 成功的不会返回
 */
export function importCustomerBackflowList (params: ImportCustomerBackflowFilter) {
  return requestApiV2<ImportCustomerBackflowRes>('/trpc.tcc.tcc_main.Customer/ImportCustomerBackflowList', params)
}

// 下载客户分配记录
// export function exportCustomerChangeRecordList (params: ChangeRecordFilter) {
//   return requestApi<CommonList<ChangeRecordItem>>('ExportCustomerChangeRecordList', params)
// }

// 获取tab显示配置
export function getModuleDetailConfig () {
  return requestApiV2<CommonList<ModuleDetailConfigItem>>('/trpc.tcc.tcc_main.Other/GetModuleDetailConfig')
}

// 查询cid下uin列表，以及uin的通路信息。
export function getUinListByCid (params) {
  return requestApiV2('trpc.tcc.tcc_main.Customer/GetUinListByCid', params)
}

// 获取客户列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001592365
export function getCustomerList (params: CustomerFilter) {
  return requestApiV2<CommonList<CustomerItem>>(getInterfaceName('/trpc.tcc.tcc_main.Customer/GetCustomerList', params), params)
}

// 下载客户列表
export function exportCustomerList (params: CustomerFilter) {
  return requestApiV2<CommonList<CustomerItem>>('/trpc.tcc.tcc_main.Customer/ExportCustomerList', params)
}
// 释放客户到公司公海池 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002066025
export function releaseCustomerCompany (params: ReleaseCustomerFilter) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/ReleaseCustomerToVirtualSales', params)
}

// 释放客户 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001593481
export function releaseCustomer (params: ReleaseCustomerFilter) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/ReleaseCustomer', params)
}

// 合并客户
export function mergeCustomerLeadsManually (params: {remainCid: string, beMergedCids: string[], remark: string}) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/MergeCustomerLeadsManually', params)
}

// 转出客户 暂时下线
// export function transferCustomerToOtherModule (params: {filter?: CustomerFilter | { cid: string[] }, moduleId: number, remark?: string}) {
//   return requestApi('TransferCustomerToOtherModule', params)
// }

//  仅转出客户，仅一个标记 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002138731
export function transferCustomer (params: TransferCustomerParam) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/TransferCustomer', params)
}

// CID变更
export function cidChange (params: any) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/FixCidChange', params)
}

// 转出客户到点石 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002124939
export function transferCustomerToDianShi (params: TransferCustomerToDianShiParam) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/TransferCustomerToDianShi', params)
}

// 此接口用于查询二三级uin注册渠道枚举值
export function getUinRegisterChannelEnum () {
  return requestApiV2('trpc.tcc.tcc_main.Customer/GetUinRegisterChannelEnum', {})
}

// 获取磐石一级行业 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002132355
export function getPrimaryIndustryIncomeNoSession () {
  return requestApiV2<PrimaryIndustryIncome[]>('/trpc.tcc.tcc_main.Trade/GetPrimaryIndustryIncome', {})
}

// 获取有效一级行业 http://opc-mock.woa.com/project/530/interface/api/5794
export function getValidPrimaryIndustryIncome () {
  return requestApiV2<PrimaryIndustryIncome[]>('/trpc.tcc.tcc_main.Other/GetValidPrimaryIndustryIncome')
}

// 分配客户 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001601467
export function assignCustomer (params: {
  filter?: CustomerFilter | { cid: string[] }
  salesNames?: string[]
  ifAssignToOfflineSales?: number
  taskName: string // 任务名称
  remark?: string
  notifySales: IsNotifySales // 是否通知座席
  extraFormId?: number
},
) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/AssignCustomer', params)
}

/**
 * 更新客户 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001757231
 */
export function UpdateCustomer (payload: UpdateCustomerFilter) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Customer/UpdateCustomers', payload)
}

/**
 * 获取销售通路枚举值
 */
export function getSalesChannelEnum () {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/GetSalesChannelEnum')
}

// 获取客户转出池列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001592365
export function getCustomerTransferList (params: TransferFilter) {
  return requestApiV2<CommonList<TransferItem>>('/trpc.tcc.tcc_main.Customer/GetCustomerTransferList', params)
}

// 下载客户转出池列表
export function exportCustomerTransferList (params: TransferFilter) {
  return requestApiV2('/trpc.tcc.tcc_main.Customer/ExportCustomerTransferList', params)
}

// 获取客户画像数据
export function getUserPortrait (params: {uin: string}) {
  if (params.uin) {
    return requestApiV2<UserPortrait>('/trpc.tcc.tcc_main.Customer/GetUserPortrait', params)
  } else {
    return new Promise<[UserPortrait, CommonError]>((resolve) => resolve([{ }, null])).then(res => res)
  }
}

// 获取工商信息
export function getCompanyEntInfo (params: {cid: string}) {
  return requestApiV2('trpc.tcc.tcc_main.Customer/GetCompanyEntInfo', params)
}

// 下载客户收入层级
export function getCustomerIncomeLevel (params: {uin: string}) {
  // return requestApi('GetCustomerShouldIncomeLevel', params)
  if (params.uin) {
    return requestApiV2<ICustomerShouldIncomeLevel>('/trpc.tcc.tcc_main.Trade/GetCustomerShouldIncomeLevel', params)
  } else {
    return Promise.resolve([{}, null] as [ICustomerShouldIncomeLevel, CommonError])
  }
}

// 获取优惠券信息
export function getVoucherList (params: VoucherFilter) {
  if (params?.uins?.length) {
    return requestApiV2<CommonList<VoucherItem>>('/trpc.tcc.tcc_main.Voucher/GetVoucherList', params)
  } else {
    return Promise.resolve([{ list: [], total: 0 }, null] as [{ list: any[], total: number }, CommonError])
  }
}

// 申请优惠券
export function createApplicationApproval (params: VoucherCreate) {
  return requestApiV2<{id: number}>('/trpc.tcc.tcc_main.Voucher/CreateApplicationApproval', params)
}

// 申请预付费优惠券
export function createVoucherApplication (params: VoucherCreate) {
  return requestApiV2<{id: number}>('/trpc.tcc.tcc_main.Voucher/CreateVoucherApplication', params)
}

// 获取优惠券产品
export function getAllProducts () {
  return requestApiV2<{ list: VoucherProdItem[] }>('/trpc.tcc.tcc_main.Voucher/GetAllProducts')
}

// 获取优惠券类型
export function getVoucherType () {
  return requestApiV2<CommonList<VoucherTypeItem>>('/trpc.tcc.tcc_main.Voucher/GetVoucherType')
}

// 获取优惠券申请单列表
export function getVoucherApplicationList (params: {uin: number}) {
  return requestApiV2<CommonList<VoucherApplicationItem>>('/trpc.tcc.tcc_main.Voucher/GetVoucherApplicationList', params)
}

// 获取优惠券申请单列表-新
export function getVoucherApplicationV2List (params: {uin: number, action?: number}) {
  return requestApiV2<CommonList<VoucherApplicationItem>>('/trpc.tcc.tcc_main.Voucher/GetVoucherApplicationV2List', params)
}

// 获取优惠券详情
export function getVoucherApplication (params: {applicationId: string}) {
  return requestApiV2<{ application: VoucherApplication }>('/trpc.tcc.tcc_main.Voucher/GetVoucherApplication', params)
}

// 获取规则类型 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001710197
export function getRuleTypes (payload: RuleTypePayload) {
  return requestApiV2<CommonList<IRuleType>>('/trpc.tcc.tcc_main.Rule/GetRuleTypeList', payload)
}

// 添加规则类型 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001714513
export function addRuleType (payload: IAddRuleTypePayload) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Rule/AddRuleType', payload)
}

// 修改规则类型 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001714559
export function editRuleType (payload: IEditRuleTypePayload) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Rule/UpdateRuleType', payload)
}

// 删除规则类型 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001714713
export function deleteRuleType (id: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Rule/DeleteRuleType', { ruleType: id })
}

// 获取短信内容
export function getSmsTemplateContent (payload: SmsTemplateContentPayload) {
  return requestApiV2('/trpc.tcc.tcc_main.Sms/GetSmsTemplateContent', payload)
}

/**
 * 获取客户的注册信息 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001751827
 * 如果uin查不到信息，那么registerInfo和accountInfo的值是个空数组哈，所以格式应该是是这样的 registerInfo :IRegisterInfo | []
 * @param payload
 */
export function getCustomerRegisterInfoByUin (payload: { uin: string }) {
  return requestApiV2<{ majorSalesType: number, registerInfo: IRegisterInfo, accountInfo: IaccountInfo}>('/trpc.tcc.tcc_main.Account/GetCustomerRegisterInfoByUin', payload)
}

// 获取uin账号 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001927389
export function getAccountInfo (payload: { uin: string }) {
  return requestApiV2<UinBaseInfoType>('/trpc.tcc.tcc_main.Account/GetAccountInfo', payload)
}

// 获取解绑代客列表 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002033151
export function getSmsReplyAutoBoundList (payload: SmsReplyAutoBoundListPayload) {
  return requestApiV2<CommonList<SmsReplyAutoBoundItem>>('/trpc.tcc.tcc_main.Sms/GetSmsReplyAutoBoundList', payload)
}

// 获取渠道指派失败日志 http://opc-mock.woa.com/project/530/interface/api/3344
export function getBindFailureList (payload: BindFailureRecordPayload) {
  return requestApiV2<CommonList<BindFailureRecordItem>>(getInterfaceName('/trpc.tcc.tcc_main.Lead/GetChannelBindFailureRecord', payload), payload)
}

// 短信回复手动解绑代理商 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002033043
export function smsReplyManualBound (payload: {id: number}) {
  return requestApiV2('/trpc.tcc.tcc_main.Sms/SmsReplyManualBound', payload)
}

// 获取手机号码随机串 用于加客户微信 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002125683
export function getPhoneHashCode (payload: { contactId: number }) {
  return requestApiV2<{phoneHashCode: string}>('/trpc.tcc.tcc_main.Contact/GetPhoneHashCode', payload)
}

// 获取明微信号 http://opc-mock.oa.com/project/530/interface/api/2948
export function getPlaintextWechat (payload: { contactId: number }) {
  return requestApiV2<{wechat: string}>('/trpc.tcc.tcc_main.Contact/GetPlaintextWechat', payload)
}

// 获取明文QQ号 http://opc-mock.oa.com/project/530/interface/api/2944
export function getPlaintextQQ (payload: { contactId: number }) {
  return requestApiV2<{qq: string}>('/trpc.tcc.tcc_main.Contact/GetPlaintextQq', payload)
}

// 获取明文邮箱 http://opc-mock.oa.com/project/530/interface/api/2950
export function getPlaintextEmail (payload: { contactId: number }) {
  return requestApiV2<{email: string}>('/trpc.tcc.tcc_main.Contact/GetPlaintextEmail', payload)
}

// 获取明文电话号码 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412002102293
// 线索列表传leadId，联系人和推荐联系人传contactId
export function getPhoneNumber (params: LeadOrCustomer) {
  return requestApiV2<{phone: string}>('/trpc.tcc.tcc_main.Contact/GetPhoneNumber', params)
}

// 模糊查询客户名称
export function getCustomerNameList (params: CustomerNameFilter) {
  return requestApiV2<CommonList<ICustomerName>>(getInterfaceName('/trpc.tcc.tcc_main.Customer/GetCustomerNameList', params), params)
}

// 获取标签
export function getCustomerPortraitTagInfo (params) {
  return requestApiV2('trpc.tcc.tcc_main.Customer/GetCustomerPortraitTagInfo', params)
}

// 获取磐石全二级行业 https://opc-mock.woa.com/project/530/interface/api/3398
export function getIndustryIncome () {
  return requestApiV2<SecondIndustryItem[]>('/trpc.tcc.tcc_main.Trade/GetIndustryIncome', {})
}

// 查询工单评论 http://opc-mock.woa.com/project/530/interface/api/4957
export function getCustomerTicketComment (ticketId: string) {
  return requestApiV2<{ list: CustomerTicketComment[] }>('/trpc.tcc.tcc_main.Customer/GetCustomerTicketComment', { ticketId })
}

// 新增工单评论 http://opc-mock.woa.com/project/530/interface/api/4948
export function addCustomerTicketComment (params: CustomerTicketCommentParams) {
  return requestApiV2<{commentId: number}>('/trpc.tcc.tcc_main.Customer/AddCustomerTicketComment', params)
}

// 获取客户流转日志 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001856941
export function getIntentionChangeRecord (params: IntentionChangeFilter) {
  return requestApiV2<IntentionChangeItem[]>('/trpc.tcc.tcc_main.Customer/GetIntentionChangeRecord', params)
}

// 获取销售机会类型 http://opc-mock.woa.com/project/530/interface/api/5902
export function getOpportunityType () {
  return requestApiV2<OpportunityTypeItem[]>('/trpc.tcc.tcc_main.Opportunity/GetOpportunityType')
}

// 获取销售机会进行中阶段 http://opc-mock.woa.com/project/530/interface/api/5911
export function getOpportunityStageInProgress () {
  return requestApiV2<StageInProgressTypeItem[]>('/trpc.tcc.tcc_main.Opportunity/GetOpportunityStageInProgress')
}

// 获取批次背景及目的 https://opc-mock.woa.com/project/530/interface/api/6055
export function getBatchBackgroundAndGoalById (batchId: number) {
  return requestApiV2<{backgroundAndGoal: string}>('/trpc.tcc.tcc_main.Batch/GetBatchBackgroundAndGoalById', { batchId })
}

// 查询专属链接绑定记录 https://opc-mock.woa.com/project/530/interface/api/7024
export function getBindingRecordList (cid: string) {
  return requestApiV2('trpc.tcc.tcc_main.Binding/GetBindingRecord', { cid })
}

// 创建专属链接 https://opc-mock.woa.com/project/530/interface/api/7020
export function createBindingURL (params: { originURL: string; cid: string, type?:number }) {
  return requestApiV2('trpc.tcc.tcc_main.Binding/CreateBindingURL', params)
}

export function getAccountList (params: { uin: string[] }) {
  return requestApiV2<{ list: { authState: number }[] }>('/trpc.tcc.tcc_main.Account/GetAccountList', params)
}

export function querySpuCodeList (params) {
  return requestApiV2('trpc.tcc.tcc_main.BillingSelection/QuerySpuCodeList', params)
}

export function querySelectionList (params) {
  return requestApiV2('trpc.tcc.tcc_main.BillingSelection/QuerySelectionList', params)
}

export function querySelectionListByUin (params) {
  return requestApiV2('trpc.tcc.tcc_main.BillingSelection/QuerySelectionListByUin', params)
}

// 本接口用于上报营销话术使用情况
export function reportMarketingWordsUsage (params) {
  return requestApiV2('trpc.tcc.tcc_main.AIBot/ReportMarketingWordsUsage', params)
}

export function cancelSelection (params) {
  return requestApiV2('trpc.tcc.tcc_main.BillingSelection/CancelSelection', params)
}

export function getWeChatAppletLink () {
  return requestApiV2('trpc.tcc.tcc_main.Binding/GetWeChatAppletLink')
}

// 回流客户
export function manualBackFlow (params) {
  return requestApiV2('trpc.tcc.tcc_main.Customer/ManualBackFlow', params)
}

// 查询订单汇总列表 https://iwiki.woa.com/p/4013067474
export function GetOrderCollectReport (params) {
  return requestApiV2('trpc.tcc.tcc_main.OrderReport/GetOrderCollectReport', params)
}

// 查询订单明细列表 https://iwiki.woa.com/p/4013067556
export function GetOrderDetailReport (params) {
  return requestApiV2('trpc.tcc.tcc_main.OrderReport/GetOrderDetailReport', params)
}

// 查询订单汇总排行榜列表 https://iwiki.woa.com/p/4013067821
export function GetOrderCollectRankReport (params) {
  return requestApiV2('trpc.tcc.tcc_main.OrderReport/GetOrderCollectRankReport', params)
}
