/**
 * 线索相关
 * @author tylerzzheng
 */
import { IOrgGroup, IStaff } from './organazition'
import { BlackListTypeEnum } from './customer'
import { PublicTags, PrivateTags, Pagination, ISortOrder, AsyncTotal } from './common'

/**
 * 客户来源标签对象 一级和二级
 */
interface SourceSecondTag {
  sourceId: number,
  sourceName: string,
}
export interface SourceTag {
  sourceId: number,
  sourceName: string,
  subSource: SourceSecondTag[],
}
/**
 * 触达状态过滤条件
 */
export interface TouchStatusFilter {
  intentionId: number
}
/**
 * 触达状态对象
 */
export interface TouchStatus {
  key: number,
  value: string,
}

/**
 * 线索意向等级
 */
export interface LeadIntentionLevel {
  code: string
  name: string
  child: {
    code: string
    name: string
  }[]
}

export interface GetAddLeadCustomerFollower {
  follower: IStaff,
  isFollowed: IsFollowedEnum,
}

export enum IsFollowedEnum {
  YES = 1, // 有跟进人
  NO = 0, // 无跟进人
}

/**
 * 添加线索上送对象
 */
export interface NewLead {
  contributor: string // 线索贡献人
  name: string // 客户名称
  smallProduct?: string // 关注产品一级
  subProduct?: string // 关注产品二级
  uin: string // uin
  phone?: string // 电话
  intentionLevel?: string // 意向等级一级
  intentionSecondLevel?: string // 意向等级二级
  leadTags?: string [] // 线索标签
  primaryIndustryIncomeName?: string // 磐石一级行业名称
  provinceCode?: string // 省份code
  cityCode?: string // 市code
  mainBusiness?: string // 主营业务
  companySize?: string // 公司规模
  customerDemand?: string // 客户需求
  selectionReason?: string // 入选原因
  email?: string // 邮箱
  wechat?: string // 微信
  job?: string // 职位
  contactName?: string // 联系人
  assignFollower?: string // 指定跟进人
  // extend?: Array<ExtendItem>
  customTrackId?: string //  自定义标识ID
}

/* 线索的两种查询方式，默认通过es，优点是速度快，缺点是会缓存。 如果是更新操作之后立即查询， 需要传after-update */
export enum LeadPoolQueryTypeEnum {
  DEFAULT = 'default',
  AFTER_UPDATE = 'after-update',
}

/**
 * 线索池过滤对象
 */
export interface LeadPoolFilter extends Pagination, ISortOrder, AsyncTotal {
  queryType?: LeadPoolQueryTypeEnum // 线索的两种查询方式，默认通过es，优点是速度快，缺点是会缓存。 如果是更新操作之后立即查询， 需要传after-update
  status?: LeadStatusEnum[] | LeadStatusEnum
  leadId?: number // 线索ID
  leadName?: string // 线索名称 注意和客户名称区分。线索名称是新增线索时候，带的客户名称。客户名称是真实查询的磐石的客户名称
  name?: string // 客户名称
  customerId?: number // 客户ID
  touchStatus?: number[] // 触达状态
  intentionId?: number[] // 一级客户意向ID
  subIntentionId?: number[] // 二级客户意向ID
  ruleType?: number // 任务类型
  sourcePrimaryTagId?: number // 一级客户来源
  sourceSecondaryTagId?: number // 二级客户来源
  groupId?: number // 所属业务组
  follower?: string | string[] // 当前跟进人 当值为空的时候(空字符串"",或数组里包含一个字符串[""])表示筛选没有跟进人的线索
  leadBeginTime?: string // 线索创建开始时间（格式为2020-04-17 16:46:45）
  leadEndTime?: string // 线索创建结束时间（格式为2020-04-17 16:46:45）
  publicTagId?: number | number[] // 公共标签
  privateTagId?: number | number[] // 个人标签
  leadAssignTimeFrom?: string // 线索变更时间开始时间（格式为2020-04-17 16:46:45）
  leadAssignTime?: string // 线索变更时间时间（格式为2020-04-17 16:46:45）
  abandonBeginTime?: string // 废弃开始时间（格式为2020-04-17 16:46:45）
  abandonEndTime?: string // 废弃结束时间（格式为2020-04-17 16:46:45）
  regTimeFrom?: string // 注册时间范围开始（格式为2020-04-17 16:46:45）
  regTimeTo?: string // 注册时间范围结束（格式为2020-04-17 16:46:45）
  authTimeFrom?: string // 认证时间范围开始（格式为2020-04-17 16:46:45）
  authTimeTo?: string // 认证时间范围结束（格式为2020-04-17 16:46:45）
  qq?: string // qq
  phone?: string // 电话
  uin?: string | string[] // uin
  extend?: string // 其它信息
  batchId?: number | number[] // 批次ID
  payType?: PayTypeEnum | PayTypeEnum[] // 付费类型1=未付费未使用2=未付费已使用3=已付费
  // intentionLevel?: IntentionLevelEnum // 意向度H=高M=中L=低
  intentionLevel?: string // 意向等级一级
  intentionSecondLevel?: string // 意向等级二级
  smallProduct?: string // 关注产品一级
  subProduct?: string // 关注产品二级
  leadTags?: string[] // 是 string 线索标签
  sort?: 'leadCreateTime' | 'leadAssignTime'
  leadStatus?: LeadStatusEnum // 线索状态
  exceedSla?: LeadExceedSlaEnum // int 否 是否超SLA，1-是，0-否
  exceedPeriod?: LeadExceedPeriodEnum // Int 否 是否失效，1-是，0-否
  firstAssignTimeFrom?: string // 首次分配开始时间
  firstAssignTimeTo?: string // 首次分配结束时间
  firstAssigner?: string // 首次分配人
  firstFollowTimeFrom?: string // 首次跟进开始时间
  firstFollowTimeTo?: string // 首次跟进结束时间
  firstFollower?: string // 首次跟进人
  leadReleaseSourceId?: string // 来源方线索ID
  provinceCode?: string, // 省市,
  cityCode?: string, // 省市
  leadIds?: number[] // 线索ID
}

/**
 * 更新线索筛选项的上送参数
 */
export interface UpdateLeadsFilter {
  filter: Omit<LeadPoolFilter, keyof (Pagination & ISortOrder)> & {
    leadIds?: number[]
  }
  updateResult: {
    status?: number
    updateLeadPublicTags?: {
      publicTagIds?: number[] // 公共标签id
    }
    updateLeadPrivateTags?: {
      privateTagIds?: number[] // 个人标签id
    }
  }
}

export enum AssignToOfflineSales {
  NO,
  YES

}
/**
 * 手动分配线索筛选项的上送参数
 */
export interface AssignLeadsFilter {
  filter: Omit<LeadPoolFilter, keyof (Pagination & ISortOrder)> & {
    leadIds?: number[]
  }
  taskName: string, // 任务名称
  salesNames: string[]
  ifAssignToOfflineSales: AssignToOfflineSales
  assignRemark?: string
  extraFormId?: number
}

/**
 * @deprecated 已废弃
 * 手回收线索的上送参数
 */
export interface ReuseLeadFilter {
  salesNames: string[] // 销售名，为空数组时回收到公海池，每个销售名不能为空
  leadIds: number[] // 待回收线索ID数组
  ifAssignToOfflineSales?: AssignToOfflineSales // 是否派发给离线销售0否1是，回收至指定人时必选
  taskName?: string, // 任务名称 // salesNames为空时没有任务名称（即回收到公海池）
}

export interface Extend {
  key: string,
  keyValue: string,
  keyName: string,
}

/**
 * 线索池对象
 */
export interface ILeadPoolItem {
  leadId: number,
  leadExtend: Extend[],
  taskCreateTime: string,
  authTime: string, // 认证时间
  regTime: string, // 注册时间
  customerId: string,
  name: string, // 客户名称 注意和leadName区分开
  leadName: string, // 线索名称
  uin: string,
  sourcePrimaryTagId: number,
  sourcePrimaryTagName: string,
  sourceSecondaryTagId: number,
  sourceSecondaryTagName: string,
  publicTags: PublicTags[]
  privateTags: PrivateTags[]
  leadStatus: LeadStatusEnum,
  touchStatus: number,
  follower: string,
  intentionId: number,
  intentionName: string,
  subIntentionId: number,
  subIntentionName: string,
  position: string, // 职业 行业 职位 （接口文档，页码，原型图的各种叫法）
  provinceCode: string,
  cityCode: string,
  groups: IOrgGroup[]
  leadCreateTime: string,
  lastFollowCreateTime: string,
  leadAssignTime: string,
  phone: string
  blackListType: BlackListTypeEnum // 是否在黑名单中
  wechat: string
  email: string
  qq: string
  cid: string
  ruleId: number // 规则ID  // 为0的时候任务不是自动分配的，是手动分配
  taskName: string // 任务类型 当手动分配的时候会有此项
  ruleName: string // 规则名
  // ruleType: string // 规则类型ID
  ruleTypeName: string // 规则类型名
  batchId: number // 批次id
  batchName: string // 批次名称
  extraFormId: number
  payType: PayTypeEnum // 付费类型1=未付费未使用2=未付费已使用3=已付费
  // intentionLevel: IntentionLevelEnum // 意向度H=高M=中L=低
  customerType: number// 客户类型
  authState: number // 认证状态
  leadReleaseSourceId: string // 来源方线索ID
  firstAssignTime: string // 首次分配时间
  firstAssigner: string // 首次分配人
  firstFollowTime: string // 首次跟进时间
  firstFollower: string// 首次跟进人
  leadTagNames: string[] // 线索标签
  subProductName: string // 行业产品子类名称
  smallProductName: string // 行业产品细类名称
  intentionLevelName: string // 意向等级一级
  intentionSecondLevelName: string // 意向等级二级
  primaryIndustryIncomeId: number // 行业
  primaryIndustryIncomeName: string //
  exceedSla: LeadExceedSlaEnum // int 否 是否超SLA，1-是，0-否
  exceedPeriod: LeadExceedPeriodEnum // Int 否 是否失效，1-是，0-否
  leadSlaTime: string // timestamp 否 线索sla时间
  leadPeriodTime: string // timestamp 否 线索过期时间
}

// 线索状态枚举、映射
export enum LeadStatusEnum {
  UNHANDLE = 0,
  ASSIGNED,
  DISCARDED,
  FOLLOWED,
}

// 线索是否超SLA，1-是，0-否
export enum LeadExceedSlaEnum {
  NO = 0,
  YES = 1,
}

// 线索是否失效，1-是，0-否
export enum LeadExceedPeriodEnum {
  NO = 0,
  YES = 1,
}

// 付费类型枚举
export enum PayTypeEnum {
  // 1=未付费未使用
  UNPAID_UNUSED = 1,
  // 2=未付费已使用
  UNPAID_USED = 2,
  // 3=已付费
  PAID = 3,
}

// 意向度枚举
// export enum IntentionLevelEnum {
//   // 高
//   H = 'H',
//   // 中
//   M = 'M',
//   // 低
//   L = 'L'
// }

/**
 * @deprecated 已废弃
 */
export interface AddLeadBatchFilter {
  leads?: AddLeadBatch[]
}

/**
 * @deprecated 已废弃
 */
export interface FailLeadData {
  uin: string// "353553464674334",
  name: string// "测试分配线索公司29",
  code: number// 603014,
  index: number// 行数,
  message: string// "通过uin查找客户信息为空"
}

/**
 * 批量上传线索扩展参数字段
 * @deprecated 已废弃
 */
export interface ExtendType {
  key: string
  keyName: string
  keyValue: string
}

/**
 * 批量上传线索参数
 * @deprecated 已废弃
 */
export interface AddLeadBatch {
  contributor: string
  sourcePrimaryTagId: string
  sourceSecondaryTagId: string
  qq?: string
  phone?: string
  name?: string
  email?: string
  publicTagIds?: number[]
  privateTagIds?: number[]
  extend?: ExtendType[]
  intentionId: string
  touchStatus?: string
  wechat?: string
}

/* 分配方式 */
export enum AssignType {
  ALL = 'all', // 全部座席
  CERTAIN = 'certain', // 指定座席
}

/**
 * 线索标签
 */
export interface LeadTagsItem {
  id: number,
  tagCode: string,
  tagName: string,
  createtime: string,
  lastUpdateTime: string,
  creator: string,
  updator: string,
}
