import React, { FC, useCallback, useState } from 'react'
import { GoalParam } from '@types'
import { setGoal } from '@services/task'
import { InputNumber, message, Spin } from 'antd'
import { handleNumber, isFunction, notEmptyArray } from '@library'
import { EditableText } from '@components'
import { GoalNode } from '@pages/task/goal/index'
import './style.less'

interface GoalElementProp {
  goalNode: GoalNode
  canEdit?: boolean
  afterUpdateGoal: (goalNode: GoalNode) => void
}

const GoalElement: FC<GoalElementProp> = (props) => {
  const { goalNode, afterUpdateGoal, canEdit } = props

  const [loading, setLoading] = useState(false)
  const updateGoal = useCallback(async (personalCustomerGoal?: number, enterpriseCustomerGoal?: number) => {
    setLoading(true)
    const pcg = handleNumber(personalCustomerGoal)
    const ecg = handleNumber(enterpriseCustomerGoal)
    const param: GoalParam = {
      relatedId: goalNode.relatedId,
      relatedType: goalNode.relatedType,
      belongMonth: goalNode.belongMonth,
      personalCustomerGoal: pcg,
      enterpriseCustomerGoal: ecg,
    }
    const [, err] = await setGoal(param)
    setLoading(false)
    if (err) {
      message.error(`设置目标失败: ${err?.message}`)
      return
    }
    const newGoalNode = { ...goalNode, personalCustomerGoal: pcg ?? goalNode.personalCustomerGoal, enterpriseCustomerGoal: ecg ?? goalNode.enterpriseCustomerGoal }
    isFunction(afterUpdateGoal) && afterUpdateGoal(newGoalNode)
  }, [goalNode])

  return (
    <Spin spinning={loading}>
      <span className="goal-label mr10">{goalNode.name}</span>
      {!goalNode.simpleMode && (
        <>
          <span className="mr5">个人客户目标:</span>
          <EditableText
            text={(goalNode.personalCustomerGoal || 0).toLocaleString()}
            value={goalNode.personalCustomerGoal || 0}
            onSubmit={val => updateGoal(val)}
            readonly={!canEdit}
          >
            <InputNumber min={0} />
          </EditableText>
          {notEmptyArray(goalNode.children) && <span className="ml20">已拆分: {(_.sumBy(goalNode.children, 'personalCustomerGoal') || 0).toLocaleString()}</span>}
          <span className="ml20 mr5">企业客户目标:</span>
          <EditableText
            text={(goalNode.enterpriseCustomerGoal || 0).toLocaleString()}
            value={goalNode.enterpriseCustomerGoal || 0}
            onSubmit={val => updateGoal(undefined, val)}
            readonly={!canEdit}
          >
            <InputNumber min={0} />
          </EditableText>
          {notEmptyArray(goalNode.children) && <span className="ml20">已拆分: {(_.sumBy(goalNode.children, 'enterpriseCustomerGoal') || 0).toLocaleString()}</span>}
        </>
      )}
    </Spin>
  )
}
GoalElement.defaultProps = {
  canEdit: true,
  goalNode: {} as GoalNode,
  afterUpdateGoal: () => {},
}
export default GoalElement
