import { CommonError, LeadOrCustomer } from '@types'
import React, { useCallback, useEffect, useState } from 'react'
import { message, Popover, Spin, Tooltip } from 'antd'

interface HiddenTextProps<F> {
  name: string // popover的高亮信息
  keyWord: string // res中返回的关键字段
  filter: F
  service: (filter: F) => Promise<[null, CommonError] | [object, null]>
}

const HiddenText: React.FC<HiddenTextProps<LeadOrCustomer>> = React.memo((props) => {
  const { filter, service, keyWord, name, children } = props

  const [plaintext, setPlaintext] = useState(undefined)
  const [loading, setLoading] = useState(false)

  const onOpenChange = useCallback(async (open: boolean) => {
    if (open && !plaintext) {
      setLoading(true)
      const [res, err] = await service(filter)
      if (err) {
        message.error(`获取${name}失败: ${err.message}`)
        setLoading(false)
        return
      }
      setLoading(false)
      setPlaintext(res?.[keyWord])
    }
  }, [filter, plaintext])

  // 当children变化的时候去除缓存
  useEffect(() => setPlaintext(undefined), [children])

  return (
    <Popover
      placement="top"
      trigger="click"
      onOpenChange={onOpenChange}
      content={<Spin spinning={loading}>{plaintext}</Spin>}
    >
      <Tooltip title={`查看${name}`} placement="bottom">
        <span style={{ cursor: 'pointer', color: 'dodgerblue' }}>{children}</span>
      </Tooltip>
    </Popover>
  )
})

HiddenText.defaultProps = {
  service: () => Promise.resolve([null, null]),
  keyWord: '',
}

export default HiddenText
