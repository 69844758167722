/**
 * 线索分配规则接口
 * @author wzwwu
 */
import { requestApiV2 } from '@library'
import {
  CommonList,
  RuleListFilter,
  RuleListItem,
  RuleNameList,
  RuleNameListFilter,
  RulePayloadAdd,
  RulePayloadUpdate,
} from '@types'

// 获取规则列表
export function getRuleList (filter: RuleListFilter) {
  return requestApiV2<CommonList<RuleListItem>>('/trpc.tcc.tcc_main.Rule/GetRuleList', filter)
}

// 获取规则名称列表 是getRuleList的简版 http://tapd.oa.com/TC_CSIG_OM/markdown_wikis/show/#1220403412001831727
export function getRuleNameList (filter: RuleNameListFilter) {
  return requestApiV2<CommonList<RuleNameList>>('/trpc.tcc.tcc_main.Rule/GetRuleNameList', filter)
}

// 增加规则
export function addRule (payload: RulePayloadAdd) {
  return requestApiV2<{ ruleId: number }>('/trpc.tcc.tcc_main.Rule/AddRule', payload)
}

// 修改规则
export function updateRule (payload: RulePayloadUpdate) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Rule/UpdateRule', payload)
}

// 删除规则
export function deleteRule (ruleId: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Rule/DeleteRule', { ruleId })
}

// 删除规则
export function clearRuleAssignLimit (ruleId: number) {
  return requestApiV2<{}>('/trpc.tcc.tcc_main.Rule/ClearRuleAssignLimit', { ruleId })
}
