import React, { FunctionComponent, useMemo } from 'react'
import { Card, Table } from 'antd'
// import { TableProps } from 'antd/lib/table'
import { CommonFilters, PageHeader, UserAvatar, TableDownload } from '@components'
import { TableColumnProps } from '@hooks'
import { ChangeRecordItem, FlowRecordFilter } from '@types' // needTotalEnum
// import { flowTypeMap } from '@configs/map'
import { getBindingRecords, exportBindingRecordList } from '@services/customer'
import { getTableXwidth } from '@configs/table'
// import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { linkUrlFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { Link } from 'react-router-dom'
import { trimStr } from '@library'

const initFilter: FlowRecordFilter = {
  page: 1,
  pageSize: 10,
  // needTotal: needTotalEnum.LIST,
}

const LinkLogPgae: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getBindingRecords, initFilter, '获取客户变更记录失败')

  const custColumns: TableColumnProps<ChangeRecordItem>[] = useMemo(() => [
    { title: '关联时间', dataIndex: 'beginTime', width: 150 },
    { title: '触发关联的UIN', dataIndex: 'uin', width: 140 },
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 220,
      render: (customerName, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(customerName, '-')}
        </Link>),
    },
    { title: 'cid', dataIndex: 'cid', width: 170 },
    { title: '发起坐席', dataIndex: 'originSales', width: 180, render: value => <UserAvatar salesName={value} /> },
    { title: '关联坐席', dataIndex: 'relateSales', width: 180, render: value => <UserAvatar salesName={value} /> },
    { title: '关联状态', dataIndex: 'status', width: 120, render: v => String(v) === '0' ? '已失效' : '生效中' },
    { title: '到期时间', dataIndex: 'endTime', width: 150 },
  ], [filter.sort, filter.sortType])
  // const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.CUSTOMER_ASSIGN, custColumns, ['customerName'])
  const tableXWidth = useMemo(() => getTableXwidth(custColumns), [custColumns])

  /* 列表选择相关 */
  return (
    <>
      <PageHeader title="专属链接关联记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={linkUrlFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
          standstill={9}
          colProps={{ flex: '1 1 300px' }}
        />
      </Card>
      <Card>
        <TableDownload className="ml20" style={{ marginBottom: 10 }} exportMethod={exportBindingRecordList} filter={filter} />{/** 下载接口未开发 */}
        <Table
          tableLayout="fixed"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={custColumns}
        />
      </Card>
    </>
  )
}
export default LinkLogPgae
