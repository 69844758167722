/**
 * 判断两个数组的内容是否相等，可以顺序不同
 * @author tylerzzheng
 */
export const isEqualArray = function (arr1: Array<number|string|boolean>, arr2: Array<number|string|boolean>): boolean {
  if (_.isEmpty(arr1) || _.isEmpty(arr2)) return _.isEmpty(arr1) && _.isEmpty(arr2)

  let flag = true
  if (arr1.length !== arr2.length) {
    flag = false
  } else {
    arr1.forEach(item => {
      if (arr2.indexOf(item) === -1) {
        flag = false
      }
    })
  }
  return flag
}
