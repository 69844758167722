/**
 * 404 页面
 * @author jasonelchen
 */
import React, { FunctionComponent } from 'react'
import { useTitle } from '@hooks'
import './style'

export const NotFoundPage: FunctionComponent<{}> = () => {
  useTitle('404')

  return (
    <>
      <img className="warning-image" src="https://cloudcache.tencent-cloud.com/open_proj/proj_qcloud_v2/mc_2014/break/css/errorpage/img/404.png" />
      <h2>该页面暂时无法访问，可能由于网址已删除或没有权限</h2>
      <p className="small-tip">
        您可以前往：
        <a className="helper-link" href="/">首页</a>
      </p>
    </>
  )
}

export default NotFoundPage
