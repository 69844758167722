/**
 * sentry相关
 * 指引见： https://km.woa.com/articles/show/465615?kmref=search&from_page=1&no=1
 * 相关api文档见: https://getsentry.github.io/sentry-javascript/modules/react.html
 * @author tylerzzheng
 */
import { captureException as sentryCaptureException, configureScope as sentryConfigureScope } from '@sentry/browser'
// import { sentryDSN } from '@configs'
import { CaptureContext } from '@sentry/types'
import { UserInfo } from '@types'
import { isProd } from './getEnv'

export function initSentry (): void{
  if (isProd) {
    // init({
    //   dsn: sentryDSN,
    //   ignoreErrors: [
    //     // 去除 antd的ResizeObserver loop limit exceeded错误上报，该上报可忽略。issue见：https://github.com/ant-design/ant-design/issues/26621
    //     'ResizeObserver loop limit exceeded',
    //     'ResizeObserver loop completed with undelivered notifications.',
    //     '对象不支持“contains”属性或方法',
    //     'Object doesn\'t support property or method \'contains\'',
    //   ],
    // })
    console.info('[SENTRY] sentry initiated.')
  } else {
    console.info('[SENTRY] sentry was disabled due to NOT at production mode.')
  }
}

export function captureException (error: any, ctx?: CaptureContext): string {
  if (isProd) {
    console.info('[SENTRY] exception captured.')
    return sentryCaptureException(error, ctx)
  }
}

export function configureScope (userInfo: UserInfo): void {
  if (isProd) {
    sentryConfigureScope(scope => {
      scope.setExtras({
        belongModule: userInfo?.belongModule,
        companyId: userInfo?.companyId,
      })
      scope.setUser({
        username: userInfo?.salesName,
        id: userInfo?.id + '',
      })
      // scope.clear();
    })
  }
}
