/**
 * 解绑客户
 * @author ruikangmao
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Button, Card, message, Popconfirm, Table } from 'antd'
import { CommonFilters, PageHeader } from '@components'
import { BoundStatusEnum, FlowFilter, SmsReplyAutoBoundItem, SmsReplyAutoBoundListPayload } from '@types'
import { TableProps } from 'antd/lib/table'
import { getSortOrder, getTableXwidth } from '@configs/table'
import { unbindCustomerFilterItems } from '../../../components/CommonFilters/configs'
import { getSmsReplyAutoBoundList, smsReplyManualBound } from '@services/customer'
import { antdSortMap, clientTypeMap, unboundStatusMap, unboundTypeMap } from '@configs/map'
import { Link } from 'react-router-dom'
import { useTableService } from '@hooks/useTableService'
import { trimStr } from '../../../library/utils'
import { TableColumnProps } from '@hooks'

const initFilter: SmsReplyAutoBoundListPayload = {
  page: 1,
  pageSize: 10,
  sort: 'replyTime',
  sortType: 'DESC',
}

const CustomerTransTempPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
    reload,
  } = useTableService(getSmsReplyAutoBoundList, initFilter, '获取解绑代客列表失败')

  const onTableChange: TableProps<SmsReplyAutoBoundItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<FlowFilter>(sorter)
    setFilter(sort)
  }

  const renderOperation = (text, record) => {
    return (
      <Popconfirm className="ml10" title="确认解绑吗？" disabled={record.status !== BoundStatusEnum.WAIT} onConfirm={() => unbind(record)}>
        <Button type="link" disabled={record.status !== BoundStatusEnum.WAIT}>解绑</Button>
      </Popconfirm>
    )
  }

  const unbind = async customer => {
    const [res, err] = await smsReplyManualBound({ id: customer.id })
    if (res) {
      message.success('解绑成功')
    } else if (err) {
      message.error(err.message || '解绑失败')
    }
    reload()
  }

  const columns: TableColumnProps<SmsReplyAutoBoundItem>[] = useMemo(() => [
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 180,
      render: (customerName, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(customerName, '-')}
        </Link>),
    },
    { title: 'UIN', dataIndex: 'uin', width: 150 },
    { title: '代理商名称', dataIndex: 'agentName', width: 160 },
    { title: '代理商UIN', dataIndex: 'agentId', width: 150 },
    { title: '代客类型', dataIndex: 'clientType', width: 120, render: value => clientTypeMap[value] || '-' },
    {
      title: '回复时间',
      dataIndex: 'replyTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'replyTime' ? antdSortMap[filter.sortType] : undefined,
    },
    { title: '回复内容', dataIndex: 'replyContent', width: 180 },
    { title: '解绑状态', dataIndex: 'status', width: 120, render: (text) => unboundStatusMap[text] },
    { title: '解绑方式', dataIndex: 'unboundType', width: 120, render: value => unboundTypeMap[value] || '-' },
    { title: '备注', dataIndex: 'remark', width: 180 },
    { title: '操作', dataIndex: 'action', width: 120, render: (text, record) => renderOperation(text, record) },
  ], [filter.sort, filter.sortType])

  const tableXWidth = useMemo(() => getTableXwidth(columns), [columns])

  return (
    <>
      <PageHeader title="解绑客户记录" />
      <Card className="mt20">
        <CommonFilters
          className="custom-Label"
          itemList={unbindCustomerFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <Table
          className="mt20"
          loading={loading}
          columns={columns}
          rowKey={rowKey}
          dataSource={list}
          pagination={pagination}
          onChange={onTableChange}
          scroll={{ x: tableXWidth }}
        />
      </Card>
    </>
  )
}
export default CustomerTransTempPage
