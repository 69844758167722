/**
 *  外呼机器人相关接口
 */

import { requestApiV2 } from '@library'
import {
  RobotItem, RobotTaskItem, Pagination, CommonList, CreateRobotCallRollParams, ListCallRollTitleFilter, ListCallRollTitleItem,
  ListRobotCallRollFilter, ListRobotCallRollItem, ListCallTagFilter, ListRobotCallRecordFilter, RobotCallRecordItem, ActivityListWithMarketingWordsRecordItem,
} from '@types'

// 机器人列表 https://opc-mock.woa.com/project/562/interface/api/5965
export function getListRobot (params: Pagination) {
  return requestApiV2<CommonList<RobotItem>>('trpc.tcc.tcc_main.Robot/ListRobot', params)
}

// 创建机器人名单 https://opc-mock.woa.com/project/562/interface/api/5938
export function createRobotCallRoll (params: CreateRobotCallRollParams) {
  return requestApiV2<{rollId: number}>('trpc.tcc.tcc_main.Robot/CreateRobotCallRoll', params)
}

// 机器人名单名称列表 https://opc-mock.woa.com/project/562/interface/api/5956
export function listCallRollTitle (params: ListCallRollTitleFilter) {
  return requestApiV2<ListCallRollTitleItem[]>('trpc.tcc.tcc_main.Robot/ListCallRollTitle', params)
}

// 机器人名单 https://opc-mock.woa.com/project/562/interface/api/5983
export function listRobotCallRoll (params: ListRobotCallRollFilter) {
  return requestApiV2<ListRobotCallRollItem[]>('trpc.tcc.tcc_main.Robot/ListRobotCallRoll', params)
}

// 机器人任务列表 http://opc-mock.woa.com/project/562/interface/api/6315
export function getListRobotTask (params: Pagination) {
  return requestApiV2<CommonList<RobotTaskItem>>('trpc.tcc.tcc_main.Robot/ListRobotTask', params)
}

// 机器人外呼通话标签列表 https://opc-mock.woa.com/project/562/interface/api/5947
export function listCallTag (params: ListCallTagFilter) {
  return requestApiV2<string[]>('trpc.tcc.tcc_main.Robot/ListCallTag', params)
}

// 机器人外呼通话记录列表 https://opc-mock.woa.com/project/562/interface/api/5974
export function listRobotCallRecord (params: ListRobotCallRecordFilter) {
  return requestApiV2<CommonList<RobotCallRecordItem>>('trpc.tcc.tcc_main.Robot/ListRobotCallRecord', params)
}

// 下载机器人外呼记录 http://opc-mock.woa.com/project/562/interface/api/6127
export function exportRobotCallRecordList (params: ListRobotCallRecordFilter) {
  return requestApiV2<CommonList<RobotCallRecordItem>>('trpc.tcc.tcc_main.Robot/ExportRobotCallRecordList', params)
}

// 热门活动列表 https://iwiki.woa.com/p/4012715988
export function getActivityListWithMarketingWords (params: {page:number, pageSize:number}) {
  return requestApiV2<CommonList<ActivityListWithMarketingWordsRecordItem>>('trpc.tcc.tcc_main.AIBot/GetActivityListWithMarketingWords', params)
}
