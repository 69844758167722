/**
 * 全局渲染错误 catch
 * @author tylerzzheng
 */
import React from 'react'
import { Result } from 'antd'
import { captureException as aegisCaptureException } from '../../library/rum'

interface ErrorBoundaryState {
  error: Error
}

export class ErrorBoundary extends React.Component<{}, ErrorBoundaryState> {
  constructor (props) {
    super(props)
    this.state = { error: null }

    // 初始化sentry
    // initSentry()
  }

  componentDidCatch (error: Error) {
    this.setState({ error })
    // rum 上报
    aegisCaptureException(error)
    // // sentry 上报
    // captureException(error)
  }

  render () {
    const { error } = this.state

    if (!error) {
      return this.props.children
    }

    return (
      <div className="main-app">
        <Result
          status="error"
          title="抱歉，页面渲染错误"
          subTitle={(
            <p>
              您可以尝试刷新本页面重试，或联系 <a href="wxwork://message?uin=8444253413321081">CSIG_OMP 助手</a>反馈问题
            </p>
          )}
          extra={error.stack}
        />
      </div>
    )
  }
}
