import React, { FC, ReactNode, useEffect, useMemo } from 'react'
import store from '@store'
import { Avatar, AvatarProps, Tooltip } from 'antd'
import { AccountType, IStaff } from '@types'
import { isEmpty, isEmptyArray, isFunction, isNullOrUndefined, notEmptyArray, getResList, getStaffName } from '@library'
import { getStaffList } from '@services/organazition'

/**
 * getStaffList接口返回的UserInfo数据中的avatar字段是高清大头像url，将url结尾的/0替换为/100就可以获得10kb以下的小图
 * @param avatar
 */
export const getThumbAvatar = (avatar: string = ''): string => avatar.replace(/\/0$/, '/100').replace('http://', 'https://')

export interface UserAvatarProps extends Omit<AvatarProps, 'src' | 'srcSet'>{
  /**
   * 员工（座席）英文名，会自动根据英文名称获取头像资源url
   */
  salesName: string
  /**
   * 是否需要tooltip提示
   */
  tooltip?: boolean
  /**
   * 是否需要展示中英文名
   */
  label?: boolean | ((staff: IStaff) => ReactNode)
  labelClassName?: string;
  labelStyle?: React.CSSProperties;
}

let StaticTimer: NodeJS.Timeout = null // 存
const StaticSalesNameSet = new Set<string>([])

/**
 * 提供系统统一格式的员工（座席）中英文名展示
 * @param props
 * @constructor
 */
export const UserAvatar: FC<UserAvatarProps> = (props) => {
  const { salesName, tooltip, label, labelClassName, labelStyle, ...otherProps } = props

  const { staffData, setStaffData } = store.useGlobal()
  const staff = useMemo(() => staffData[salesName] || { salesName } as IStaff, [salesName, staffData])

  useEffect(() => {
    // 如果avatar已经存在，或者salesName为空则返回
    if (staff?.avatar || isEmpty(salesName)) return

    // 说明已经拉过用户数据，只不过拉过的数据avatar为空，那么无需再拉取并直接返回
    if (!isNullOrUndefined(staff?.id)) return

    // 页面上可能有很多个示例，收集起来一次性请求后台
    StaticSalesNameSet.add(salesName)
    clearTimeout(StaticTimer)
    StaticTimer = setTimeout(() => {
      const staffNotIncluded = [...StaticSalesNameSet]
      if (notEmptyArray(staffNotIncluded)) {
        ;(async () => {
          const [res, err] = await getStaffList({
            salesNames: staffNotIncluded,
            page: 1,
            pageSize: staffNotIncluded.length,
          })
          if (err) return

          const newStaffList = getResList(res, [])[0]
          if (isEmptyArray(newStaffList)) return

          const newStaffData: typeof staffData = _.keyBy(newStaffList, 'salesName')
          setStaffData(newStaffData)
          StaticSalesNameSet.clear()
        })()
      }
    }, 1000)

    return () => {
      if (StaticTimer) clearTimeout(StaticTimer)
    }
  }, [staff, salesName])

  const avatar = useMemo(() => {
    if (isEmpty(salesName)) return null
    const staffAvatar = (
      <Avatar
        children={staff.type === AccountType.VIRTUAL ? '虚' : salesName.charAt?.(0)?.toUpperCase()}
        {...otherProps}
        src={getThumbAvatar(staff.avatar)}
      />
    )
    return tooltip ? <Tooltip title={getStaffName(staff.salesName, staff.salesNameCh)} placement="top" children={staffAvatar} /> : staffAvatar
  }, [staff, salesName, otherProps, tooltip])

  return label ? (
    <span style={{ whiteSpace: 'nowrap' }}>
      {avatar}
      {isFunction(label) ? label(staff) : <span className={labelClassName} style={labelStyle}>{getStaffName(staff.salesName, staff.salesNameCh)}</span>}
    </span>
  ) : avatar
}

UserAvatar.defaultProps = {
  salesName: '',
  tooltip: false,
  label: true,
  style: { border: '1px solid #ddd' },
  labelClassName: 'ml5',
}
