import React, { useCallback, useEffect } from 'react'
import { message, Spin, TreeSelect, TreeSelectProps } from 'antd'
import { useObject, useService, useShowError } from '@hooks'
import { isArray, isFunction, ServiceType } from '@library'
import { getResList } from '../../library/utils'

/**
 * antd类型中漏掉了TreeSelect为treeDataSimpleMode时treeData的类型，这里补充上
 * 这里的树数据是扁平结构的，使用pId指定父节点。但并非强制，也可以使用children字段来充当树形结构
 */
export interface TreeDataSimple {
  key?: React.Key;
  pId?: React.Key // 父节点id
  id: React.Key
  value: React.Key
  title: React.ReactNode
  isLeaf?: boolean
  disabled?: boolean;
  checkable?: boolean;
  disableCheckbox?: boolean;
  /**
   * 本组件已经把treeData扁平化，所以通常情况下不需要children字段，只需要指定pId即可。
   * 但如果现有的数据已经是树形结构，则可以使用children字段。
   */
  children?: TreeDataSimple[]
  /**
   * 允许自行挂载
   */
  other?: any
}

export interface CommonSelectProps<F extends object, T extends object> extends Omit<TreeSelectProps, 'onChange' | 'treeDataSimpleMode'| 'treeData' | 'loadData'> {
  // 组件名字，请求数据报错时会用到。
  name?: string
  // 查询参数,
  filter?: Partial<F>
  // 数据请求接口
  service: ServiceType<F, T>
  // 异步加载树节点的时候,通过此prop组成新的上送参数。为空时不进行异步加载。
  loadData?: ((item: TreeDataSimple) => F)
  // 将数据翻译成TreeDataSimple
  translate: (item: T, index?: number, list?: T[]) => TreeDataSimple
  // 另外onchange的时候返回整个treeData
  onChange?: (value: any, label: string[], teeData: TreeDataSimple[]) => void
}

/**
 * 公用树类型选择器
 * 数据结构使用了treeDataSimpleMode模式
 */
export function CommonTreeSelector<F extends object, T extends object> (props: CommonSelectProps<F, T>) {
  const { name, filter, service, loadData: fetchMore, onChange, translate, filterTreeNode, ...otherProps } = props

  const [loading, res, err] = useService(service, filter)
  useShowError(`获取${name}数据失败`, err)

  const [treeData, setTreeData] = useObject<TreeDataSimple[]>([])
  useEffect(() => {
    const list = getResList(res, [])[0].map(translate)
    setTreeData(list)
  }, [res])

  const loadData = useCallback(async (item: TreeDataSimple) => {
    if (!isFunction(service)) return

    const [res, err] = await service(fetchMore?.(item))
    if (err) {
      message.error(`获取${name}子节点失败${err?.message}`)
      return
    }
    const newData = getResList(res, [])[0]
    const list = newData.map(translate).map(data => ({ ...data, pId: item.id }))
    setTreeData(treeData => isArray(treeData) ? treeData.concat(list) : list)
  }, [setTreeData, fetchMore])

  return (
    <TreeSelect
      notFoundContent={loading ? <Spin size="small" /> : null}
      loading={loading}
      showSearch={!!filterTreeNode}
      {...otherProps}
      loadData={isFunction(fetchMore) ? loadData as unknown as TreeSelectProps['loadData'] : undefined} // 这里antd原本的类型有误，经实测，loadData的时候是可以返回一个TreeDataSimple类型的参数
      onChange={(value: T, label: string[]) => onChange?.(value, label, treeData)}
      treeDataSimpleMode
      treeData={treeData}
    />
  )
}

CommonTreeSelector.defaultProps = {
  name: '',
  style: { minWidth: '100px' },
  filter: {},
  translate: item => item,
  /* origin */
  filterTreeNode: false, // 默认后端搜索，不需要前端过滤
  allowClear: true,
  showArrow: true,
}
