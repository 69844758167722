/**
 * 留资记录
 * @author v_linhairao
 */
import React, { useMemo } from 'react'
import { PageHeader, TableDownload, CommonFilters, UserAvatar } from '@components'
import { Card, Table, Popover, Button } from 'antd'
import { getRetainRecordList, exportRetainRecordList } from '@services/retain-resource'
import { RetainListItem, RetainListFilter, sortTypeEnum } from '@types'
import { TableColumnProps, useTableColumn } from '@hooks'
import { TableProps } from 'antd/lib/table'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { antdSortMap } from '@configs/map'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { retainRecordFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'

interface ExtendType {
  key: string
  keyName: string
  keyValue: string
}
const initFilter: RetainListFilter = {
  page: 1,
  pageSize: 10,
  sort: 'createTime',
  sortType: sortTypeEnum.DESC,
}

export default function RetainRecordPage () {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getRetainRecordList, initFilter, '获取留资记录失败')

  const onTableChange: TableProps<RetainListItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<RetainListFilter>(sorter)
    setFilter(sort)
  }

  const renderExtend = (extend) => {
    let list: ExtendType[] = []
    try {
      list = JSON.parse(extend)
    } catch {}
    return (
      <Popover
        trigger="hover"
        placement="topRight"
        content={(
          <section style={{ width: '450px' }}>
            {list?.map(({ key, keyName, keyValue }) => (
              <div style={{ display: 'flex' }} key={key}>
                <div style={{ textAlign: 'right', width: '100px', wordWrap: 'break-word' }}>{keyName}</div>
                <div style={{ paddingRight: '5px' }}>：</div>
                <div style={{ flexGrow: 1, wordWrap: 'break-word', width: '320px' }}>{(keyValue || '')?.toString()}</div>
              </div>
            ))}
          </section>
        )}
      >
        <Button type="link" style={{ padding: 0 }}>详情</Button>
      </Popover>
    )
  }

  const columns: TableColumnProps<RetainListItem & {[propName: string]: string}>[] = useMemo(() => [
    {
      title: '线索ID',
      dataIndex: 'leadId',
      width: 120,
    },
    {
      title: '模板ID',
      dataIndex: 'extraFormId',
      width: 120,
    },
    {
      title: '模板名称',
      dataIndex: 'extraFormName',
      width: 180,
    },
    {
      title: '创建时间',
      dataIndex: 'createTime',
      width: 180, // 注意和时间相关都建议180
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
    {
      title: '所属客户',
      dataIndex: 'customerName',
      width: 200,

    },
    {
      title: '所属任务ID',
      dataIndex: 'taskId',
      width: 200,

    },
    {
      title: '所属任务',
      dataIndex: 'taskName',
      width: 200,

    },
    {
      title: 'CID',
      dataIndex: 'cid',
      width: 200,
    },
    { title: '创建人', dataIndex: 'creator', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '填写内容',
      dataIndex: 'extend',
      width: 100,
      render: renderExtend,
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.RETAIN_RECORD, columns, ['extraFormId'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  return (
    <>
      <PageHeader title="留资记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={retainRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1, extraFormId: filter?.extraFormId && [filter?.extraFormId] })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.RETAIN_RECORD} onSuccess={() => forceRequest()} />
        <TableDownload className="ml20" exportMethod={exportRetainRecordList} filter={filter} />
        <Table
          style={{ maxWidth: tableXWidth + 'px' }}
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
