/**
 * 系统任务列表
 * @author v_linhairao
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Card, Table, Popover, Descriptions } from 'antd'
import { PageHeader, CommonFilters, UserAvatar } from '@components'
import { missionStatusMap, TaskLogExtend } from '@types'
import { missionTypeMap } from '@configs/map'
import { ColumnProps } from 'antd/lib/table'
import { getAutoScriptMissionList } from '@services/task-log'
import TaskLog from '@services/task-log/entities/TaskLog'
import { taskLogFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'

const { Item: DesItem } = Descriptions
const fontColor = { color: '#1890ff', cursor: 'pointer' }
const initFilter = { page: 1, pageSize: 10 }
const ConfigTaskLogPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    setFilter,
    resetFilter,
  } = useTableService(getAutoScriptMissionList, initFilter, '获取任务日志列表失败')

  const TaskLogList: TaskLog[] = useMemo(() => list.map(taskLog => new TaskLog(taskLog)), [list])

  const columns: ColumnProps<TaskLog>[] = useMemo(() => [
    { title: '任务id', dataIndex: 'missionId', width: 80 },
    { title: '任务类型', dataIndex: 'missionType', width: 120, render: v => missionTypeMap[v] },
    { title: '提交人', dataIndex: 'creator', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '执行状态', dataIndex: 'status', width: 120, render: v => missionStatusMap[v] },
    { title: '失败/成功原因', dataIndex: 'message', width: 120 },
    {
      title: '详情',
      dataIndex: 'extend',
      width: 120,
      render: v => {
        let extend = [] as TaskLogExtend[]
        try {
          extend = JSON.parse(v)
        } catch {}
        return (
          <Popover
            trigger="hover"
            content={(
              <section style={{ width: '200px' }}>
                <Descriptions size="small" column={1}>
                  {extend?.map(({ key, keyName, keyValue }) => (<DesItem key={key} label={keyName}>{keyValue}</DesItem>))}
                </Descriptions>
              </section>
            )}
          >
            <span style={fontColor}>查看</span>
          </Popover>
        )
      },
    },
    { title: '创建时间', dataIndex: 'createTime', width: 120 },
  ], [])

  return (
    <>
      <PageHeader title="系统任务列表" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={taskLogFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card className="mt20">
        <Table
          className="mt20"
          loading={loading}
          columns={columns}
          dataSource={TaskLogList}
          rowKey="missionId"
          pagination={pagination}
        />
      </Card>
    </>
  )
}
export default ConfigTaskLogPage
