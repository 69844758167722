/**
 * 客户分配记录,客户变更记录
 * @author ruikangmao
 */
import React, { FunctionComponent, useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Card, Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import { CommonFilters, PageHeader, UserAvatar } from '@components'
import { TableColumnProps, useTableColumn } from '@hooks'
import { ChangeRecordItem, FlowRecordFilter, needTotalEnum } from '@types'
import { antdSortMap, flowTypeMap } from '@configs/map'
import { getCustomerFlowRecordList } from '@services/customer'
import { getSortOrder, getTableXwidth, TableIdEnum } from '@configs/table'
import { ConfigTableHeader } from '../../../components/ConfigTableHeader'
import { assignRecordFilterItems } from '../../../components/CommonFilters/configs'
import { useTableService } from '@hooks/useTableService'
import { trimStr } from '@library'

const initFilter: FlowRecordFilter = {
  page: 1,
  pageSize: 10,
  needTotal: needTotalEnum.LIST,
  // sort: 'createTime',
  // sortType: sortTypeEnum.DESC,
}

const CustomerAssignRecordPage: FunctionComponent = () => {
  const {
    loading,
    list,
    pagination,
    filter,
    setFilter,
    resetFilter,
    rowKey,
  } = useTableService(getCustomerFlowRecordList, initFilter, '获取客户变更记录失败')

  const onTableChange: TableProps<ChangeRecordItem>['onChange'] = (pagination, filters, sorter) => {
    const sort = getSortOrder<FlowRecordFilter>(sorter)
    setFilter(sort)
  }

  const custColumns: TableColumnProps<ChangeRecordItem>[] = useMemo(() => [
    {
      title: '客户名称',
      dataIndex: 'customerName',
      width: 120,
      render: (customerName, record) => (
        <Link target="_blank" to={`/customer/${record.customerId}?from=${encodeURIComponent(window.location.pathname)}`}>
          {trimStr(customerName, '-')}
        </Link>),
    },
    { title: 'CID', dataIndex: 'cid', width: 180 },
    { title: 'uin', dataIndex: 'uin', width: 120 },
    { title: '变更类型', dataIndex: 'flowType', render: flowType => flowTypeMap[flowType] || '-', width: 180 },
    { title: '保留的CID', dataIndex: 'remainCid', width: 120 },
    { title: '被合并的CID', dataIndex: 'beMergedCid', width: 120 },
    { title: '变更前跟进人', dataIndex: 'beforeFollowerNameEn', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '变更后跟进人', dataIndex: 'afterFollowerNameEn', width: 220, render: value => <UserAvatar salesName={value} /> },
    { title: '变更后所属组', dataIndex: 'afterFollowerGroupName', width: 220 },
    { title: '变更原因', dataIndex: 'remark', width: 120 },
    { title: '操作人', dataIndex: 'operator', width: 220, render: value => <UserAvatar salesName={value} /> },
    {
      title: '变更时间',
      dataIndex: 'createTime',
      width: 180,
      sorter: true,
      sortOrder: filter.sort === 'createTime' ? antdSortMap[filter.sortType] : undefined,
    },
  ], [filter.sort, filter.sortType])
  const [columnConfigs, columnProps, forceRequest] = useTableColumn(TableIdEnum.CUSTOMER_ASSIGN, custColumns, ['customerName'])
  const tableXWidth = useMemo(() => getTableXwidth(columnProps), [columnProps])

  /* 列表选择相关 */
  return (
    <>
      <PageHeader title="客户变更记录" />
      <Card className="mt20 mb20">
        <CommonFilters
          className="custom-Label"
          itemList={assignRecordFilterItems}
          onSubmit={filter => setFilter({ ...filter, page: 1 })}
          onReset={() => resetFilter(initFilter)}
        />
      </Card>
      <Card>
        <ConfigTableHeader columnConfigs={columnConfigs} tableId={TableIdEnum.CUSTOMER_ASSIGN} onSuccess={() => forceRequest()} />
        {/* <TableDownload className="ml20" exportMethod={exportCustomerChangeRecordList} filter={filter} /> */}{/** 下载接口未开发 */}
        <Table
          tableLayout="fixed"
          className="mt20"
          loading={loading}
          pagination={pagination}
          scroll={{ x: tableXWidth }}
          rowKey={rowKey}
          dataSource={list}
          columns={columnProps}
          onChange={onTableChange}
        />
      </Card>
    </>
  )
}
export default CustomerAssignRecordPage
