import React, { Ref, useCallback, useEffect, useImperativeHandle, useMemo } from 'react'
import { Form, Modal } from 'antd'
import { ModalProps } from 'antd/lib/modal/Modal'
import { createForm, PureFieldProps } from '@tencent/meta-form'
import { isEmpty, isFunction, isNull, isNullOrUndefined, isObject, isUndefined, notEmptyArray } from '@library'
import classNames from 'classnames'
import { FormProps } from 'antd/lib/form/Form'
import { usePropState } from '@hooks'

type ExcludeSymbol<T> = Exclude<keyof T, symbol>
type SetValueFunc<T extends object, TV = T[keyof T]> = (newVal: any, oldVal: any, formValues: T) => Partial<T> | TV | undefined | null
type TransToFunc<P extends object, FV extends object> = {
  [k in keyof P]: P[k] | ((value: FV[keyof FV], formValues: FV) => P[k])
}
/**
 * 泛型 FV： FormValues，ModalForm组件表单的类型
 * 泛型 V：values，传入ModalForm组件的 values 的类型，和提交或 onchange事件触发时，传递出去的类型
 *
 * 这里把PureFieldProps变成了 Partial，表明其 field 字段可为空，为空时会把 其key设置为 field，并且提交时会被忽略，不会生成到values中。
 * display为false的时候可以不设置children
 */
interface FieldType<V, FV extends {[key: string]: any}> extends Partial<Omit<PureFieldProps<FV, ExcludeSymbol<FV>>, 'initialValue' | 'onChange' | 'label' | 'help' | 'required' | 'hasFeedback' | 'rule'>>
  , TransToFunc<Pick<PureFieldProps<FV, ExcludeSymbol<FV>>, 'label' | 'help' | 'required' | 'hasFeedback' | 'rule'>, FV>{
  /**
   * 默认 field。为 plainElement 时不会被 Field 包裹，Children 或renderChildren会独立显示，并且 Field 相关的字段都不会生效
   */
  type?: 'field' | 'plainElement'
  /**
   * 将外部initValues 转换为当前 表单项的表单值，最后会合并成整个表单值
   * 未定义时会直接将 value 赋值给 fieldValue，为 null 时会直接跳过
   */
  toFiledValue?: (values: Partial<V>) => Partial<FV> | null
  /**
   * 点击提交后，转化为外部需要的类型，返回值为 null 时会跳过
   */
  fromFieldValue?: (value: FV[keyof FV], formValues: FV) => Partial<V> | null
  /**
   * display 只是视觉上显示和隐藏，不影响 submit 的时候的上送，要控制 submit 时的上送请使用fromFieldValue。
   */
  display?: ((value: FV[keyof FV], formValues: FV) => boolean)
  /**
   * 当其它field的 value 改变时，执行的函数，返回值会 merge 到表单值。具体 merge 逻辑同onFieldValueChange
   */
  watch?: Partial<Record<keyof FV, SetValueFunc<FV>>>
  /**
   * 在用户输入时触发，返回值会被 merge 到formValues。如果没有提供此参数，则自动执行默认行为。
   * 若返回值为对象，则自动 merge，
   * 若返回值为非对象，则将返回值设为到当前的 field 的值
   * 若返回undefined, 则将当前 field 的值置空
   * 若返回 null，则跳过赋值
   */
  onFieldValueChange?: SetValueFunc<FV>
  renderChildren?: (value: FV[keyof FV], formValues: FV) => PureFieldProps<FV, keyof FV>['children']
  /**
   * 表单内唯一，未指定时将设置为index
   */
  key?: string
}

/**
 * 泛型 V 为外部需要的类型
 * 泛型 FV 为表单内部的类型
 */
export interface ModalFormProp<V extends {[key: string]: any}, FV extends {[key: string]: any} = {}> extends Omit<ModalProps, | 'onOk'> {
  cRef?: Ref<any>
  formProps?: Omit<FormProps, 'onSubmit'>
  onSubmit: (values: V) => void
  // 会被fieldItems.toFiledValue解析为初始的 初始表单值formValues，并且可能会覆盖initFormValues
  initValues?: Partial<V>
  /**
   * 会被fieldItems上的toFiledValue覆盖
   */
  initFormValues?: Partial<FV>
  // 渲染整个表单
  fieldItems: FieldType<V, FV>[]
  // 是否需要弹窗， 默认true。 若不需要弹窗，则直接为一个表单.若false，则关于modal相关props都会失效
  isModal?: boolean
}

const formStore = createForm()
const { useValues, useValidate, Field, withForm, useErrors } = formStore

/**
 * 公共弹窗表单，旨在通过配置快速生成较复杂表单的能力。
 * 满足表单数据结构和外部数据结构的转换，支持表单项之间的联动，显示和隐藏。
 *
 * API 见类型定义
 * 支持通过 ref 直接调用内部方法。暴露出去的方法见useImperativeHandle
 *
 * 注意： 因为要支持泛型的缘故，导出的是一个函数，需要再执行一次得到返回值，示例如下：
 * const Form = useMemo(() => ModalForm<V, FV>(), [])
 *
 */
const _ModalForm = <V extends {[key: string]: any}, FV extends {[key: string]: any}>(props: ModalFormProp<V, FV>) => {
  const { cRef, formProps, onSubmit, initFormValues, initValues, fieldItems: items, isModal, confirmLoading, ...modalProps } = props

  const { validate } = useValidate()
  const { removeError, setError } = useErrors()
  const { values: formValues, setValues: setFormValues, resetValues: resetFormValues } = useValues()

  const [loading, setLoading] = usePropState(confirmLoading)

  // 给每一项设置key值，在后面的field中用到
  const fieldItems = useMemo(() => items.map((item, index) => _.set(item, 'key', item.key || index)), [items])

  // 设置表单初始值
  const initFormValue = useMemo(() => fieldItems.reduce((prev, curr) => {
    return isFunction(curr.toFiledValue) ? Object.assign(prev, curr.toFiledValue(initValues) || {}) : prev
  }, initFormValues), [])
  useEffect(() => {
    resetFormValues(initFormValue)
  }, [initFormValue])

  // 过滤一下list
  const displayFieldItems = useMemo(() => fieldItems.filter(item => {
    if (isFunction(item.display)) return item.display((formValues as FV)?.[item.field || item.key as keyof FV], formValues as FV)
    return true
  }), [formValues, fieldItems])

  // 收集onchange 的时候的依赖函数, onFieldValueChange和 watch 都会收集到同一处
  const dependMap = useMemo(() => fieldItems.reduce<Partial<Record<keyof FV, SetValueFunc<FV>[]>>>((prev, curr) => {
    const map: Partial<Record<keyof FV, SetValueFunc<FV>[]>> = prev || {}
    if (isObject(curr.watch)) {
      for (const [key, func] of Object.entries<SetValueFunc<FV>>(curr.watch)) {
        if (!isFunction(func)) continue
        const funcList = map[key as keyof FV] || []
        funcList.push(func)
        map[key as keyof FV] = funcList
      }
    }
    if (isFunction(curr.onFieldValueChange)) {
      const funcList = map[curr.field || curr.key as keyof FV] || []
      funcList.push(curr.onFieldValueChange)
      map[curr.field || curr.key as keyof FV] = funcList
    }
    return map
  }, {}), [fieldItems])

  // 将 formValues 转换为外部需要的 values
  const handleValues = useCallback((formValues: FV): V => {
    if (_.isEmpty(formValues) || _.isEmpty(fieldItems)) return {} as V

    return fieldItems.reduce((prev, curr) => {
      const { field, key, fromFieldValue, type = 'field' } = curr
      if (type !== 'field') return prev

      const val = formValues[field || key as keyof FV]

      if (isFunction(fromFieldValue)) {
        const temp = fromFieldValue(val, formValues)
        if (isNull(temp)) return prev
        return Object.assign(prev, temp || {})
      }
      if (isUndefined(fromFieldValue) && !isEmpty(field)) {
        const temp = prev || {}
        // undefined必须显式的等于
        temp[field as string] = isNullOrUndefined(val) ? undefined : val
        return temp
      }
    }, {}) as V
  }, [fieldItems])

  const onOK = useCallback(async () => {
    console.log('点击提交了', formValues)
    setLoading(true)
    const validateRes = await validate()
    if (!validateRes.isPass) {
      setLoading(false)
      return
    }

    const values = handleValues(formValues as FV)
    await onSubmit(values)
    setLoading(false)
  }, [formValues, handleValues, setLoading])

  // 暴露出去一些方法
  useImperativeHandle(cRef, () => ({
    submitForm: onOK,
    resetFormValues: (formValues = initFormValue) => {
      resetFormValues(formValues)
    },
    setFormValues,
    removeError,
    setError,
    validate,
    getValues: () => {
      return handleValues(formValues as FV)
    },
    // 相当于点击 OK 按钮
    onOK,
  }), [initFormValue, setFormValues, formValues, handleValues, onOK, validate, setError, removeError])

  const form = (
    <Form {...formProps}>
      {
        displayFieldItems.map((item, index) => {
          const {
            children, type = 'field', renderChildren, className, field, key, label, help, required, hasFeedback, rule,
            //  去除非Field本身的props
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            fromFieldValue, toFiledValue, display, watch, onFieldValueChange, ...restItems
          } = item || {}
          const fieldKey: keyof FV = isEmpty(field) ? key + '' : field
          if (type === 'plainElement') {
            return isFunction(renderChildren) ? renderChildren((formValues as FV)?.[fieldKey], formValues as FV) : children
          }
          if (type === 'field') {
            const funcList = dependMap[fieldKey]
            return (
              <Field
                {...restItems}
                key={index}
                label={isFunction(label) ? label((formValues as FV)?.[fieldKey], formValues as FV) : label}
                required={isFunction(required) ? required((formValues as FV)?.[fieldKey], formValues as FV) : required}
                hasFeedback={isFunction(hasFeedback) ? hasFeedback((formValues as FV)?.[fieldKey], formValues as FV) : hasFeedback}
                rule={isFunction(rule) ? rule((formValues as FV)?.[fieldKey], formValues as FV) : rule}
                help={isFunction(help) ? help((formValues as FV)?.[fieldKey], formValues as FV) : help}
                className={classNames(className, { 'no-label-text': isEmpty(item.label) })}
                field={fieldKey as string}
                children={isFunction(renderChildren) ? renderChildren((formValues as FV)?.[fieldKey], formValues as FV) : children}
                onChange={notEmptyArray(funcList) ? value => {
                  setFormValues(fieldKey as string, value)
                  funcList.forEach(func => {
                    if (isFunction(func)) {
                      const val = func(value as FV[keyof FV], (formValues as FV)[field], formValues as FV)
                      if (isNull(val)) {
                        return
                      }
                      if (isObject(val)) {
                        setFormValues(val)
                      } else {
                        setFormValues(fieldKey as string, val)
                      }
                    }
                  })
                } : undefined}
              />
            )
          }
        })
      }
    </Form>
  )

  return isModal ? (
    <Modal
      {...modalProps}
      confirmLoading={loading}
      onOk={onOK}
    >
      {form}
    </Modal>
  ) : form
}

_ModalForm.defaultProps = {
  title: '弹窗表单',
  okText: '提交',
  width: 800,
  fieldItems: [],
  initFormValues: {},
  initValues: {},
  onSubmit: () => {},
  isModal: true,
  confirmLoading: false,
}

export const ModalForm = <V extends object, FV extends object>() => withForm<ModalFormProp<V, FV>>(_ModalForm)
