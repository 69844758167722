/**
 * Session 登录态
 * @author jasonelchen
 */
import { useCallback, useMemo, useState } from 'react'
import { message } from 'antd'
import { stringify } from 'qs'
import { useHistory } from 'react-router-dom'
import { useObject, useService } from '@hooks'
import { CompanyInfo, ModuleInfo, StaffisAdmin, StaffisAssign, StaffStatus, UserInfo } from '@types'
import { clearTccToken, getModuleCompanyMapNoSession, getUserInfo as getUserInfoApi, login } from '@services/session'
import { remove } from 'js-cookie'
import { ORIGIN_TCC_TOKEN } from '@configs'

export function useSession () {
  const [logging, setLogging] = useState(true)
  const { push } = useHistory()

  const [, moduleRes, , forceRequestModuleCompanyMap] = useService(getModuleCompanyMapNoSession)
  // 这里转换一下数据结构，得到所有的模块列表和公司列表
  const companyList: CompanyInfo[] = useMemo(() => Array.from(moduleRes?.list || []), [moduleRes])
  const moduleList: ModuleInfo[] = useMemo(() => _.uniqBy(companyList.map(company => {
    const { belongModule, belongModuleName } = company
    return { belongModule, belongModuleName }
  }), 'belongModule'), [companyList])

  const [userInfo, setUserInfo] = useObject<UserInfo>({
    salesName: '',
    salesNameCh: '',
    avatar: '',
    isAdmin: StaffisAdmin.NOTADMIN,
    isAssign: StaffisAssign.NO,
    belongModule: -1,
    companyId: -1,
    createTime: '',
    id: -1,
    lastModifier: '',
    leader: '',
    openid: '-1',
    poolNum: -1,
    qidianWorkNumber: '-1',
    status: StaffStatus.OFFDUTY,
    updateTime: '',
    salesPhone: '',
    weworkShortChain: '',
  })

  // 当前模块下所有公司
  const currCompanyList: CompanyInfo[] = useMemo(() => companyList.filter(company => company.belongModule === userInfo.belongModule), [companyList, userInfo.belongModule])

  // 拉取用户信息
  const getUserInfo = useCallback(async () => {
    const [res, err] = await getUserInfoApi()

    if (err) {
      throw err
    }

    setLogging(false)
    setUserInfo(res)
  }, [])

  // 登录
  const loginWithCode = useCallback(async (code: string, moduleId: number, appId: string, agentId: number, tccCompany: number) => {
    // 清理可能遗留的上次模拟登录的cookie
    remove(ORIGIN_TCC_TOKEN)

    const [, err] = await login(code, moduleId, appId, agentId, tccCompany)

    if (err) {
      throw err
    }

    // 拉取用户信息
    return getUserInfo()
  }, [])

  // 跳转到企业微信登录页面
  const jumpToWorkWechat = useCallback((appId: string, agentId: string, callbackUrl?: string) => {
    const { origin } = window.location
    const query = stringify({
      appid: appId,
      agentid: agentId,
      redirect_uri: `${origin}/login`,
      state: stringify({ callbackUrl }),
    })

    window.location.href = `https://open.work.weixin.qq.com/wwopen/sso/qrConnect?${query}`
  }, [])

  // 登出
  const logout = useCallback(async () => {
    // 静默失败
    await clearTccToken()
    message.success('退出登录成功')
    return push(`/login?${stringify({ callbackUrl: window.location.pathname + window.location.search })}`)
  }, [])

  return {
    logging,
    logged: !logging,
    userInfo,
    setUserInfo,
    moduleList, // 所有模块列表
    companyList, // 所有公司列表，注意，不是当前
    currCompanyList, // 当前所有公司列表
    loginWithCode,
    logout,
    jumpToWorkWechat,
    getUserInfo,
    forceRequestModuleCompanyMap, // 强制刷新moduleRes
  }
}
