/**
 * 座席工作台
 * @author wzwwu
 */
import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { Card, Col, Row, Table, Tabs, Tooltip, Statistic, Switch } from 'antd'
import { CommonSelector, PageHeader } from '@components'
import { groupId } from '../../../components/CommonSelector/configs'
import { DataTypeEnum, GroupLevel, Indicator as IndicatorType, IndicatorFilter, needTotalEnum } from '@types'
import { getResList, isNullOrUndefined, mergeArrayWith, notEmptyArray } from '@library'
import './style.less'
import { useObject, useService, useShowError } from '@hooks'
import {
  getLeadIndicatorNumHistory,
  getLeadIndicatorNumToday,
  getNotHandleTaskCustomerNum,
  getNotHandleLeadCustomerNum,
  getOpportunityCustomerNum,
  getPredictFollowCustomerNum,
  getSpecifiedBatchLeadNum,
} from '@services/task'
import {
  filterMap,
  staticLeadIndicator,
  staticOpportunityIndicator,
  staticRecallIndicator,
  staticTaskIndicator,
  staticAssignedLeadIndicator,
} from '@pages/task/Workspace/config'
import classNames from 'classnames'
import { ColumnProps } from 'antd/lib/table'
import IndicatorCustomer from '@pages/task/Workspace/IndicatorCustomer'
import IndicatorCard from '@pages/task/Workspace/IndicatorCard'
import { moduleId } from '@configs'
import store from '@store'
import { IndicatorTipMap, IndicatorNameMap } from './config'

export interface Indicator extends IndicatorType {
  filterKey?: keyof typeof filterMap
  color?: string
}

// 重点线索列表对象
interface BatchIndicator {
  todayTag: string,
  historyTag: string,
  todayTagName: string,
  historyTagName: string,
  todayCount: number,
  historyCount: number,
  batchId: number,
}

export default function WorkspacePage () {
  const { userInfo } = store.useSession()
  /* 参数 */
  const [filter, setFilter] = useObject<IndicatorFilter>({ dataType: DataTypeEnum.ME_ONLY })
  const [activeTag, setActiveTag] = useState<string>(null)
  const filterParams = useRef({})

  const onClickIndicatorBadge = (bi: BatchIndicator, type: 'today' | 'history') => {
    const indicator: Indicator = {
      tag: type === 'today' ? bi.todayTag : bi.historyTag,
      tagName: type === 'today' ? bi.todayTagName + '今日新增末触达' : bi.historyTagName + '近6个月未触达',
      count: type === 'today' ? bi.todayCount : bi.historyCount,
      filterKey: 'indicatorLeadFilterItems',
    }
    onClickTag(indicator)
  }

  /* 指标客户 */
  const [visitedIndicators, setVisitedIndicators] = useState<Indicator[]>([]) // 保存已经访问过的指标列表

  useEffect(() => {
    const timer = setInterval(() => {
      // 在这里执行刷新页面数据的操作
      setFilter({
        ...filterParams.current,
        refresh: Math.random(),
      })
    // }, 10 * 1000) // 10秒
    }, 10 * 60 * 1000) // 10分钟
    return () => {
      clearInterval(timer) // 在组件卸载时清除定时器
    }
  }, [])

  useEffect(() => {
    filterParams.current = filter
    setActiveTag(null)
    setVisitedIndicators([])
  }, [filter])

  const onClickTag = (indicator: Indicator) => {
    if (!indicator || indicator.count <= 0) return
    setActiveTag(indicator.tag)
    setVisitedIndicators(draft => {
      const index = draft?.findIndex?.(item => item.tag === indicator.tag) ?? -1
      if (index === -1) draft.push(indicator)
      return draft
    })
  }

  const onEdit = (targetKey, action) => {
    if (action !== 'remove') return

    const list = _.clone(visitedIndicators)
    const index = list?.findIndex?.(item => item.tag === targetKey) ?? -1

    // 如果被删除的tab是当前激活的tab，则重新激活其左边的tab
    if (activeTag === targetKey) {
      setActiveTag(list[index > 0 ? index - 1 : 1]?.tag ?? null)
    }

    if (index > -1) {
      list.splice(index, 1)
      setVisitedIndicators(list)
    }
  }

  const IndictorArea = useCallback((props) => {
    const { filter, activeTag } = props
    // 近6月待处理任务
    const [notHandleTaskLoading, notHandleTaskIndicator, notHandleTaskIndicatorErr] = useService(getNotHandleTaskCustomerNum, filter)
    useShowError('近6月待处理任务指标失败', notHandleTaskIndicatorErr)
    // 近6月待处理线索
    const [notHandleLeadLoading, notHandleLeadIndicator, notHandleLeadIndicatorErr] = useService(getNotHandleLeadCustomerNum, filter)
    useShowError('近6月待处理线索指标失败', notHandleLeadIndicatorErr)
    const notHandleTaskAndLeadList = useMemo(() => ([
      notEmptyArray(getResList(notHandleTaskIndicator)?.[0]) && mergeArrayWith(getResList(notHandleTaskIndicator)?.[0], staticTaskIndicator, 'tag'),
      notEmptyArray(getResList(notHandleLeadIndicator)?.[0]) && mergeArrayWith(getResList(notHandleLeadIndicator)?.[0], staticAssignedLeadIndicator, 'tag'),
    ]), [notHandleTaskIndicator, notHandleLeadIndicator])

    // 是否展示全量线索
    const [isFullClue, setFullClue] = useState<boolean>(false)

    /* 重点线索批次 */
    const [todayBatchIndicatorLoading, todayBatchIndicator, todayBatchIndicatorErr] = useService(getLeadIndicatorNumToday, filter)
    useShowError('获取重点线索(今日)指标失败', todayBatchIndicatorErr)
    const [historyBatchIndicatorLoading, historyBatchIndicator, historyBatchIndicatorErr] = useService(getLeadIndicatorNumHistory, filter)
    useShowError('获取重点线索(累积)指标失败', historyBatchIndicatorErr)
    const [batchIndicatorList, batchIndicatorRemoveZeroList] = useMemo<BatchIndicator[][]>(() => {
      const tempToday = getResList(todayBatchIndicator)?.[0]?.map?.(item => ({ todayTag: item.tag, todayTagName: item.tagName, todayCount: item.count ?? 0, batchId: item.batchId })) ?? []
      const tempHistory = getResList(historyBatchIndicator)?.[0]?.map?.(item => ({ historyTag: item.tag, historyTagName: item.tagName, historyCount: item.count ?? 0, batchId: item.batchId })) ?? []
      if (notEmptyArray(tempToday)) {
        const list = mergeArrayWith(tempToday, tempHistory, 'batchId') as BatchIndicator[]
        const removeZeroList = list.filter(item => item.todayCount || item.historyCount) // 过滤掉今日新增末触达与近6月未触达均为0的数据
        return [list, removeZeroList]
      } else {
        const list = mergeArrayWith(tempHistory, tempToday, 'batchId') as BatchIndicator[]
        const removeZeroList = list.filter(item => item.todayCount || item.historyCount) // 过滤掉今日新增末触达与近6月未触达均为0的数据
        return [list, removeZeroList]
      }
    }, [todayBatchIndicator, historyBatchIndicator])

    const batchColumns: ColumnProps<BatchIndicator>[] = useMemo(() => [
      { title: '线索批次', dataIndex: 'todayTagName', ellipsis: true },
      { title: '批次id', dataIndex: 'batchId' },
      {
        title: '今日新增末触达',
        dataIndex: 'todayCount',
        width: 200,
        render: (value, record) => (
          <span onClick={() => onClickIndicatorBadge(record, 'today')}>
            <Statistic
              className={classNames({ active: activeTag === record.todayTag }, 'statistic', { 'zero-statistic': value === 0 })}
              value={value}
              valueStyle={{ color: value > 0 ? '#FF7250' : '#5cD492' }}
            />
          </span>),
      },
      {
        title: '近6月未触达',
        dataIndex: 'historyCount',
        width: 200,
        render: (value, record) => (
          <span onClick={() => onClickIndicatorBadge(record, 'history')}>
            <Statistic
              className={classNames({ active: activeTag === record.historyTag }, 'statistic', { 'zero-statistic': value === 0 })}
              value={value}
              valueStyle={{ color: value > 0 ? '#FF7250' : '#5cD492' }}
            />
          </span>),
      },
    ], [activeTag])

    const getToolTipTitle = (name) => IndicatorTipMap?.[name] ? (
      <Tooltip placement="right" title={<>{IndicatorTipMap?.[name]?.map((str, index) => <div key={index}>{index + 1}. {str}</div>)}</>}>
        {IndicatorNameMap?.[name]}
      </Tooltip>
    ) : IndicatorNameMap?.[name]

    return (
      <Row gutter={16} className="mt10">
        <Col span={6}>
          <Card title={getToolTipTitle('notHandleTask')} hoverable loading={notHandleTaskLoading && notHandleLeadLoading}>
            <Row gutter={[16, 16]}>
              {notHandleTaskAndLeadList?.map((itemList, index) => {
                return (
                  <Col span={24} key={index}>
                    <span onClick={() => onClickTag(itemList?.[0])}>
                      <Statistic
                        className={classNames('statistic', 'inline', { active: activeTag === itemList?.[0]?.tag }, { 'zero-statistic': itemList?.[0]?.count === 0 })}
                        title={itemList?.[0]?.tagName ?? '暂无数据'}
                        value={itemList?.[0]?.count ?? ' '}
                        valueStyle={{ color: itemList?.[0]?.count > 0 ? '#FF7250' : '#5cD492' }}
                      />
                    </span>
                  </Col>
                )
              })}
            </Row>
          </Card>
          {/* 清洗模块和腾讯会议模块,coc转化12, coc用研11，没有企业新商机的快捷 */}
          {![moduleId.MID_LONG_TAIL_CLEAN_ID,
            moduleId.TENCENT_MEETING_ID,
            moduleId.COC_STUDY_ID,
            moduleId.COC_TURN_ID,
          ].includes(userInfo.belongModule) && (
            <IndicatorCard
              filter={filter}
              service={getSpecifiedBatchLeadNum}
              activeTag={activeTag}
              onClickTag={onClickTag}
              staticIndicator={staticLeadIndicator}
              name="enterpriseBusiness"
              StatisticClassName="inline"
              className="mt10"
              ColSpan={() => 24}
            />
          )}
          <IndicatorCard
            filter={filter}
            service={getPredictFollowCustomerNum}
            activeTag={activeTag}
            onClickTag={onClickTag}
            // 只有中长尾模块有关联库21天未触达
            staticIndicator={userInfo.belongModule === moduleId.MID_LONG_TAIL_ID ? staticRecallIndicator : staticRecallIndicator.filter(item => item.tag !== 'customer.lastFollow21Day')} // 只有中长尾模块有关联库21天未触达
            name="callbackTask"
            StatisticClassName="inline"
            className="mt10"
            ColSpan={() => 24}
          />
        </Col>
        <Col span={18}>
          {/* 清洗模块和腾讯会议模块，没有销售机会的快捷 */}
          {![moduleId.MID_LONG_TAIL_CLEAN_ID, moduleId.TENCENT_MEETING_ID].includes(userInfo.belongModule) && (
            <IndicatorCard
              filter={filter}
              service={getOpportunityCustomerNum}
              activeTag={activeTag}
              onClickTag={onClickTag}
              staticIndicator={staticOpportunityIndicator}
              name="sellOpportunity"
            />
          )}
          <Card
            title={getToolTipTitle('importantLead')}
            loading={historyBatchIndicatorLoading && todayBatchIndicatorLoading}
            hoverable
            style={{ cursor: 'auto' }}
            extra={<>是否展示全量线索：<Switch checkedChildren="展示" unCheckedChildren="隐藏" checked={isFullClue} onChange={() => setFullClue(!isFullClue)} /></>}
            className="mt10"
          >
            <Table
              rowKey="batchId"
              scroll={{ y: '359px' }}
              size="small"
              tableLayout="fixed"
              dataSource={isFullClue ? batchIndicatorList : batchIndicatorRemoveZeroList}
              columns={batchColumns}
              pagination={false}
            />
          </Card>
        </Col>
      </Row>
    )
  }, [filter])
  return (
    <>
      <PageHeader
        title="座席工作台"
        footer={(
          <Tabs
            onChange={activeKey => { if (activeKey === DataTypeEnum.ME_ONLY + '') setFilter({ dataType: DataTypeEnum.ME_ONLY, groupId: undefined }) }}
            activeKey={filter.dataType + ''}
          >
            <Tabs.TabPane tab="归属我的" key={DataTypeEnum.ME_ONLY + ''} />
            <Tabs.TabPane
              key={DataTypeEnum.MY_TEAM + ''}
              tab={
                <CommonSelector
                  {...groupId}
                  filter={{ groupLevel: GroupLevel.SUB_GROUP }}
                  value={filter.groupId}
                  onChange={val => {
                    setFilter({ dataType: DataTypeEnum.MY_TEAM, groupId: val })
                  }}
                  bordered={false}
                  suffixIcon={isNullOrUndefined(filter.groupId) ? null : undefined}
                  allowClear={false}
                  placeholder="我管理的群组"
                />
              }
            />
          </Tabs>
        )}
      />
      <IndictorArea filter={filter} activeTag={activeTag} />
      <Card title="指标客户" className="mt10">
        {notEmptyArray(visitedIndicators) ? (
          <Tabs hideAdd activeKey={activeTag} type="editable-card" onEdit={onEdit} onChange={setActiveTag}>
            {visitedIndicators.map((pane) => (
              <Tabs.TabPane tab={<>{pane.tagName}<span style={{ color: pane.color }}>({pane.count})</span></>} key={pane.tag}>
                <IndicatorCustomer initFilter={{ ...filter, tag: pane.tag, page: 1, pageSize: 10, needTotal: needTotalEnum.LIST }} filterKey={pane?.filterKey} />
              </Tabs.TabPane>
            ))}
          </Tabs>
        ) : '请选择上方指标'}
      </Card>
    </>
  )
}
