/**
 * 播放录音
 * @author tylerzzheng
 */
import React, { Children, cloneElement, PropsWithChildren, useCallback, useState } from 'react'
import { PlayCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { message, notification, Tooltip } from 'antd'
import { getCallRecord } from '@services/customer'
import { ArgsProps } from 'antd/lib/notification'
import { isEmpty } from '@library'

interface ReplayRecordProps {
  logId?: string // CallRecordListItem的callId
  playTips?: string // 提示浮层的信息
  disabled?: boolean // 不可播放的样式
  title: string // 播放控件的标题£
  src?: string // 音频的src地址，有这个值的时候将略过用logID去请求src的过程。logId参数会被忽略
  className?: string,
  robotFlag?: boolean // 是否事机器人通话记录
}

/**
 * 播放录音,
 * 此组件功能，会显示一个play的icon(可以被children覆盖，自由扩展)，点击之后右下角弹出播放控件浮层。
 * 如果有子元素，请确保子元素能接受onClick时间
 * @param props
 * @constructor
 */
export function ReplayRecord (props: PropsWithChildren<ReplayRecordProps>) {
  const { className, src = null, logId, children, title, playTips = '收听录音', disabled = false, robotFlag = false } = props

  const [loading, setLoading] = useState(false)
  const [audioSrc, setAudioSrc] = useState<string>(src)

  // 当logid变化的时候，清空缓存
  useCallback(() => { setAudioSrc(src) }, [logId])

  const getRecordSrc = useCallback(async (): Promise<string> => {
    if (isEmpty(logId)) {
      message.error('找不到通话Id')
      return null
    }

    setLoading(true)
    const [res, err] = await getCallRecord(logId, robotFlag)
    setLoading(false)
    if (err) {
      message.error(`获取通话录音失败(${err.message})`)
      return null
    }
    return _.get(res, 'voiceUrlList[0]', null)
  }, [logId])

  const openPlayPanel = useCallback(async () => {
    const src = _.isEmpty(audioSrc) ? await getRecordSrc() : audioSrc
    if (_.isEmpty(src)) {
      return
    }

    setAudioSrc(src)
    const args: ArgsProps = {
      message: title,
      description: (<audio src={src} controls>您的浏览器不支持播放录音</audio>),
      placement: 'bottomRight',
      duration: 0,
    }
    notification.open(args)
  }, [audioSrc, title])

  const renderChildren = () => {
    if (!children) {
      return (
        <Tooltip title={playTips}>
          {disabled ? (<PlayCircleOutlined className={className} style={{ color: '#aec6de' }} />)
            : loading ? (<LoadingOutlined onClick={openPlayPanel} style={{ cursor: 'pointer', color: '#1890ff' }} className={className} />)
              : (<PlayCircleOutlined onClick={openPlayPanel} style={{ cursor: 'pointer', color: '#1890ff' }} className={className} />)}
        </Tooltip>
      )
    } else {
      // 只能有一个子元素
      Children.only(children)

      return cloneElement(children as React.ReactElement, { onClick: openPlayPanel })
    }
  }

  return (
    <>
      {renderChildren()}
    </>
  )
}
