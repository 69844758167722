/**
 * 从 props 同步 state
 * @author jasonelchen
 */
import { useState, useEffect, Dispatch, SetStateAction, useCallback } from 'react'

export function usePropState<T> (propsValue: T): [T, Dispatch<SetStateAction<T>>, () => void] {
  const [state, setState] = useState(propsValue)

  // 根据 props 变化 state
  useEffect(() => setState(propsValue), [propsValue])

  // 重置回 props
  const reset = useCallback(() => setState(propsValue), [propsValue])

  return [state, setState, reset]
}
